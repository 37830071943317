import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { PartError, PartErrorProps, } from 'Components/Parts';


interface Props extends PartErrorProps {
  colSpan: number,
}


const TableError = ({
  colSpan,
  minHeight = '10rem',
  ...rest
}: Props): JSX.Element => (
  <MuiTableBody>
    <MuiTableRow>
      <TableCell colSpan={colSpan}>
        <PartError
          minHeight={minHeight}
          {...rest}
        />
      </TableCell>
    </MuiTableRow>
  </MuiTableBody>
);


export default TableError;
