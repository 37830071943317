import { AdminTableOrderFragment } from "Apollo/graphql";

export const orderListToSelection = (orderList:AdminTableOrderFragment[]) => orderList.reduce((acc, order) => {
    acc[order.id] = false;
    return acc;
}
, {} as Record<string, boolean>);

export const setAllToFalse = (orderSelection: Record<string, boolean>) => Object.entries(orderSelection).reduce((acc, [key]) => {
    acc[key] = false;
    return acc;
}, {} as Record<string, boolean>);
