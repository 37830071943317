import { Form, } from 'formik';
import { useTranslation, } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { User, } from 'Tools/auth';
import { ModalBody, ModalFooter, } from 'Components/Modal';
import { FormSubmit, FormError, } from 'Components/Form';
import UserForm from 'Modules/Users/components/UserForm';

interface Props {
  currentUser: User | null,
  onClose: () => void,
}

const View = ({
  currentUser,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation();
  return (
    <Form autoComplete="off">
      <ModalBody>
        <UserForm currentUser={currentUser} />
      </ModalBody>

      <ModalFooter>
        <Grid container justify="space-between" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="default"
              onMouseDown={onClose}
            >
              {t('common:action.back')}
            </Button>
          </Grid>
          <Grid item>
            <FormSubmit
              color="primary"
            >
              {t('common:action.edit')}
            </FormSubmit>
          </Grid>
        </Grid>
        <FormError />
      </ModalFooter>

    </Form>
  );
};
export default View;
