import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcceptBillingInfoAndCalculateShippingPriceResponse = {
  __typename?: 'AcceptBillingInfoAndCalculateShippingPriceResponse';
  order?: Maybe<Order>;
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Scalars['String']>>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  id: Scalars['ID'];
  email: Scalars['String'];
  token: Token;
};

export type BillingInfo = {
  __typename?: 'BillingInfo';
  id: Scalars['String'];
  orderId?: Maybe<Scalars['ID']>;
  spaceId: Scalars['ID'];
  name: Scalars['String'];
  telephone: Scalars['String'];
  email: Scalars['String'];
  ico: Scalars['String'];
  dic: Scalars['String'];
  psc: Scalars['String'];
  city: Scalars['String'];
  street: Scalars['String'];
  isBusinessOwner: Scalars['Boolean'];
};

export type BillingInfoInput = {
  orderId?: Maybe<Scalars['ID']>;
  spaceId: Scalars['ID'];
  name: Scalars['String'];
  telephone: Scalars['String'];
  email: Scalars['String'];
  ico: Scalars['String'];
  dic: Scalars['String'];
  psc: Scalars['String'];
  city: Scalars['String'];
  street: Scalars['String'];
};

export enum BrandEnum {
  MercedesSmart = 'MERCEDES_SMART',
  Bmw = 'BMW',
  AstonMartin = 'ASTON_MARTIN',
  Alternative = 'ALTERNATIVE',
  Bentley = 'BENTLEY',
  ChryslerJeepHummer = 'CHRYSLER_JEEP_HUMMER',
  Daihatsu = 'DAIHATSU',
  Fca = 'FCA',
  Ferrari = 'FERRARI',
  FiatAlfaRomeoDodgeLancia = 'FIAT_ALFA_ROMEO_DODGE_LANCIA',
  Fisker = 'FISKER',
  Ford = 'FORD',
  Honda = 'HONDA',
  Hyundai = 'HYUNDAI',
  Isuzu = 'ISUZU',
  Jaguar = 'JAGUAR',
  Kia = 'KIA',
  Lada = 'LADA',
  LandRover = 'LAND_ROVER',
  Maserati = 'MASERATI',
  Mazda = 'MAZDA',
  Mitsubishi = 'MITSUBISHI',
  NissanInfinity = 'NISSAN_INFINITY',
  PeugeotCitroenChevroletOpel = 'PEUGEOT_CITROEN_CHEVROLET_OPEL',
  Proton = 'PROTON',
  RenaultDacia = 'RENAULT_DACIA',
  Rover = 'ROVER',
  Saab = 'SAAB',
  Ssangyong = 'SSANGYONG',
  Subaru = 'SUBARU',
  Suzuki = 'SUZUKI',
  ToyotaLexus = 'TOYOTA_LEXUS',
  VolkswagenAudiSeatSkoda = 'VOLKSWAGEN_AUDI_SEAT_SKODA',
  Volvo = 'VOLVO',
  Other = 'OTHER',
  Ate = 'ATE',
  Airmatic = 'AIRMATIC',
  BeTurbo = 'BE_TURBO',
  Banner = 'BANNER',
  Bosal = 'BOSAL',
  Bosch = 'BOSCH',
  Continental = 'CONTINENTAL',
  Contitech = 'CONTITECH',
  Diederich = 'DIEDERICH',
  Eibach = 'EIBACH',
  Elring = 'ELRING',
  Ernst = 'ERNST',
  Fag = 'FAG',
  Fte = 'FTE',
  Febi = 'FEBI',
  Huf = 'HUF',
  Hella = 'HELLA',
  HerthBuss = 'HERTH_BUSS',
  Hitachi = 'HITACHI',
  Ina = 'INA',
  Jakoparts = 'JAKOPARTS',
  Luk = 'LUK',
  Lemfoerder = 'LEMFOERDER',
  Mann = 'MANN',
  Marelli = 'MARELLI',
  MeyerMotoren = 'MEYER_MOTOREN',
  Ngk = 'NGK',
  Nrf = 'NRF',
  Ntk = 'NTK',
  Phillips = 'PHILLIPS',
  Pierburg = 'PIERBURG',
  Skf = 'SKF',
  Swf = 'SWF',
  Sachs = 'SACHS',
  Sekurit = 'SEKURIT',
  Stabilus = 'STABILUS',
  Trw = 'TRW',
  Vdo = 'VDO',
  Valeo = 'VALEO',
  VanWezel = 'VAN_WEZEL',
  Varta = 'VARTA',
  Waeco = 'WAECO',
  Zf = 'ZF'
}

export type ChangePasswordInput = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type CheckManyPartsAvailabilityInput = {
  spaceId: Scalars['ID'];
  orderId: Scalars['ID'];
  partParams: Array<PartParams>;
  brand: BrandEnum;
};

export type CreateOrderResult = {
  __typename?: 'CreateOrderResult';
  missingParts: Array<Scalars['String']>;
  order: Order;
  success: Scalars['Boolean'];
};


export enum GqlErrorCodeEnum {
  FileSizeExceeded = 'FILE_SIZE_EXCEEDED',
  UnsupportedFileFormat = 'UNSUPPORTED_FILE_FORMAT',
  AlreadyExist = 'ALREADY_EXIST',
  InvalidImportFile = 'INVALID_IMPORT_FILE',
  NotFound = 'NOT_FOUND',
  BadLoginInput = 'BAD_LOGIN_INPUT',
  BadRegisterInput = 'BAD_REGISTER_INPUT',
  ZoomApiError = 'ZOOM_API_ERROR',
  UnsupportedType = 'UNSUPPORTED_TYPE',
  Logic = 'LOGIC',
  MaxUsersCountExceeded = 'MAX_USERS_COUNT_EXCEEDED'
}

export type ImportedUser = {
  __typename?: 'ImportedUser';
  email?: Maybe<Scalars['String']>;
  statusCode?: Maybe<ImportedUserStatusCodeEnum>;
};

export enum ImportedUserStatusCodeEnum {
  Ok = 'OK',
  SpaceDoesNotExist = 'SPACE_DOES_NOT_EXIST',
  UserDoesNotExist = 'USER_DOES_NOT_EXIST',
  UserAlreadyExist = 'USER_ALREADY_EXIST',
  UserInvalidEmail = 'USER_INVALID_EMAIL',
  UserInvalidPhone = 'USER_INVALID_PHONE'
}

export type ImportedUsers = {
  __typename?: 'ImportedUsers';
  succeeded: Array<ImportedUser>;
  errored: Array<ImportedUser>;
};

export type Invoice = {
  __typename?: 'Invoice';
  orderId: Scalars['String'];
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceUrl?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceInput = {
  orderId: Scalars['String'];
  stripeInvoiceId: Scalars['String'];
  stripeInvoiceUrl: Scalars['String'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  checkManyPartsAvailability: Array<Part>;
  sendOrdersInTopTrans: Array<Scalars['String']>;
  resetTopTransForOrders: Array<Scalars['String']>;
  prepareDispatchForOrders: PrepareDispatchForOrdersResult;
  prepareOrderForDispatch: Order;
  validateOrder: ValidateOrderResponse;
  reviewOrder: Order;
  createInvoice: Scalars['String'];
  acceptBillingInfoAndCalculateShippingPrice: AcceptBillingInfoAndCalculateShippingPriceResponse;
  checkOrderAvailability: CreateOrderResult;
  makeBindingOrder: CreateOrderResult;
  createBillingInfo: BillingInfo;
  login: AuthPayload;
  forgetPassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  changePassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  refreshToken: Token;
  createUser: User;
  registerUser: User;
  editCurrentUser: User;
  editUser: User;
  removeUser: Scalars['ID'];
  confirmGdpr: User;
  setUserActive: User;
  importUsers: ImportedUsers;
  exportUsers: Scalars['String'];
  createSpace: Space;
  checkPart: Part;
  refreshPart: Part;
  orderPart: Part;
  removePart: Scalars['String'];
  updatePartAmount: Part;
  editSpace: Space;
  removeSpace: Scalars['ID'];
  setSpaceActive: Space;
  exportSpaces: Scalars['String'];
  uploadFile: Scalars['String'];
};


export type MutationCheckManyPartsAvailabilityArgs = {
  checkManyPartsAvailabilityInput: CheckManyPartsAvailabilityInput;
};


export type MutationSendOrdersInTopTransArgs = {
  orderIds: Array<Scalars['ID']>;
};


export type MutationResetTopTransForOrdersArgs = {
  orderIds: Array<Scalars['ID']>;
};


export type MutationPrepareDispatchForOrdersArgs = {
  orderIds: Array<Scalars['ID']>;
};


export type MutationPrepareOrderForDispatchArgs = {
  id: Scalars['ID'];
};


export type MutationValidateOrderArgs = {
  id: Scalars['ID'];
};


export type MutationReviewOrderArgs = {
  input: ReviewOrderInput;
};


export type MutationCreateInvoiceArgs = {
  orderId: Scalars['ID'];
};


export type MutationAcceptBillingInfoAndCalculateShippingPriceArgs = {
  billingInfoInput: BillingInfoInput;
};


export type MutationCheckOrderAvailabilityArgs = {
  orderId: Scalars['ID'];
};


export type MutationMakeBindingOrderArgs = {
  orderId: Scalars['ID'];
};


export type MutationCreateBillingInfoArgs = {
  billingInfoInput: BillingInfoInput;
};


export type MutationLoginArgs = {
  loginInput: LoginInput;
};


export type MutationForgetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


export type MutationChangePasswordArgs = {
  changePasswordInput: ChangePasswordInput;
};


export type MutationCreateUserArgs = {
  userInput: UserInput;
};


export type MutationRegisterUserArgs = {
  userRegisterInput: UserRegisterInput;
};


export type MutationEditCurrentUserArgs = {
  userInput: UserEditInput;
  password?: Maybe<Scalars['String']>;
};


export type MutationEditUserArgs = {
  id: Scalars['ID'];
  userInput: UserEditInput;
};


export type MutationRemoveUserArgs = {
  id: Scalars['ID'];
  force?: Maybe<Scalars['Boolean']>;
};


export type MutationConfirmGdprArgs = {
  id: Scalars['ID'];
};


export type MutationSetUserActiveArgs = {
  id: Scalars['ID'];
  active: Scalars['Boolean'];
};


export type MutationImportUsersArgs = {
  csv: Scalars['Upload'];
};


export type MutationExportUsersArgs = {
  usersInput: UsersInput;
  paginationInput?: Maybe<PaginationInput>;
};


export type MutationCreateSpaceArgs = {
  spaceInput: SpaceInput;
};


export type MutationCheckPartArgs = {
  partInput: PartInput;
};


export type MutationRefreshPartArgs = {
  id: Scalars['ID'];
};


export type MutationOrderPartArgs = {
  id: Scalars['ID'];
};


export type MutationRemovePartArgs = {
  id: Scalars['ID'];
};


export type MutationUpdatePartAmountArgs = {
  updatePartAmountInput: UpdatePartAmountInput;
};


export type MutationEditSpaceArgs = {
  id: Scalars['ID'];
  spaceInput: SpaceInput;
};


export type MutationRemoveSpaceArgs = {
  id: Scalars['ID'];
  force?: Maybe<Scalars['Boolean']>;
};


export type MutationSetSpaceActiveArgs = {
  id: Scalars['ID'];
  active: Scalars['Boolean'];
};


export type MutationExportSpacesArgs = {
  spacesInput: SpacesInput;
  paginationInput?: Maybe<PaginationInput>;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  orderNumber: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  orderedAt?: Maybe<Scalars['DateTime']>;
  shippingPrice?: Maybe<Scalars['Float']>;
  emailSent?: Maybe<Scalars['Boolean']>;
  parts: Array<Part>;
  status: OrderStatusEnum;
  billingInfo?: Maybe<BillingInfo>;
  note?: Maybe<Scalars['String']>;
  topTransId?: Maybe<Scalars['Int']>;
  invoiceUrl?: Maybe<Scalars['String']>;
};

export enum OrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderFilterInput = {
  status?: Maybe<OrderStatusEnum>;
  userId?: Maybe<Scalars['ID']>;
};

export enum OrderStatusEnum {
  Open = 'OPEN',
  Unpaid = 'UNPAID',
  Paid = 'PAID',
  Ordered = 'ORDERED',
  PaidProcessing = 'PAID_PROCESSING',
  FailedOrder = 'FAILED_ORDER',
  Reviewed = 'REVIEWED',
  Incomplete = 'INCOMPLETE',
  Dispatched = 'DISPATCHED',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED'
}

export type PaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type PaginationOutput = {
  __typename?: 'PaginationOutput';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  count: Scalars['Int'];
};

export type Part = {
  __typename?: 'Part';
  id: Scalars['ID'];
  number: Scalars['String'];
  oemNumber?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  price?: Maybe<Scalars['Float']>;
  brand: BrandEnum;
  scrapedBrand?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
};

export type PartInput = {
  spaceId: Scalars['ID'];
  orderId: Scalars['ID'];
  number: Scalars['String'];
  amount: Scalars['Int'];
  brand: BrandEnum;
};

export type PartMeasures = {
  __typename?: 'PartMeasures';
  weight: Scalars['Float'];
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type PartParams = {
  partNumber: Scalars['String'];
  amount: Scalars['Int'];
};

export type Parts = {
  __typename?: 'Parts';
  list: Array<Part>;
  pagination?: Maybe<PaginationOutput>;
};

export type PartsFilter = {
  number?: Maybe<Scalars['String']>;
  processed?: Maybe<Scalars['Boolean']>;
};

export type PartsInput = {
  filter: PartsFilter;
  sort?: Maybe<SortInput>;
};

export enum PermissionEnum {
  ManageAll = 'MANAGE_ALL',
  SpaceRead = 'SPACE_READ',
  SpaceManage = 'SPACE_MANAGE',
  UsersRead = 'USERS_READ',
  UsersManage = 'USERS_MANAGE',
  UsersSpaceManage = 'USERS_SPACE_MANAGE',
  PartsManage = 'PARTS_MANAGE',
  PartsOrder = 'PARTS_ORDER',
  ManageOrders = 'MANAGE_ORDERS'
}

export type PrepareDispatchForOrdersResult = {
  __typename?: 'PrepareDispatchForOrdersResult';
  url: Scalars['String'];
  validOrders: Array<Order>;
  invalidOrders: Array<Order>;
};

export type Query = {
  __typename?: 'Query';
  getPartBrandOptions: Array<BrandEnum>;
  getOrders: Array<Order>;
  getOrderWithId: Order;
  getOrdersForInterval: Array<Order>;
  getBillingInfo?: Maybe<BillingInfo>;
  getBusinessOwnerBillingInfo: BillingInfo;
  getCurrentOrder: Order;
  currentUser: User;
  validateResetCode: Scalars['Boolean'];
  user: User;
  users: Users;
  space: Space;
  spaces: Spaces;
  part: Part;
  parts: Parts;
};


export type QueryGetOrdersArgs = {
  filterInput?: Maybe<OrderFilterInput>;
  paginationInput?: Maybe<PaginationInput>;
};


export type QueryGetOrderWithIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetOrdersForIntervalArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};


export type QueryGetBillingInfoArgs = {
  orderId?: Maybe<Scalars['ID']>;
  spaceId?: Maybe<Scalars['ID']>;
};


export type QueryGetCurrentOrderArgs = {
  userId: Scalars['ID'];
};


export type QueryValidateResetCodeArgs = {
  code: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  usersInput: UsersInput;
  paginationInput?: Maybe<PaginationInput>;
};


export type QuerySpaceArgs = {
  id: Scalars['ID'];
};


export type QuerySpacesArgs = {
  spacesInput: SpacesInput;
  paginationInput?: Maybe<PaginationInput>;
};


export type QueryPartArgs = {
  id: Scalars['ID'];
};


export type QueryPartsArgs = {
  partsInput: PartsInput;
  paginationInput?: Maybe<PaginationInput>;
};

export type ResetPasswordInput = {
  resetCode: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ReviewOrderInput = {
  missingPartNumbers: Array<Scalars['String']>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type SignUpInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SortInput = {
  by: Scalars['String'];
  order: OrderEnum;
};

export type Space = {
  __typename?: 'Space';
  id: Scalars['ID'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  approved: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  users?: Maybe<Array<User>>;
};

export type SpaceInput = {
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  approved?: Maybe<Scalars['Boolean']>;
};

export type Spaces = {
  __typename?: 'Spaces';
  list: Array<Space>;
  pagination?: Maybe<PaginationOutput>;
};

export type SpacesFilter = {
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  approved?: Maybe<Scalars['Boolean']>;
};

export type SpacesInput = {
  filter: SpacesFilter;
  sort?: Maybe<SortInput>;
};

/**
 * refreshToken is provided as a http-only cookie
 * accessToken should be used as an Authorization: Bearer <token>
 */
export type Token = {
  __typename?: 'Token';
  accessToken: Scalars['String'];
};

export type UpdatePartAmountInput = {
  id: Scalars['ID'];
  amount: Scalars['Int'];
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  isFirstLogin: Scalars['Boolean'];
  isPasswordChangeNeeded: Scalars['Boolean'];
  gdprConfirmed: Scalars['Boolean'];
  active: Scalars['Boolean'];
  email: Scalars['String'];
  role: UserRoleEnum;
  permissions: Array<PermissionEnum>;
  /** permissions: [PermissionEnum!]! */
  lastLoggedAt?: Maybe<Scalars['DateTime']>;
  space: Space;
};

export type UserEditInput = {
  spaceId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleEnum>;
};

export type UserInput = {
  spaceId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  role: UserRoleEnum;
};

export type UserRegisterInput = {
  spaceName: Scalars['String'];
  email: Scalars['String'];
};

export enum UserRoleEnum {
  Admin = 'ADMIN',
  SpaceManager = 'SPACE_MANAGER',
  User = 'USER'
}

export type Users = {
  __typename?: 'Users';
  list: Array<User>;
  pagination?: Maybe<PaginationOutput>;
};

export type UsersFilter = {
  email?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UserRoleEnum>;
  hadLogged?: Maybe<Scalars['Boolean']>;
  spaceId?: Maybe<Scalars['String']>;
};

export type UsersInput = {
  filter: UsersFilter;
  sort?: Maybe<SortInput>;
};

export type ValidateOrderResponse = {
  __typename?: 'ValidateOrderResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type TokenFragment = (
  { __typename?: 'Token' }
  & Pick<Token, 'accessToken'>
);

export type AuthUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'active' | 'role' | 'permissions' | 'isFirstLogin' | 'gdprConfirmed'>
  & { space: (
    { __typename?: 'Space' }
    & Pick<Space, 'id' | 'name'>
  ) }
);

export type BillingInfoFragment = (
  { __typename?: 'BillingInfo' }
  & Pick<BillingInfo, 'name' | 'telephone' | 'email' | 'ico' | 'dic' | 'psc' | 'city' | 'street'>
);

export type OrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'shippingPrice'>
  & { parts: Array<(
    { __typename?: 'Part' }
    & PartFragment
  )>, billingInfo?: Maybe<(
    { __typename?: 'BillingInfo' }
    & BillingInfoFragment
  )> }
);

export type AdminTableOrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'orderNumber' | 'status' | 'orderedAt' | 'topTransId'>
  & { billingInfo?: Maybe<(
    { __typename?: 'BillingInfo' }
    & BillingInfoFragment
  )>, parts: Array<(
    { __typename?: 'Part' }
    & PartFragment
  )> }
);

export type CustomerTableOrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'orderNumber' | 'status' | 'orderedAt' | 'invoiceUrl'>
  & { parts: Array<(
    { __typename?: 'Part' }
    & PartFragment
  )> }
);

export type PartsOnlyOrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'note'>
  & { parts: Array<(
    { __typename?: 'Part' }
    & PartFragment
  )> }
);

export type ValidateOrderResponseFragment = (
  { __typename?: 'ValidateOrderResponse' }
  & Pick<ValidateOrderResponse, 'success' | 'error'>
);

export type PrepareDispatchResultFragment = (
  { __typename?: 'PrepareDispatchForOrdersResult' }
  & Pick<PrepareDispatchForOrdersResult, 'url'>
  & { validOrders: Array<(
    { __typename?: 'Order' }
    & AdminTableOrderFragment
  )>, invalidOrders: Array<(
    { __typename?: 'Order' }
    & AdminTableOrderFragment
  )> }
);

export type PartFragment = (
  { __typename?: 'Part' }
  & Pick<Part, 'id' | 'number' | 'oemNumber' | 'amount' | 'price' | 'weight' | 'brand' | 'name' | 'scrapedBrand' | 'deliveryDate' | 'length' | 'width' | 'height'>
);

export type SpaceTableFragment = (
  { __typename?: 'Space' }
  & Pick<Space, 'id' | 'name' | 'active' | 'approved'>
);

export type SpaceFragment = (
  { __typename?: 'Space' }
  & Pick<Space, 'id' | 'name' | 'active' | 'approved'>
);

export type SpaceOptionFragment = (
  { __typename?: 'Space' }
  & Pick<Space, 'id' | 'name'>
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'role' | 'active' | 'gdprConfirmed' | 'isFirstLogin'>
  & { space: (
    { __typename?: 'Space' }
    & Pick<Space, 'id' | 'name'>
  ) }
);

export type UserOptionFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email'>
);

export type ImportedUserFragment = (
  { __typename?: 'ImportedUser' }
  & Pick<ImportedUser, 'email' | 'statusCode'>
);

export type LoginMutationVariables = Exact<{
  loginInput: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'id' | 'email'>
    & { token: (
      { __typename?: 'Token' }
      & TokenFragment
    ) }
  ) }
);

export type RefreshMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshMutation = (
  { __typename?: 'Mutation' }
  & { refresh: (
    { __typename?: 'Token' }
    & TokenFragment
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ForgottenPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgottenPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgetPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  resetPasswordInput: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type ChangePasswordMutationVariables = Exact<{
  changePasswordInput: ChangePasswordInput;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type CreateBillingInfoMutationVariables = Exact<{
  billingInfoInput: BillingInfoInput;
}>;


export type CreateBillingInfoMutation = (
  { __typename?: 'Mutation' }
  & { createBillingInfo: (
    { __typename?: 'BillingInfo' }
    & BillingInfoFragment
  ) }
);

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadFile'>
);

export type EditCurrentUserMutationVariables = Exact<{
  userInput: UserEditInput;
  password?: Maybe<Scalars['String']>;
}>;


export type EditCurrentUserMutation = (
  { __typename?: 'Mutation' }
  & { editCurrentUser: (
    { __typename?: 'User' }
    & AuthUserFragment
  ) }
);

export type CreateInvoiceMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type CreateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createInvoice'>
);

export type CheckOrderAvailabilityMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type CheckOrderAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { checkOrderAvailability: (
    { __typename?: 'CreateOrderResult' }
    & Pick<CreateOrderResult, 'missingParts' | 'success'>
    & { order: (
      { __typename?: 'Order' }
      & OrderFragment
    ) }
  ) }
);

export type MakeBindingOrderMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type MakeBindingOrderMutation = (
  { __typename?: 'Mutation' }
  & { makeBindingOrder: (
    { __typename?: 'CreateOrderResult' }
    & Pick<CreateOrderResult, 'missingParts' | 'success'>
    & { order: (
      { __typename?: 'Order' }
      & OrderFragment
    ) }
  ) }
);

export type AcceptBillingInfoAndCalculateShippingPriceMutationVariables = Exact<{
  billingInfoInput: BillingInfoInput;
}>;


export type AcceptBillingInfoAndCalculateShippingPriceMutation = (
  { __typename?: 'Mutation' }
  & { acceptBillingInfoAndCalculateShippingPrice: (
    { __typename?: 'AcceptBillingInfoAndCalculateShippingPriceResponse' }
    & Pick<AcceptBillingInfoAndCalculateShippingPriceResponse, 'errors' | 'success'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderFragment
    )> }
  ) }
);

export type ReviewOrderMutationVariables = Exact<{
  input: ReviewOrderInput;
}>;


export type ReviewOrderMutation = (
  { __typename?: 'Mutation' }
  & { reviewOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type ValidateOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ValidateOrderMutation = (
  { __typename?: 'Mutation' }
  & { validateOrder: (
    { __typename?: 'ValidateOrderResponse' }
    & ValidateOrderResponseFragment
  ) }
);

export type PrepareDispatchForOrdersMutationVariables = Exact<{
  orderIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type PrepareDispatchForOrdersMutation = (
  { __typename?: 'Mutation' }
  & { prepareDispatchForOrders: (
    { __typename?: 'PrepareDispatchForOrdersResult' }
    & PrepareDispatchResultFragment
  ) }
);

export type ResetTopTransForOrdersMutationVariables = Exact<{
  orderIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ResetTopTransForOrdersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetTopTransForOrders'>
);

export type SendOrdersInTopTransMutationVariables = Exact<{
  orderIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SendOrdersInTopTransMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendOrdersInTopTrans'>
);

export type CheckPartMutationVariables = Exact<{
  partInput: PartInput;
}>;


export type CheckPartMutation = (
  { __typename?: 'Mutation' }
  & { checkPart: (
    { __typename?: 'Part' }
    & PartFragment
  ) }
);

export type RemovePartMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemovePartMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removePart'>
);

export type UpdatePartAmountMutationVariables = Exact<{
  updatePartAmountInput: UpdatePartAmountInput;
}>;


export type UpdatePartAmountMutation = (
  { __typename?: 'Mutation' }
  & { updatePartAmount: (
    { __typename?: 'Part' }
    & PartFragment
  ) }
);

export type RefreshPartMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RefreshPartMutation = (
  { __typename?: 'Mutation' }
  & { refreshPart: (
    { __typename?: 'Part' }
    & PartFragment
  ) }
);

export type CheckManyPartsAvailabilityMutationVariables = Exact<{
  checkManyPartsAvailabilityInput: CheckManyPartsAvailabilityInput;
}>;


export type CheckManyPartsAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { checkManyPartsAvailability: Array<(
    { __typename?: 'Part' }
    & PartFragment
  )> }
);

export type CreateSpaceMutationVariables = Exact<{
  spaceInput: SpaceInput;
}>;


export type CreateSpaceMutation = (
  { __typename?: 'Mutation' }
  & { createSpace: (
    { __typename?: 'Space' }
    & SpaceFragment
  ) }
);

export type EditSpaceMutationVariables = Exact<{
  id: Scalars['ID'];
  spaceInput: SpaceInput;
}>;


export type EditSpaceMutation = (
  { __typename?: 'Mutation' }
  & { editSpace: (
    { __typename?: 'Space' }
    & SpaceFragment
  ) }
);

export type RemoveSpaceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveSpaceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeSpace'>
);

export type SetSpaceActiveMutationVariables = Exact<{
  id: Scalars['ID'];
  active: Scalars['Boolean'];
}>;


export type SetSpaceActiveMutation = (
  { __typename?: 'Mutation' }
  & { setSpaceActive: (
    { __typename?: 'Space' }
    & SpaceFragment
  ) }
);

export type ExportSpacesMutationVariables = Exact<{
  spacesInput: SpacesInput;
  paginationInput?: Maybe<PaginationInput>;
}>;


export type ExportSpacesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportSpaces'>
);

export type CreateUserMutationVariables = Exact<{
  userInput: UserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type RegisterUserMutationVariables = Exact<{
  userRegisterInput: UserRegisterInput;
}>;


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { registerUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type EditUserMutationVariables = Exact<{
  id: Scalars['ID'];
  userInput: UserEditInput;
}>;


export type EditUserMutation = (
  { __typename?: 'Mutation' }
  & { editUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['ID'];
  force?: Maybe<Scalars['Boolean']>;
}>;


export type RemoveUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeUser'>
);

export type SetUserActiveMutationVariables = Exact<{
  id: Scalars['ID'];
  active: Scalars['Boolean'];
}>;


export type SetUserActiveMutation = (
  { __typename?: 'Mutation' }
  & { setUserActive: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type ExportUsersMutationVariables = Exact<{
  usersInput: UsersInput;
  paginationInput?: Maybe<PaginationInput>;
}>;


export type ExportUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportUsers'>
);

export type ImportUsersMutationVariables = Exact<{
  csv: Scalars['Upload'];
}>;


export type ImportUsersMutation = (
  { __typename?: 'Mutation' }
  & { importUsers: (
    { __typename?: 'ImportedUsers' }
    & { succeeded: Array<(
      { __typename?: 'ImportedUser' }
      & ImportedUserFragment
    )>, errored: Array<(
      { __typename?: 'ImportedUser' }
      & ImportedUserFragment
    )> }
  ) }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'User' }
    & AuthUserFragment
  ) }
);

export type ValidateResetCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type ValidateResetCodeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'validateResetCode'>
);

export type GetBusinessOwnerBillingInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessOwnerBillingInfoQuery = (
  { __typename?: 'Query' }
  & { getBusinessOwnerBillingInfo: (
    { __typename?: 'BillingInfo' }
    & BillingInfoFragment
  ) }
);

export type GetBillingInfoQueryVariables = Exact<{
  orderId?: Maybe<Scalars['ID']>;
  spaceId?: Maybe<Scalars['ID']>;
}>;


export type GetBillingInfoQuery = (
  { __typename?: 'Query' }
  & { getBillingInfo?: Maybe<(
    { __typename?: 'BillingInfo' }
    & BillingInfoFragment
  )> }
);

export type GetCurrentOrderQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetCurrentOrderQuery = (
  { __typename?: 'Query' }
  & { getCurrentOrder: (
    { __typename?: 'Order' }
    & OrderFragment
  ) }
);

export type GetOrdersForIntervalQueryVariables = Exact<{
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}>;


export type GetOrdersForIntervalQuery = (
  { __typename?: 'Query' }
  & { getOrdersForInterval: Array<(
    { __typename?: 'Order' }
    & AdminTableOrderFragment
  )> }
);

export type GetOrderWithIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrderWithIdQuery = (
  { __typename?: 'Query' }
  & { getOrderWithId: (
    { __typename?: 'Order' }
    & PartsOnlyOrderFragment
  ) }
);

export type GetOrdersQueryVariables = Exact<{
  filterInput: OrderFilterInput;
  paginationInput: PaginationInput;
}>;


export type GetOrdersQuery = (
  { __typename?: 'Query' }
  & { getOrders: Array<(
    { __typename?: 'Order' }
    & AdminTableOrderFragment
  )> }
);

export type GetOrdersCustomerQueryVariables = Exact<{
  filterInput: OrderFilterInput;
  paginationInput: PaginationInput;
}>;


export type GetOrdersCustomerQuery = (
  { __typename?: 'Query' }
  & { getOrders: Array<(
    { __typename?: 'Order' }
    & CustomerTableOrderFragment
  )> }
);

export type SpacesQueryVariables = Exact<{
  spacesInput: SpacesInput;
  paginationInput?: Maybe<PaginationInput>;
}>;


export type SpacesQuery = (
  { __typename?: 'Query' }
  & { spaces: (
    { __typename?: 'Spaces' }
    & { list: Array<(
      { __typename?: 'Space' }
      & SpaceTableFragment
    )>, pagination?: Maybe<(
      { __typename?: 'PaginationOutput' }
      & Pick<PaginationOutput, 'limit' | 'offset' | 'count'>
    )> }
  ) }
);

export type SpaceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SpaceQuery = (
  { __typename?: 'Query' }
  & { space: (
    { __typename?: 'Space' }
    & SpaceFragment
  ) }
);

export type SpaceOptionsQueryVariables = Exact<{
  spacesInput: SpacesInput;
  paginationInput?: Maybe<PaginationInput>;
}>;


export type SpaceOptionsQuery = (
  { __typename?: 'Query' }
  & { spaces: (
    { __typename?: 'Spaces' }
    & { list: Array<(
      { __typename?: 'Space' }
      & SpaceOptionFragment
    )> }
  ) }
);

export type GetPartBrandOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPartBrandOptionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPartBrandOptions'>
);

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type UsersQueryVariables = Exact<{
  usersInput: UsersInput;
  paginationInput?: Maybe<PaginationInput>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'Users' }
    & { list: Array<(
      { __typename?: 'User' }
      & UserFragment
    )>, pagination?: Maybe<(
      { __typename?: 'PaginationOutput' }
      & Pick<PaginationOutput, 'limit' | 'offset' | 'count'>
    )> }
  ) }
);

export type UserOptionsQueryVariables = Exact<{
  usersInput: UsersInput;
  paginationInput?: Maybe<PaginationInput>;
}>;


export type UserOptionsQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'Users' }
    & { list: Array<(
      { __typename?: 'User' }
      & UserOptionFragment
    )>, pagination?: Maybe<(
      { __typename?: 'PaginationOutput' }
      & Pick<PaginationOutput, 'limit' | 'offset' | 'count'>
    )> }
  ) }
);

export const TokenFragmentDoc = gql`
    fragment Token on Token {
  accessToken
}
    `;
export const AuthUserFragmentDoc = gql`
    fragment AuthUser on User {
  space {
    id
    name
  }
  id
  email
  active
  role
  permissions
  isFirstLogin
  gdprConfirmed
}
    `;
export const PartFragmentDoc = gql`
    fragment Part on Part {
  id
  number
  oemNumber
  amount
  price
  weight
  brand
  name
  scrapedBrand
  deliveryDate
  length
  width
  height
}
    `;
export const BillingInfoFragmentDoc = gql`
    fragment BillingInfo on BillingInfo {
  name
  telephone
  email
  ico
  dic
  psc
  city
  street
}
    `;
export const OrderFragmentDoc = gql`
    fragment Order on Order {
  id
  parts {
    ...Part
  }
  billingInfo {
    ...BillingInfo
  }
  shippingPrice
}
    ${PartFragmentDoc}
${BillingInfoFragmentDoc}`;
export const CustomerTableOrderFragmentDoc = gql`
    fragment CustomerTableOrder on Order {
  id
  orderNumber
  status
  orderedAt
  invoiceUrl
  parts {
    ...Part
  }
}
    ${PartFragmentDoc}`;
export const PartsOnlyOrderFragmentDoc = gql`
    fragment PartsOnlyOrder on Order {
  id
  note
  parts {
    ...Part
  }
}
    ${PartFragmentDoc}`;
export const ValidateOrderResponseFragmentDoc = gql`
    fragment ValidateOrderResponse on ValidateOrderResponse {
  success
  error
}
    `;
export const AdminTableOrderFragmentDoc = gql`
    fragment AdminTableOrder on Order {
  id
  orderNumber
  billingInfo {
    ...BillingInfo
  }
  status
  orderedAt
  topTransId
  parts {
    ...Part
  }
}
    ${BillingInfoFragmentDoc}
${PartFragmentDoc}`;
export const PrepareDispatchResultFragmentDoc = gql`
    fragment PrepareDispatchResult on PrepareDispatchForOrdersResult {
  url
  validOrders {
    ...AdminTableOrder
  }
  invalidOrders {
    ...AdminTableOrder
  }
}
    ${AdminTableOrderFragmentDoc}`;
export const SpaceTableFragmentDoc = gql`
    fragment SpaceTable on Space {
  id
  name
  active
  approved
}
    `;
export const SpaceFragmentDoc = gql`
    fragment Space on Space {
  id
  name
  active
  approved
}
    `;
export const SpaceOptionFragmentDoc = gql`
    fragment SpaceOption on Space {
  id
  name
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
  role
  active
  gdprConfirmed
  space {
    id
    name
  }
  isFirstLogin
}
    `;
export const UserOptionFragmentDoc = gql`
    fragment UserOption on User {
  id
  email
}
    `;
export const ImportedUserFragmentDoc = gql`
    fragment ImportedUser on ImportedUser {
  email
  statusCode
}
    `;
export const LoginDocument = gql`
    mutation Login($loginInput: LoginInput!) {
  login(loginInput: $loginInput) {
    id
    email
    token {
      ...Token
    }
  }
}
    ${TokenFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginInput: // value for 'loginInput'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RefreshDocument = gql`
    mutation Refresh {
  refresh: refreshToken {
    ...Token
  }
}
    ${TokenFragmentDoc}`;
export type RefreshMutationFn = Apollo.MutationFunction<RefreshMutation, RefreshMutationVariables>;

/**
 * __useRefreshMutation__
 *
 * To run a mutation, you first call `useRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMutation, { data, loading, error }] = useRefreshMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshMutation(baseOptions?: Apollo.MutationHookOptions<RefreshMutation, RefreshMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshMutation, RefreshMutationVariables>(RefreshDocument, options);
      }
export type RefreshMutationHookResult = ReturnType<typeof useRefreshMutation>;
export type RefreshMutationResult = Apollo.MutationResult<RefreshMutation>;
export type RefreshMutationOptions = Apollo.BaseMutationOptions<RefreshMutation, RefreshMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ForgottenPasswordDocument = gql`
    mutation ForgottenPassword($email: String!) {
  forgetPassword(email: $email)
}
    `;
export type ForgottenPasswordMutationFn = Apollo.MutationFunction<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>;

/**
 * __useForgottenPasswordMutation__
 *
 * To run a mutation, you first call `useForgottenPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgottenPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgottenPasswordMutation, { data, loading, error }] = useForgottenPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgottenPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>(ForgottenPasswordDocument, options);
      }
export type ForgottenPasswordMutationHookResult = ReturnType<typeof useForgottenPasswordMutation>;
export type ForgottenPasswordMutationResult = Apollo.MutationResult<ForgottenPasswordMutation>;
export type ForgottenPasswordMutationOptions = Apollo.BaseMutationOptions<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($resetPasswordInput: ResetPasswordInput!) {
  resetPassword(resetPasswordInput: $resetPasswordInput)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetPasswordInput: // value for 'resetPasswordInput'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($changePasswordInput: ChangePasswordInput!) {
  changePassword(changePasswordInput: $changePasswordInput)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      changePasswordInput: // value for 'changePasswordInput'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateBillingInfoDocument = gql`
    mutation CreateBillingInfo($billingInfoInput: BillingInfoInput!) {
  createBillingInfo(billingInfoInput: $billingInfoInput) {
    ...BillingInfo
  }
}
    ${BillingInfoFragmentDoc}`;
export type CreateBillingInfoMutationFn = Apollo.MutationFunction<CreateBillingInfoMutation, CreateBillingInfoMutationVariables>;

/**
 * __useCreateBillingInfoMutation__
 *
 * To run a mutation, you first call `useCreateBillingInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillingInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillingInfoMutation, { data, loading, error }] = useCreateBillingInfoMutation({
 *   variables: {
 *      billingInfoInput: // value for 'billingInfoInput'
 *   },
 * });
 */
export function useCreateBillingInfoMutation(baseOptions?: Apollo.MutationHookOptions<CreateBillingInfoMutation, CreateBillingInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBillingInfoMutation, CreateBillingInfoMutationVariables>(CreateBillingInfoDocument, options);
      }
export type CreateBillingInfoMutationHookResult = ReturnType<typeof useCreateBillingInfoMutation>;
export type CreateBillingInfoMutationResult = Apollo.MutationResult<CreateBillingInfoMutation>;
export type CreateBillingInfoMutationOptions = Apollo.BaseMutationOptions<CreateBillingInfoMutation, CreateBillingInfoMutationVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($file: Upload!) {
  uploadFile(file: $file)
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const EditCurrentUserDocument = gql`
    mutation EditCurrentUser($userInput: UserEditInput!, $password: String) {
  editCurrentUser(userInput: $userInput, password: $password) {
    ...AuthUser
  }
}
    ${AuthUserFragmentDoc}`;
export type EditCurrentUserMutationFn = Apollo.MutationFunction<EditCurrentUserMutation, EditCurrentUserMutationVariables>;

/**
 * __useEditCurrentUserMutation__
 *
 * To run a mutation, you first call `useEditCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCurrentUserMutation, { data, loading, error }] = useEditCurrentUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useEditCurrentUserMutation(baseOptions?: Apollo.MutationHookOptions<EditCurrentUserMutation, EditCurrentUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCurrentUserMutation, EditCurrentUserMutationVariables>(EditCurrentUserDocument, options);
      }
export type EditCurrentUserMutationHookResult = ReturnType<typeof useEditCurrentUserMutation>;
export type EditCurrentUserMutationResult = Apollo.MutationResult<EditCurrentUserMutation>;
export type EditCurrentUserMutationOptions = Apollo.BaseMutationOptions<EditCurrentUserMutation, EditCurrentUserMutationVariables>;
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($orderId: ID!) {
  createInvoice(orderId: $orderId)
}
    `;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCreateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, options);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const CheckOrderAvailabilityDocument = gql`
    mutation CheckOrderAvailability($orderId: ID!) {
  checkOrderAvailability(orderId: $orderId) {
    missingParts
    order {
      ...Order
    }
    success
  }
}
    ${OrderFragmentDoc}`;
export type CheckOrderAvailabilityMutationFn = Apollo.MutationFunction<CheckOrderAvailabilityMutation, CheckOrderAvailabilityMutationVariables>;

/**
 * __useCheckOrderAvailabilityMutation__
 *
 * To run a mutation, you first call `useCheckOrderAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOrderAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOrderAvailabilityMutation, { data, loading, error }] = useCheckOrderAvailabilityMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCheckOrderAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<CheckOrderAvailabilityMutation, CheckOrderAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckOrderAvailabilityMutation, CheckOrderAvailabilityMutationVariables>(CheckOrderAvailabilityDocument, options);
      }
export type CheckOrderAvailabilityMutationHookResult = ReturnType<typeof useCheckOrderAvailabilityMutation>;
export type CheckOrderAvailabilityMutationResult = Apollo.MutationResult<CheckOrderAvailabilityMutation>;
export type CheckOrderAvailabilityMutationOptions = Apollo.BaseMutationOptions<CheckOrderAvailabilityMutation, CheckOrderAvailabilityMutationVariables>;
export const MakeBindingOrderDocument = gql`
    mutation MakeBindingOrder($orderId: ID!) {
  makeBindingOrder(orderId: $orderId) {
    missingParts
    order {
      ...Order
    }
    success
  }
}
    ${OrderFragmentDoc}`;
export type MakeBindingOrderMutationFn = Apollo.MutationFunction<MakeBindingOrderMutation, MakeBindingOrderMutationVariables>;

/**
 * __useMakeBindingOrderMutation__
 *
 * To run a mutation, you first call `useMakeBindingOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeBindingOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeBindingOrderMutation, { data, loading, error }] = useMakeBindingOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useMakeBindingOrderMutation(baseOptions?: Apollo.MutationHookOptions<MakeBindingOrderMutation, MakeBindingOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeBindingOrderMutation, MakeBindingOrderMutationVariables>(MakeBindingOrderDocument, options);
      }
export type MakeBindingOrderMutationHookResult = ReturnType<typeof useMakeBindingOrderMutation>;
export type MakeBindingOrderMutationResult = Apollo.MutationResult<MakeBindingOrderMutation>;
export type MakeBindingOrderMutationOptions = Apollo.BaseMutationOptions<MakeBindingOrderMutation, MakeBindingOrderMutationVariables>;
export const AcceptBillingInfoAndCalculateShippingPriceDocument = gql`
    mutation AcceptBillingInfoAndCalculateShippingPrice($billingInfoInput: BillingInfoInput!) {
  acceptBillingInfoAndCalculateShippingPrice(billingInfoInput: $billingInfoInput) {
    order {
      ...Order
    }
    errors
    success
  }
}
    ${OrderFragmentDoc}`;
export type AcceptBillingInfoAndCalculateShippingPriceMutationFn = Apollo.MutationFunction<AcceptBillingInfoAndCalculateShippingPriceMutation, AcceptBillingInfoAndCalculateShippingPriceMutationVariables>;

/**
 * __useAcceptBillingInfoAndCalculateShippingPriceMutation__
 *
 * To run a mutation, you first call `useAcceptBillingInfoAndCalculateShippingPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptBillingInfoAndCalculateShippingPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptBillingInfoAndCalculateShippingPriceMutation, { data, loading, error }] = useAcceptBillingInfoAndCalculateShippingPriceMutation({
 *   variables: {
 *      billingInfoInput: // value for 'billingInfoInput'
 *   },
 * });
 */
export function useAcceptBillingInfoAndCalculateShippingPriceMutation(baseOptions?: Apollo.MutationHookOptions<AcceptBillingInfoAndCalculateShippingPriceMutation, AcceptBillingInfoAndCalculateShippingPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptBillingInfoAndCalculateShippingPriceMutation, AcceptBillingInfoAndCalculateShippingPriceMutationVariables>(AcceptBillingInfoAndCalculateShippingPriceDocument, options);
      }
export type AcceptBillingInfoAndCalculateShippingPriceMutationHookResult = ReturnType<typeof useAcceptBillingInfoAndCalculateShippingPriceMutation>;
export type AcceptBillingInfoAndCalculateShippingPriceMutationResult = Apollo.MutationResult<AcceptBillingInfoAndCalculateShippingPriceMutation>;
export type AcceptBillingInfoAndCalculateShippingPriceMutationOptions = Apollo.BaseMutationOptions<AcceptBillingInfoAndCalculateShippingPriceMutation, AcceptBillingInfoAndCalculateShippingPriceMutationVariables>;
export const ReviewOrderDocument = gql`
    mutation ReviewOrder($input: ReviewOrderInput!) {
  reviewOrder(input: $input) {
    id
  }
}
    `;
export type ReviewOrderMutationFn = Apollo.MutationFunction<ReviewOrderMutation, ReviewOrderMutationVariables>;

/**
 * __useReviewOrderMutation__
 *
 * To run a mutation, you first call `useReviewOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewOrderMutation, { data, loading, error }] = useReviewOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewOrderMutation(baseOptions?: Apollo.MutationHookOptions<ReviewOrderMutation, ReviewOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReviewOrderMutation, ReviewOrderMutationVariables>(ReviewOrderDocument, options);
      }
export type ReviewOrderMutationHookResult = ReturnType<typeof useReviewOrderMutation>;
export type ReviewOrderMutationResult = Apollo.MutationResult<ReviewOrderMutation>;
export type ReviewOrderMutationOptions = Apollo.BaseMutationOptions<ReviewOrderMutation, ReviewOrderMutationVariables>;
export const ValidateOrderDocument = gql`
    mutation ValidateOrder($id: ID!) {
  validateOrder(id: $id) {
    ...ValidateOrderResponse
  }
}
    ${ValidateOrderResponseFragmentDoc}`;
export type ValidateOrderMutationFn = Apollo.MutationFunction<ValidateOrderMutation, ValidateOrderMutationVariables>;

/**
 * __useValidateOrderMutation__
 *
 * To run a mutation, you first call `useValidateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateOrderMutation, { data, loading, error }] = useValidateOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useValidateOrderMutation(baseOptions?: Apollo.MutationHookOptions<ValidateOrderMutation, ValidateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateOrderMutation, ValidateOrderMutationVariables>(ValidateOrderDocument, options);
      }
export type ValidateOrderMutationHookResult = ReturnType<typeof useValidateOrderMutation>;
export type ValidateOrderMutationResult = Apollo.MutationResult<ValidateOrderMutation>;
export type ValidateOrderMutationOptions = Apollo.BaseMutationOptions<ValidateOrderMutation, ValidateOrderMutationVariables>;
export const PrepareDispatchForOrdersDocument = gql`
    mutation PrepareDispatchForOrders($orderIds: [ID!]!) {
  prepareDispatchForOrders(orderIds: $orderIds) {
    ...PrepareDispatchResult
  }
}
    ${PrepareDispatchResultFragmentDoc}`;
export type PrepareDispatchForOrdersMutationFn = Apollo.MutationFunction<PrepareDispatchForOrdersMutation, PrepareDispatchForOrdersMutationVariables>;

/**
 * __usePrepareDispatchForOrdersMutation__
 *
 * To run a mutation, you first call `usePrepareDispatchForOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareDispatchForOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareDispatchForOrdersMutation, { data, loading, error }] = usePrepareDispatchForOrdersMutation({
 *   variables: {
 *      orderIds: // value for 'orderIds'
 *   },
 * });
 */
export function usePrepareDispatchForOrdersMutation(baseOptions?: Apollo.MutationHookOptions<PrepareDispatchForOrdersMutation, PrepareDispatchForOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrepareDispatchForOrdersMutation, PrepareDispatchForOrdersMutationVariables>(PrepareDispatchForOrdersDocument, options);
      }
export type PrepareDispatchForOrdersMutationHookResult = ReturnType<typeof usePrepareDispatchForOrdersMutation>;
export type PrepareDispatchForOrdersMutationResult = Apollo.MutationResult<PrepareDispatchForOrdersMutation>;
export type PrepareDispatchForOrdersMutationOptions = Apollo.BaseMutationOptions<PrepareDispatchForOrdersMutation, PrepareDispatchForOrdersMutationVariables>;
export const ResetTopTransForOrdersDocument = gql`
    mutation ResetTopTransForOrders($orderIds: [ID!]!) {
  resetTopTransForOrders(orderIds: $orderIds)
}
    `;
export type ResetTopTransForOrdersMutationFn = Apollo.MutationFunction<ResetTopTransForOrdersMutation, ResetTopTransForOrdersMutationVariables>;

/**
 * __useResetTopTransForOrdersMutation__
 *
 * To run a mutation, you first call `useResetTopTransForOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTopTransForOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTopTransForOrdersMutation, { data, loading, error }] = useResetTopTransForOrdersMutation({
 *   variables: {
 *      orderIds: // value for 'orderIds'
 *   },
 * });
 */
export function useResetTopTransForOrdersMutation(baseOptions?: Apollo.MutationHookOptions<ResetTopTransForOrdersMutation, ResetTopTransForOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetTopTransForOrdersMutation, ResetTopTransForOrdersMutationVariables>(ResetTopTransForOrdersDocument, options);
      }
export type ResetTopTransForOrdersMutationHookResult = ReturnType<typeof useResetTopTransForOrdersMutation>;
export type ResetTopTransForOrdersMutationResult = Apollo.MutationResult<ResetTopTransForOrdersMutation>;
export type ResetTopTransForOrdersMutationOptions = Apollo.BaseMutationOptions<ResetTopTransForOrdersMutation, ResetTopTransForOrdersMutationVariables>;
export const SendOrdersInTopTransDocument = gql`
    mutation SendOrdersInTopTrans($orderIds: [ID!]!) {
  sendOrdersInTopTrans(orderIds: $orderIds)
}
    `;
export type SendOrdersInTopTransMutationFn = Apollo.MutationFunction<SendOrdersInTopTransMutation, SendOrdersInTopTransMutationVariables>;

/**
 * __useSendOrdersInTopTransMutation__
 *
 * To run a mutation, you first call `useSendOrdersInTopTransMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOrdersInTopTransMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOrdersInTopTransMutation, { data, loading, error }] = useSendOrdersInTopTransMutation({
 *   variables: {
 *      orderIds: // value for 'orderIds'
 *   },
 * });
 */
export function useSendOrdersInTopTransMutation(baseOptions?: Apollo.MutationHookOptions<SendOrdersInTopTransMutation, SendOrdersInTopTransMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendOrdersInTopTransMutation, SendOrdersInTopTransMutationVariables>(SendOrdersInTopTransDocument, options);
      }
export type SendOrdersInTopTransMutationHookResult = ReturnType<typeof useSendOrdersInTopTransMutation>;
export type SendOrdersInTopTransMutationResult = Apollo.MutationResult<SendOrdersInTopTransMutation>;
export type SendOrdersInTopTransMutationOptions = Apollo.BaseMutationOptions<SendOrdersInTopTransMutation, SendOrdersInTopTransMutationVariables>;
export const CheckPartDocument = gql`
    mutation CheckPart($partInput: PartInput!) {
  checkPart(partInput: $partInput) {
    ...Part
  }
}
    ${PartFragmentDoc}`;
export type CheckPartMutationFn = Apollo.MutationFunction<CheckPartMutation, CheckPartMutationVariables>;

/**
 * __useCheckPartMutation__
 *
 * To run a mutation, you first call `useCheckPartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckPartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkPartMutation, { data, loading, error }] = useCheckPartMutation({
 *   variables: {
 *      partInput: // value for 'partInput'
 *   },
 * });
 */
export function useCheckPartMutation(baseOptions?: Apollo.MutationHookOptions<CheckPartMutation, CheckPartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckPartMutation, CheckPartMutationVariables>(CheckPartDocument, options);
      }
export type CheckPartMutationHookResult = ReturnType<typeof useCheckPartMutation>;
export type CheckPartMutationResult = Apollo.MutationResult<CheckPartMutation>;
export type CheckPartMutationOptions = Apollo.BaseMutationOptions<CheckPartMutation, CheckPartMutationVariables>;
export const RemovePartDocument = gql`
    mutation RemovePart($id: ID!) {
  removePart(id: $id)
}
    `;
export type RemovePartMutationFn = Apollo.MutationFunction<RemovePartMutation, RemovePartMutationVariables>;

/**
 * __useRemovePartMutation__
 *
 * To run a mutation, you first call `useRemovePartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePartMutation, { data, loading, error }] = useRemovePartMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePartMutation(baseOptions?: Apollo.MutationHookOptions<RemovePartMutation, RemovePartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePartMutation, RemovePartMutationVariables>(RemovePartDocument, options);
      }
export type RemovePartMutationHookResult = ReturnType<typeof useRemovePartMutation>;
export type RemovePartMutationResult = Apollo.MutationResult<RemovePartMutation>;
export type RemovePartMutationOptions = Apollo.BaseMutationOptions<RemovePartMutation, RemovePartMutationVariables>;
export const UpdatePartAmountDocument = gql`
    mutation UpdatePartAmount($updatePartAmountInput: UpdatePartAmountInput!) {
  updatePartAmount(updatePartAmountInput: $updatePartAmountInput) {
    ...Part
  }
}
    ${PartFragmentDoc}`;
export type UpdatePartAmountMutationFn = Apollo.MutationFunction<UpdatePartAmountMutation, UpdatePartAmountMutationVariables>;

/**
 * __useUpdatePartAmountMutation__
 *
 * To run a mutation, you first call `useUpdatePartAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartAmountMutation, { data, loading, error }] = useUpdatePartAmountMutation({
 *   variables: {
 *      updatePartAmountInput: // value for 'updatePartAmountInput'
 *   },
 * });
 */
export function useUpdatePartAmountMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartAmountMutation, UpdatePartAmountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartAmountMutation, UpdatePartAmountMutationVariables>(UpdatePartAmountDocument, options);
      }
export type UpdatePartAmountMutationHookResult = ReturnType<typeof useUpdatePartAmountMutation>;
export type UpdatePartAmountMutationResult = Apollo.MutationResult<UpdatePartAmountMutation>;
export type UpdatePartAmountMutationOptions = Apollo.BaseMutationOptions<UpdatePartAmountMutation, UpdatePartAmountMutationVariables>;
export const RefreshPartDocument = gql`
    mutation RefreshPart($id: ID!) {
  refreshPart(id: $id) {
    ...Part
  }
}
    ${PartFragmentDoc}`;
export type RefreshPartMutationFn = Apollo.MutationFunction<RefreshPartMutation, RefreshPartMutationVariables>;

/**
 * __useRefreshPartMutation__
 *
 * To run a mutation, you first call `useRefreshPartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshPartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshPartMutation, { data, loading, error }] = useRefreshPartMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefreshPartMutation(baseOptions?: Apollo.MutationHookOptions<RefreshPartMutation, RefreshPartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshPartMutation, RefreshPartMutationVariables>(RefreshPartDocument, options);
      }
export type RefreshPartMutationHookResult = ReturnType<typeof useRefreshPartMutation>;
export type RefreshPartMutationResult = Apollo.MutationResult<RefreshPartMutation>;
export type RefreshPartMutationOptions = Apollo.BaseMutationOptions<RefreshPartMutation, RefreshPartMutationVariables>;
export const CheckManyPartsAvailabilityDocument = gql`
    mutation CheckManyPartsAvailability($checkManyPartsAvailabilityInput: CheckManyPartsAvailabilityInput!) {
  checkManyPartsAvailability(
    checkManyPartsAvailabilityInput: $checkManyPartsAvailabilityInput
  ) {
    ...Part
  }
}
    ${PartFragmentDoc}`;
export type CheckManyPartsAvailabilityMutationFn = Apollo.MutationFunction<CheckManyPartsAvailabilityMutation, CheckManyPartsAvailabilityMutationVariables>;

/**
 * __useCheckManyPartsAvailabilityMutation__
 *
 * To run a mutation, you first call `useCheckManyPartsAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckManyPartsAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkManyPartsAvailabilityMutation, { data, loading, error }] = useCheckManyPartsAvailabilityMutation({
 *   variables: {
 *      checkManyPartsAvailabilityInput: // value for 'checkManyPartsAvailabilityInput'
 *   },
 * });
 */
export function useCheckManyPartsAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<CheckManyPartsAvailabilityMutation, CheckManyPartsAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckManyPartsAvailabilityMutation, CheckManyPartsAvailabilityMutationVariables>(CheckManyPartsAvailabilityDocument, options);
      }
export type CheckManyPartsAvailabilityMutationHookResult = ReturnType<typeof useCheckManyPartsAvailabilityMutation>;
export type CheckManyPartsAvailabilityMutationResult = Apollo.MutationResult<CheckManyPartsAvailabilityMutation>;
export type CheckManyPartsAvailabilityMutationOptions = Apollo.BaseMutationOptions<CheckManyPartsAvailabilityMutation, CheckManyPartsAvailabilityMutationVariables>;
export const CreateSpaceDocument = gql`
    mutation CreateSpace($spaceInput: SpaceInput!) {
  createSpace(spaceInput: $spaceInput) {
    ...Space
  }
}
    ${SpaceFragmentDoc}`;
export type CreateSpaceMutationFn = Apollo.MutationFunction<CreateSpaceMutation, CreateSpaceMutationVariables>;

/**
 * __useCreateSpaceMutation__
 *
 * To run a mutation, you first call `useCreateSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpaceMutation, { data, loading, error }] = useCreateSpaceMutation({
 *   variables: {
 *      spaceInput: // value for 'spaceInput'
 *   },
 * });
 */
export function useCreateSpaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateSpaceMutation, CreateSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSpaceMutation, CreateSpaceMutationVariables>(CreateSpaceDocument, options);
      }
export type CreateSpaceMutationHookResult = ReturnType<typeof useCreateSpaceMutation>;
export type CreateSpaceMutationResult = Apollo.MutationResult<CreateSpaceMutation>;
export type CreateSpaceMutationOptions = Apollo.BaseMutationOptions<CreateSpaceMutation, CreateSpaceMutationVariables>;
export const EditSpaceDocument = gql`
    mutation EditSpace($id: ID!, $spaceInput: SpaceInput!) {
  editSpace(id: $id, spaceInput: $spaceInput) {
    ...Space
  }
}
    ${SpaceFragmentDoc}`;
export type EditSpaceMutationFn = Apollo.MutationFunction<EditSpaceMutation, EditSpaceMutationVariables>;

/**
 * __useEditSpaceMutation__
 *
 * To run a mutation, you first call `useEditSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSpaceMutation, { data, loading, error }] = useEditSpaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      spaceInput: // value for 'spaceInput'
 *   },
 * });
 */
export function useEditSpaceMutation(baseOptions?: Apollo.MutationHookOptions<EditSpaceMutation, EditSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditSpaceMutation, EditSpaceMutationVariables>(EditSpaceDocument, options);
      }
export type EditSpaceMutationHookResult = ReturnType<typeof useEditSpaceMutation>;
export type EditSpaceMutationResult = Apollo.MutationResult<EditSpaceMutation>;
export type EditSpaceMutationOptions = Apollo.BaseMutationOptions<EditSpaceMutation, EditSpaceMutationVariables>;
export const RemoveSpaceDocument = gql`
    mutation RemoveSpace($id: ID!) {
  removeSpace(id: $id)
}
    `;
export type RemoveSpaceMutationFn = Apollo.MutationFunction<RemoveSpaceMutation, RemoveSpaceMutationVariables>;

/**
 * __useRemoveSpaceMutation__
 *
 * To run a mutation, you first call `useRemoveSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSpaceMutation, { data, loading, error }] = useRemoveSpaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSpaceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSpaceMutation, RemoveSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSpaceMutation, RemoveSpaceMutationVariables>(RemoveSpaceDocument, options);
      }
export type RemoveSpaceMutationHookResult = ReturnType<typeof useRemoveSpaceMutation>;
export type RemoveSpaceMutationResult = Apollo.MutationResult<RemoveSpaceMutation>;
export type RemoveSpaceMutationOptions = Apollo.BaseMutationOptions<RemoveSpaceMutation, RemoveSpaceMutationVariables>;
export const SetSpaceActiveDocument = gql`
    mutation SetSpaceActive($id: ID!, $active: Boolean!) {
  setSpaceActive(id: $id, active: $active) {
    ...Space
  }
}
    ${SpaceFragmentDoc}`;
export type SetSpaceActiveMutationFn = Apollo.MutationFunction<SetSpaceActiveMutation, SetSpaceActiveMutationVariables>;

/**
 * __useSetSpaceActiveMutation__
 *
 * To run a mutation, you first call `useSetSpaceActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSpaceActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSpaceActiveMutation, { data, loading, error }] = useSetSpaceActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useSetSpaceActiveMutation(baseOptions?: Apollo.MutationHookOptions<SetSpaceActiveMutation, SetSpaceActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSpaceActiveMutation, SetSpaceActiveMutationVariables>(SetSpaceActiveDocument, options);
      }
export type SetSpaceActiveMutationHookResult = ReturnType<typeof useSetSpaceActiveMutation>;
export type SetSpaceActiveMutationResult = Apollo.MutationResult<SetSpaceActiveMutation>;
export type SetSpaceActiveMutationOptions = Apollo.BaseMutationOptions<SetSpaceActiveMutation, SetSpaceActiveMutationVariables>;
export const ExportSpacesDocument = gql`
    mutation ExportSpaces($spacesInput: SpacesInput!, $paginationInput: PaginationInput) {
  exportSpaces(spacesInput: $spacesInput, paginationInput: $paginationInput)
}
    `;
export type ExportSpacesMutationFn = Apollo.MutationFunction<ExportSpacesMutation, ExportSpacesMutationVariables>;

/**
 * __useExportSpacesMutation__
 *
 * To run a mutation, you first call `useExportSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportSpacesMutation, { data, loading, error }] = useExportSpacesMutation({
 *   variables: {
 *      spacesInput: // value for 'spacesInput'
 *      paginationInput: // value for 'paginationInput'
 *   },
 * });
 */
export function useExportSpacesMutation(baseOptions?: Apollo.MutationHookOptions<ExportSpacesMutation, ExportSpacesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportSpacesMutation, ExportSpacesMutationVariables>(ExportSpacesDocument, options);
      }
export type ExportSpacesMutationHookResult = ReturnType<typeof useExportSpacesMutation>;
export type ExportSpacesMutationResult = Apollo.MutationResult<ExportSpacesMutation>;
export type ExportSpacesMutationOptions = Apollo.BaseMutationOptions<ExportSpacesMutation, ExportSpacesMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($userInput: UserInput!) {
  createUser(userInput: $userInput) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($userRegisterInput: UserRegisterInput!) {
  registerUser(userRegisterInput: $userRegisterInput) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      userRegisterInput: // value for 'userRegisterInput'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const EditUserDocument = gql`
    mutation EditUser($id: ID!, $userInput: UserEditInput!) {
  editUser(id: $id, userInput: $userInput) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const RemoveUserDocument = gql`
    mutation RemoveUser($id: ID!, $force: Boolean) {
  removeUser(id: $id, force: $force)
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, options);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const SetUserActiveDocument = gql`
    mutation SetUserActive($id: ID!, $active: Boolean!) {
  setUserActive(id: $id, active: $active) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type SetUserActiveMutationFn = Apollo.MutationFunction<SetUserActiveMutation, SetUserActiveMutationVariables>;

/**
 * __useSetUserActiveMutation__
 *
 * To run a mutation, you first call `useSetUserActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserActiveMutation, { data, loading, error }] = useSetUserActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useSetUserActiveMutation(baseOptions?: Apollo.MutationHookOptions<SetUserActiveMutation, SetUserActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserActiveMutation, SetUserActiveMutationVariables>(SetUserActiveDocument, options);
      }
export type SetUserActiveMutationHookResult = ReturnType<typeof useSetUserActiveMutation>;
export type SetUserActiveMutationResult = Apollo.MutationResult<SetUserActiveMutation>;
export type SetUserActiveMutationOptions = Apollo.BaseMutationOptions<SetUserActiveMutation, SetUserActiveMutationVariables>;
export const ExportUsersDocument = gql`
    mutation ExportUsers($usersInput: UsersInput!, $paginationInput: PaginationInput) {
  exportUsers(usersInput: $usersInput, paginationInput: $paginationInput)
}
    `;
export type ExportUsersMutationFn = Apollo.MutationFunction<ExportUsersMutation, ExportUsersMutationVariables>;

/**
 * __useExportUsersMutation__
 *
 * To run a mutation, you first call `useExportUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUsersMutation, { data, loading, error }] = useExportUsersMutation({
 *   variables: {
 *      usersInput: // value for 'usersInput'
 *      paginationInput: // value for 'paginationInput'
 *   },
 * });
 */
export function useExportUsersMutation(baseOptions?: Apollo.MutationHookOptions<ExportUsersMutation, ExportUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportUsersMutation, ExportUsersMutationVariables>(ExportUsersDocument, options);
      }
export type ExportUsersMutationHookResult = ReturnType<typeof useExportUsersMutation>;
export type ExportUsersMutationResult = Apollo.MutationResult<ExportUsersMutation>;
export type ExportUsersMutationOptions = Apollo.BaseMutationOptions<ExportUsersMutation, ExportUsersMutationVariables>;
export const ImportUsersDocument = gql`
    mutation ImportUsers($csv: Upload!) {
  importUsers(csv: $csv) {
    succeeded {
      ...ImportedUser
    }
    errored {
      ...ImportedUser
    }
  }
}
    ${ImportedUserFragmentDoc}`;
export type ImportUsersMutationFn = Apollo.MutationFunction<ImportUsersMutation, ImportUsersMutationVariables>;

/**
 * __useImportUsersMutation__
 *
 * To run a mutation, you first call `useImportUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUsersMutation, { data, loading, error }] = useImportUsersMutation({
 *   variables: {
 *      csv: // value for 'csv'
 *   },
 * });
 */
export function useImportUsersMutation(baseOptions?: Apollo.MutationHookOptions<ImportUsersMutation, ImportUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportUsersMutation, ImportUsersMutationVariables>(ImportUsersDocument, options);
      }
export type ImportUsersMutationHookResult = ReturnType<typeof useImportUsersMutation>;
export type ImportUsersMutationResult = Apollo.MutationResult<ImportUsersMutation>;
export type ImportUsersMutationOptions = Apollo.BaseMutationOptions<ImportUsersMutation, ImportUsersMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...AuthUser
  }
}
    ${AuthUserFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const ValidateResetCodeDocument = gql`
    query ValidateResetCode($code: String!) {
  validateResetCode(code: $code)
}
    `;

/**
 * __useValidateResetCodeQuery__
 *
 * To run a query within a React component, call `useValidateResetCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateResetCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateResetCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidateResetCodeQuery(baseOptions: Apollo.QueryHookOptions<ValidateResetCodeQuery, ValidateResetCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateResetCodeQuery, ValidateResetCodeQueryVariables>(ValidateResetCodeDocument, options);
      }
export function useValidateResetCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateResetCodeQuery, ValidateResetCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateResetCodeQuery, ValidateResetCodeQueryVariables>(ValidateResetCodeDocument, options);
        }
export type ValidateResetCodeQueryHookResult = ReturnType<typeof useValidateResetCodeQuery>;
export type ValidateResetCodeLazyQueryHookResult = ReturnType<typeof useValidateResetCodeLazyQuery>;
export type ValidateResetCodeQueryResult = Apollo.QueryResult<ValidateResetCodeQuery, ValidateResetCodeQueryVariables>;
export const GetBusinessOwnerBillingInfoDocument = gql`
    query GetBusinessOwnerBillingInfo {
  getBusinessOwnerBillingInfo {
    ...BillingInfo
  }
}
    ${BillingInfoFragmentDoc}`;

/**
 * __useGetBusinessOwnerBillingInfoQuery__
 *
 * To run a query within a React component, call `useGetBusinessOwnerBillingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessOwnerBillingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessOwnerBillingInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessOwnerBillingInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessOwnerBillingInfoQuery, GetBusinessOwnerBillingInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessOwnerBillingInfoQuery, GetBusinessOwnerBillingInfoQueryVariables>(GetBusinessOwnerBillingInfoDocument, options);
      }
export function useGetBusinessOwnerBillingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessOwnerBillingInfoQuery, GetBusinessOwnerBillingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessOwnerBillingInfoQuery, GetBusinessOwnerBillingInfoQueryVariables>(GetBusinessOwnerBillingInfoDocument, options);
        }
export type GetBusinessOwnerBillingInfoQueryHookResult = ReturnType<typeof useGetBusinessOwnerBillingInfoQuery>;
export type GetBusinessOwnerBillingInfoLazyQueryHookResult = ReturnType<typeof useGetBusinessOwnerBillingInfoLazyQuery>;
export type GetBusinessOwnerBillingInfoQueryResult = Apollo.QueryResult<GetBusinessOwnerBillingInfoQuery, GetBusinessOwnerBillingInfoQueryVariables>;
export const GetBillingInfoDocument = gql`
    query GetBillingInfo($orderId: ID, $spaceId: ID) {
  getBillingInfo(orderId: $orderId, spaceId: $spaceId) {
    ...BillingInfo
  }
}
    ${BillingInfoFragmentDoc}`;

/**
 * __useGetBillingInfoQuery__
 *
 * To run a query within a React component, call `useGetBillingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingInfoQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetBillingInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetBillingInfoQuery, GetBillingInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillingInfoQuery, GetBillingInfoQueryVariables>(GetBillingInfoDocument, options);
      }
export function useGetBillingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillingInfoQuery, GetBillingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillingInfoQuery, GetBillingInfoQueryVariables>(GetBillingInfoDocument, options);
        }
export type GetBillingInfoQueryHookResult = ReturnType<typeof useGetBillingInfoQuery>;
export type GetBillingInfoLazyQueryHookResult = ReturnType<typeof useGetBillingInfoLazyQuery>;
export type GetBillingInfoQueryResult = Apollo.QueryResult<GetBillingInfoQuery, GetBillingInfoQueryVariables>;
export const GetCurrentOrderDocument = gql`
    query GetCurrentOrder($userId: ID!) {
  getCurrentOrder(userId: $userId) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;

/**
 * __useGetCurrentOrderQuery__
 *
 * To run a query within a React component, call `useGetCurrentOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentOrderQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCurrentOrderQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentOrderQuery, GetCurrentOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentOrderQuery, GetCurrentOrderQueryVariables>(GetCurrentOrderDocument, options);
      }
export function useGetCurrentOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentOrderQuery, GetCurrentOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentOrderQuery, GetCurrentOrderQueryVariables>(GetCurrentOrderDocument, options);
        }
export type GetCurrentOrderQueryHookResult = ReturnType<typeof useGetCurrentOrderQuery>;
export type GetCurrentOrderLazyQueryHookResult = ReturnType<typeof useGetCurrentOrderLazyQuery>;
export type GetCurrentOrderQueryResult = Apollo.QueryResult<GetCurrentOrderQuery, GetCurrentOrderQueryVariables>;
export const GetOrdersForIntervalDocument = gql`
    query GetOrdersForInterval($from: DateTime!, $to: DateTime!) {
  getOrdersForInterval(from: $from, to: $to) {
    ...AdminTableOrder
  }
}
    ${AdminTableOrderFragmentDoc}`;

/**
 * __useGetOrdersForIntervalQuery__
 *
 * To run a query within a React component, call `useGetOrdersForIntervalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersForIntervalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersForIntervalQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetOrdersForIntervalQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersForIntervalQuery, GetOrdersForIntervalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersForIntervalQuery, GetOrdersForIntervalQueryVariables>(GetOrdersForIntervalDocument, options);
      }
export function useGetOrdersForIntervalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersForIntervalQuery, GetOrdersForIntervalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersForIntervalQuery, GetOrdersForIntervalQueryVariables>(GetOrdersForIntervalDocument, options);
        }
export type GetOrdersForIntervalQueryHookResult = ReturnType<typeof useGetOrdersForIntervalQuery>;
export type GetOrdersForIntervalLazyQueryHookResult = ReturnType<typeof useGetOrdersForIntervalLazyQuery>;
export type GetOrdersForIntervalQueryResult = Apollo.QueryResult<GetOrdersForIntervalQuery, GetOrdersForIntervalQueryVariables>;
export const GetOrderWithIdDocument = gql`
    query GetOrderWithId($id: ID!) {
  getOrderWithId(id: $id) {
    ...PartsOnlyOrder
  }
}
    ${PartsOnlyOrderFragmentDoc}`;

/**
 * __useGetOrderWithIdQuery__
 *
 * To run a query within a React component, call `useGetOrderWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderWithIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderWithIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrderWithIdQuery, GetOrderWithIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderWithIdQuery, GetOrderWithIdQueryVariables>(GetOrderWithIdDocument, options);
      }
export function useGetOrderWithIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderWithIdQuery, GetOrderWithIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderWithIdQuery, GetOrderWithIdQueryVariables>(GetOrderWithIdDocument, options);
        }
export type GetOrderWithIdQueryHookResult = ReturnType<typeof useGetOrderWithIdQuery>;
export type GetOrderWithIdLazyQueryHookResult = ReturnType<typeof useGetOrderWithIdLazyQuery>;
export type GetOrderWithIdQueryResult = Apollo.QueryResult<GetOrderWithIdQuery, GetOrderWithIdQueryVariables>;
export const GetOrdersDocument = gql`
    query GetOrders($filterInput: OrderFilterInput!, $paginationInput: PaginationInput!) {
  getOrders(filterInput: $filterInput, paginationInput: $paginationInput) {
    ...AdminTableOrder
  }
}
    ${AdminTableOrderFragmentDoc}`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      paginationInput: // value for 'paginationInput'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetOrdersCustomerDocument = gql`
    query getOrdersCustomer($filterInput: OrderFilterInput!, $paginationInput: PaginationInput!) {
  getOrders(filterInput: $filterInput, paginationInput: $paginationInput) {
    ...CustomerTableOrder
  }
}
    ${CustomerTableOrderFragmentDoc}`;

/**
 * __useGetOrdersCustomerQuery__
 *
 * To run a query within a React component, call `useGetOrdersCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersCustomerQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      paginationInput: // value for 'paginationInput'
 *   },
 * });
 */
export function useGetOrdersCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersCustomerQuery, GetOrdersCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersCustomerQuery, GetOrdersCustomerQueryVariables>(GetOrdersCustomerDocument, options);
      }
export function useGetOrdersCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersCustomerQuery, GetOrdersCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersCustomerQuery, GetOrdersCustomerQueryVariables>(GetOrdersCustomerDocument, options);
        }
export type GetOrdersCustomerQueryHookResult = ReturnType<typeof useGetOrdersCustomerQuery>;
export type GetOrdersCustomerLazyQueryHookResult = ReturnType<typeof useGetOrdersCustomerLazyQuery>;
export type GetOrdersCustomerQueryResult = Apollo.QueryResult<GetOrdersCustomerQuery, GetOrdersCustomerQueryVariables>;
export const SpacesDocument = gql`
    query Spaces($spacesInput: SpacesInput!, $paginationInput: PaginationInput) {
  spaces(spacesInput: $spacesInput, paginationInput: $paginationInput) {
    list {
      ...SpaceTable
    }
    pagination {
      limit
      offset
      count
    }
  }
}
    ${SpaceTableFragmentDoc}`;

/**
 * __useSpacesQuery__
 *
 * To run a query within a React component, call `useSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpacesQuery({
 *   variables: {
 *      spacesInput: // value for 'spacesInput'
 *      paginationInput: // value for 'paginationInput'
 *   },
 * });
 */
export function useSpacesQuery(baseOptions: Apollo.QueryHookOptions<SpacesQuery, SpacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpacesQuery, SpacesQueryVariables>(SpacesDocument, options);
      }
export function useSpacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpacesQuery, SpacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpacesQuery, SpacesQueryVariables>(SpacesDocument, options);
        }
export type SpacesQueryHookResult = ReturnType<typeof useSpacesQuery>;
export type SpacesLazyQueryHookResult = ReturnType<typeof useSpacesLazyQuery>;
export type SpacesQueryResult = Apollo.QueryResult<SpacesQuery, SpacesQueryVariables>;
export const SpaceDocument = gql`
    query Space($id: ID!) {
  space(id: $id) {
    ...Space
  }
}
    ${SpaceFragmentDoc}`;

/**
 * __useSpaceQuery__
 *
 * To run a query within a React component, call `useSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaceQuery(baseOptions: Apollo.QueryHookOptions<SpaceQuery, SpaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpaceQuery, SpaceQueryVariables>(SpaceDocument, options);
      }
export function useSpaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpaceQuery, SpaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpaceQuery, SpaceQueryVariables>(SpaceDocument, options);
        }
export type SpaceQueryHookResult = ReturnType<typeof useSpaceQuery>;
export type SpaceLazyQueryHookResult = ReturnType<typeof useSpaceLazyQuery>;
export type SpaceQueryResult = Apollo.QueryResult<SpaceQuery, SpaceQueryVariables>;
export const SpaceOptionsDocument = gql`
    query SpaceOptions($spacesInput: SpacesInput!, $paginationInput: PaginationInput) {
  spaces(spacesInput: $spacesInput, paginationInput: $paginationInput) {
    list {
      ...SpaceOption
    }
  }
}
    ${SpaceOptionFragmentDoc}`;

/**
 * __useSpaceOptionsQuery__
 *
 * To run a query within a React component, call `useSpaceOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceOptionsQuery({
 *   variables: {
 *      spacesInput: // value for 'spacesInput'
 *      paginationInput: // value for 'paginationInput'
 *   },
 * });
 */
export function useSpaceOptionsQuery(baseOptions: Apollo.QueryHookOptions<SpaceOptionsQuery, SpaceOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpaceOptionsQuery, SpaceOptionsQueryVariables>(SpaceOptionsDocument, options);
      }
export function useSpaceOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpaceOptionsQuery, SpaceOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpaceOptionsQuery, SpaceOptionsQueryVariables>(SpaceOptionsDocument, options);
        }
export type SpaceOptionsQueryHookResult = ReturnType<typeof useSpaceOptionsQuery>;
export type SpaceOptionsLazyQueryHookResult = ReturnType<typeof useSpaceOptionsLazyQuery>;
export type SpaceOptionsQueryResult = Apollo.QueryResult<SpaceOptionsQuery, SpaceOptionsQueryVariables>;
export const GetPartBrandOptionsDocument = gql`
    query getPartBrandOptions {
  getPartBrandOptions
}
    `;

/**
 * __useGetPartBrandOptionsQuery__
 *
 * To run a query within a React component, call `useGetPartBrandOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartBrandOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartBrandOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPartBrandOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPartBrandOptionsQuery, GetPartBrandOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartBrandOptionsQuery, GetPartBrandOptionsQueryVariables>(GetPartBrandOptionsDocument, options);
      }
export function useGetPartBrandOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartBrandOptionsQuery, GetPartBrandOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartBrandOptionsQuery, GetPartBrandOptionsQueryVariables>(GetPartBrandOptionsDocument, options);
        }
export type GetPartBrandOptionsQueryHookResult = ReturnType<typeof useGetPartBrandOptionsQuery>;
export type GetPartBrandOptionsLazyQueryHookResult = ReturnType<typeof useGetPartBrandOptionsLazyQuery>;
export type GetPartBrandOptionsQueryResult = Apollo.QueryResult<GetPartBrandOptionsQuery, GetPartBrandOptionsQueryVariables>;
export const UserDocument = gql`
    query User($id: ID!) {
  user(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query Users($usersInput: UsersInput!, $paginationInput: PaginationInput) {
  users(usersInput: $usersInput, paginationInput: $paginationInput) {
    list {
      ...User
    }
    pagination {
      limit
      offset
      count
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      usersInput: // value for 'usersInput'
 *      paginationInput: // value for 'paginationInput'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserOptionsDocument = gql`
    query UserOptions($usersInput: UsersInput!, $paginationInput: PaginationInput) {
  users(usersInput: $usersInput, paginationInput: $paginationInput) {
    list {
      ...UserOption
    }
    pagination {
      limit
      offset
      count
    }
  }
}
    ${UserOptionFragmentDoc}`;

/**
 * __useUserOptionsQuery__
 *
 * To run a query within a React component, call `useUserOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOptionsQuery({
 *   variables: {
 *      usersInput: // value for 'usersInput'
 *      paginationInput: // value for 'paginationInput'
 *   },
 * });
 */
export function useUserOptionsQuery(baseOptions: Apollo.QueryHookOptions<UserOptionsQuery, UserOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserOptionsQuery, UserOptionsQueryVariables>(UserOptionsDocument, options);
      }
export function useUserOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserOptionsQuery, UserOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserOptionsQuery, UserOptionsQueryVariables>(UserOptionsDocument, options);
        }
export type UserOptionsQueryHookResult = ReturnType<typeof useUserOptionsQuery>;
export type UserOptionsLazyQueryHookResult = ReturnType<typeof useUserOptionsLazyQuery>;
export type UserOptionsQueryResult = Apollo.QueryResult<UserOptionsQuery, UserOptionsQueryVariables>;