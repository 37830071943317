import { TableCell, TableRow } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  shippingPrice: number;
}

const ShippingPriceRow = ({
  shippingPrice,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <TableRow
    >
      <TableCell style={{ fontWeight: 'bold' }}>
        {t("billing:table.shippingPrice")}
      </TableCell>
      <TableCell />
      <TableCell style={{ fontWeight: 'bold' }}>{`${shippingPrice} ${t("common:currency")}`}</TableCell>
    </TableRow>
  );
};

export default ShippingPriceRow;
