import { ReactNode, } from 'react';
import {
  makeStyles,
  createStyles,
} from '@material-ui/core/styles';


export interface PartInfoProps {
  minHeight?: string,
  children?: ReactNode,
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme) => createStyles<any, PartInfoProps>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: (props) => props.minHeight || '4rem',
  },
  children: {
    marginTop: theme.spacing(1),
  },
}));


const PartInfo = (props: PartInfoProps): JSX.Element => {
  const {
    children,
  } = props;
  const classes = useStyles(props);


  return (
    <div className={classes.root}>
      {children && (
        <div className={classes.children}>
          {children}
        </div>
      )}
    </div>
  );
};


export default PartInfo;
