import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSnackbar, } from 'notistack';
import { useApolloClient, } from '@apollo/client';
import { Formik, FormikHelpers, } from 'formik';

import { EditCurrentUserDocument, EditCurrentUserMutation, EditCurrentUserMutationVariables, } from 'Apollo/graphql';
import { AuthStore, useAuthStore, } from 'Tools/auth';
import { initChangeEmailFormValues, changeEmailFormValidation, } from './constants';
import { ChangeEmailFormValues, } from './types';
import View from './View';


const selectorSetUser = (store: AuthStore) => store.setUser;


const ChangeEmail = (): JSX.Element => {
  const apolloClient = useApolloClient();
  const { t, } = useTranslation();
  const { enqueueSnackbar, } = useSnackbar();
  const setUser = useAuthStore(selectorSetUser);


  const handleSubmit = useCallback(
    async (
      values: ChangeEmailFormValues,
      { setStatus, resetForm, }: FormikHelpers<ChangeEmailFormValues>,
    ) => {
      try {
        setStatus(undefined);
        const ret = await apolloClient.mutate<EditCurrentUserMutation, EditCurrentUserMutationVariables>({
          mutation: EditCurrentUserDocument,
          variables: {
            userInput: {
              email: values.newEmail,
            },
            password: values.password,
          },
        });

        if (!ret.data?.editCurrentUser) throw new Error('no user');
        setUser(ret.data.editCurrentUser);
        enqueueSnackbar(t('currentUser:settings.changeEmail.notifEmailChanged'), { variant: 'success', });
        resetForm();
      } catch (error) {
        setStatus({ error, });
      }
    },
    [ apolloClient, enqueueSnackbar, t, setUser, ],
  );


  return (
    <Formik
      initialValues={initChangeEmailFormValues}
      onSubmit={handleSubmit}
      validationSchema={changeEmailFormValidation}
    >
      <View />
    </Formik>
  );
};


export default ChangeEmail;
