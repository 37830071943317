import { useTranslation, } from 'react-i18next';
import { makeStyles, Theme, } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { useUser, } from 'Tools/auth';
import { UserStore, useUserStore, } from '../../store/useUserStore';
import UsersTable from './UsersTable';


const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'inline-block',
  },
}));


const selectorOpenModalCreate = (store: UserStore) => (store.openModalCreate);
const selectorOpenModalExport = (store: UserStore) => (store.openModalExport);
const selectorOpenModalImport = (store: UserStore) => (store.openModalImport);


const UserListPage = (): JSX.Element => {
  const { t, } = useTranslation();
  const classes = useStyles();
  const user = useUser();

  const openModalCreate = useUserStore(selectorOpenModalCreate);
  const openModalExport = useUserStore(selectorOpenModalExport);
  const openModalImport = useUserStore(selectorOpenModalImport);


  return (
    <Container className="container-page">

      <Box mb={2}>
        <Typography
          variant="h4"
          gutterBottom
        >
          {t('user:usersTable.title')}
        </Typography>
        <Grid
          container
          spacing={1}
        >
          {user?.permissions.USERS_SPACE_MANAGE && (
            <Grid item>
              <Button
                className="button-success"
                onClick={openModalCreate}
              >
                {t('user:usersTable.createUser')}
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              color="primary"
              onClick={openModalExport}
            >
              {t('user:exportModal.export')}
            </Button>
          </Grid>
          {user?.permissions.USERS_SPACE_MANAGE && (
            <Grid item>
              <Button
                color="primary"
                onClick={openModalImport}
              >
                {t('user:usersTable.import')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>


      <Paper className={classes.paper}>
        <UsersTable user={user} />
      </Paper>

    </Container>
  );
};


export default UserListPage;
