import styled from "@emotion/styled";
import { Typography } from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const StyledSpinner = styled(RefreshIcon)(({ theme }) => ({
  animation: `nfLoaderSpin infinite 700ms linear`,
  transformBox: "fill-box",

  "@keyframes nfLoaderSpin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
}));

const LoadingResponse = (): JSX.Element => {
  const { t, } = useTranslation('part')
  return <Box display='flex' flexDirection='row' gap={2}>
    <StyledSpinner />
    <Typography>{t('queryLoading')}</Typography>
  </Box>;
};

export default LoadingResponse;
