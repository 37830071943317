import { useMemo, } from 'react';
import { useTranslation, TFunction, } from 'react-i18next';
import { ApolloError, } from '@apollo/client';

import { InputErrorMessage, } from 'Utils/types';
import { parseApolloError, AcceptedErrorMessages, } from 'Tools/parseApolloError';


export const DEFAULT_ERROR = 'errors:somethingHappened';


export interface UseErrorMessageProps {
  error?: boolean,
  errorMessage?: InputErrorMessage | null,
  apolloError?: ApolloError | null,
  apolloErrorMessages?: AcceptedErrorMessages | null,
  defaultError?: InputErrorMessage | null,
}


export const getErrorMessage = ({
  error,
  errorMessage,
  apolloError,
  apolloErrorMessages,
  defaultError,
}: UseErrorMessageProps): InputErrorMessage | null => {
  const defaultMessage = defaultError || { key: DEFAULT_ERROR, };

  // apolloError
  if (apolloError) {
    const parsedApolloError = parseApolloError(
      apolloError,
      apolloErrorMessages || {},
    );

    return parsedApolloError || defaultMessage;
  }

  // errorMessage
  if (errorMessage) return errorMessage;

  // unknown error
  if (error) return defaultMessage;

  // no error
  return null;
};


export interface TranslateError extends UseErrorMessageProps {
  t: TFunction<'translation'>,
}


export const translateError = ({
  t,
  ...rest
}: TranslateError): string => {
  const ret = getErrorMessage(rest);

  if (!ret) return t(DEFAULT_ERROR);
  return t([ ret.key, DEFAULT_ERROR, ], ret.params);
};


export const useErrorMessage = (props: UseErrorMessageProps): string | null => {
  const { t, } = useTranslation();
  const ret = useMemo(
    () => getErrorMessage(props),
    [ props, ],
  );

  if (!ret) return null;

  return t([ ret.key, DEFAULT_ERROR, ], ret.params);
};
