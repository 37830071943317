import { useTranslation, } from 'react-i18next';
import { RouteComponentProps, } from 'react-router-dom';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { ROUTES, } from 'Utils/globals';
import Link from 'Components/Link';
import InitLayout from '../../components/InitLayout';
import FetchCode from './FetchCode';


const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    padding: theme.spacing(6, 4),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  status: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));


type RouteParams = {
  code: string;
};
type Props = RouteComponentProps<RouteParams>;


const ResetPassword = ({
  match,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t, } = useTranslation();

  const { code, } = match.params;


  return (
    <InitLayout>
      <Paper
        className={classes.paper}
        elevation={6}
      >

        <Typography
          className={classes.title}
          variant="h3"
          color="primary"
          align="center"
        >
          {t('auth:resetPassword.title')}
        </Typography>

        <div className={classes.status}>
          <FetchCode
            code={code}
          />
        </div>

        <Link
          to={`/${ROUTES.LOGIN}`}
          color="textSecondary"
          variant="body1"
        >
          {t('auth:forgottenPassword.loginLink')}
        </Link>

      </Paper>
    </InitLayout>
  );
};


export default ResetPassword;
