/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import { ApolloError } from '@apollo/client';

interface Props {
  apolloError: ApolloError;
}

const ApolloErrorView = ({ apolloError: { networkError, graphQLErrors } }: Props): JSX.Element | null => {
  const errorCount = graphQLErrors.length + (networkError ? 1 : 0);
  // const errorCount = clientErrors.length + graphQLErrors.length + (networkError ? 1 : 0);

  if (errorCount < 0) return null;

  return (
    <>
      <span>Něco se nepovedlo</span>
      <ul>
        {/* {clientErrors.map((error) => (
          <li>{error.message}</li>
        ))} */}
        {graphQLErrors.map((error) => (
          <li>{error.message}</li>
        ))}
        {networkError && (
          <li>
            {networkError.message}
            <ul>
              {(networkError as any)?.result?.errors.map((subError: any) => (
                <li>{subError?.message || 'Něco se nepovedlo'}</li>
              ))}
            </ul>
          </li>
        )}
      </ul>
    </>
  );
};

export default ApolloErrorView;
