import { ReactNode, useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box, { BoxProps } from '@mui/material/Box';

import { AlertStructure } from './types';
import ApolloErrorView from './ApolloErrorView';
import { errorDefaultTitle, errorDefaultMessage } from './constants';

interface Props extends Omit<BoxProps, 'title'> {
  show?: boolean;
  error?: ApolloError | Error | null;
  title?: ReactNode;
  body?: ReactNode;
}

const ErrorAlert = ({ show, error, title, body, ...rest }: Props): JSX.Element | null => {
  const alert = useMemo<AlertStructure>(() => {
    if (!error && !title && !body && !show) return { isVisible: false };
    if (error) {
      if (error instanceof ApolloError) {
        // TODO apollo custom parser here
        return {
          isVisible: true,
          title: title || errorDefaultTitle,
          body: <ApolloErrorView apolloError={error} />,
        };
      }
      return {
        isVisible: true,
        title: title || errorDefaultTitle,
        body: error.message || errorDefaultMessage,
      };
    }
    if (title || body || error) {
      return {
        isVisible: true,
        title,
        body,
      };
    }
    return {
      isVisible: true,
      title: errorDefaultTitle,
      body: errorDefaultMessage,
    };
  }, [show, title, body, error]);

  if (!alert.isVisible) return null;

  return (
    <Box {...rest}>
      <Alert
        sx={{
          wordBreak: 'break-word',
        }}
        severity="error"
      >
        {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
        {alert.body}
      </Alert>
    </Box>
  );
};

export default ErrorAlert;
