import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { FormInputText } from "Components/Form";
import { useTranslation } from "react-i18next";

const BillingDataUserForm = () => {
  const { t } = useTranslation("billing");
  return (
    <Box>
      <Box marginTop={3}>
        <Typography variant="h4">{t("userInfo")}</Typography>
      </Box>
      <Grid container spacing={0} alignItems="center" direction="column">
        <Grid item>
          <FormInputText
            id="name"
            label={t("taxInfo.name")}
            variant="outlined"
            fullWidth
            margin="normal"
            name="name"
          />
        </Grid>
        <Grid item>
          <FormInputText
            id="email"
            label={t("taxInfo.email")}
            variant="outlined"
            fullWidth
            margin="normal"
            name="email"
          />
        </Grid>
        <Grid item>
          <FormInputText
            id="telephone"
            label={t("taxInfo.telephone")}
            variant="outlined"
            fullWidth
            margin="normal"
            name="telephone"
          />
        </Grid>
        <Grid item>
          <FormInputText
            id="city"
            label={t("taxInfo.city")}
            variant="outlined"
            fullWidth
            margin="normal"
            name="city"
          />
        </Grid>
        <Grid item>
          <FormInputText
            id="psc"
            label={t("taxInfo.psc")}
            variant="outlined"
            fullWidth
            margin="normal"
            name="psc"
          />
        </Grid>
        <Grid item>
          <FormInputText
            id="street"
            label={t("taxInfo.street")}
            variant="outlined"
            fullWidth
            margin="normal"
            name="street"
          />
        </Grid>
        <Grid>
          <FormInputText
            id="country"
            label={t("taxInfo.country")}
            variant="outlined"
            defaultValue={"Česká republika"}
            disabled
            fullWidth
            margin="normal"
            name="country"
          />
        </Grid>
        <Grid item>
          <FormInputText
            id="dic"
            label={t("taxInfo.dic")}
            variant="outlined"
            fullWidth
            margin="normal"
            name="dic"
          />
        </Grid>
        <Grid item>
          <FormInputText
            id="ico"
            label={t("taxInfo.ico")}
            variant="outlined"
            fullWidth
            margin="normal"
            name="ico"
            />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillingDataUserForm;
