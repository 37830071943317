import Typography from "@mui/material/Typography";
import OrderProcessBox from "Components/OrderProcessBox";
import { useUser } from "Tools/auth";
import { useTranslation } from "react-i18next";
import Logic from "./Logic";

const Orders = (): JSX.Element => {
  const user = useUser();
  const { t } = useTranslation("order");
  return (
    <>
      {user?.permissions.PARTS_ORDER && (
        <OrderProcessBox>
          <Typography
            marginBottom={2}
            variant="h3"
            sx={theme => ({
              [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(4)
              }
            })}
          >
            {t("orders.title")}
          </Typography>
          <Logic />
        </OrderProcessBox>
      )}
    </>
  );
};

export default Orders;
