import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { FormInputText, FormSubmit } from "Components/Form";
import { useTheme } from "@mui/material/styles";
import { Form } from "formik";
import PartsList from "./PartsList";
import useMediaQuery from "@mui/material/useMediaQuery";

interface Props {
  partSelection: any[];
  setPartSelection: any;
}
const PopulatePartListForm = ({
  partSelection,
  setPartSelection,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Form style={{ width: "95%" }}>
      <Box
        sx={{ display: "flex", flexDirection: "column" }}
        gap={2}
        marginBottom={2}
        marginTop={2}
      >
        <FormInputText
          name="number"
          label={t("part:label.number")}
          fullWidth
          size={isMobile ? "small" : "medium"}
        />
        <FormInputText
          type="number"
          name="amount"
          label={t("part:label.amount")}
          inputProps={{ min: 1 }}
          fullWidth
          size={isMobile ? "small" : "medium"}
        />
      </Box>
      <FormSubmit fullWidth color="secondary">
        {t("part:addToCheck")}
      </FormSubmit>
      <PartsList
        partSelection={partSelection}
        setPartSelection={setPartSelection}
      />
    </Form>
  );
};

export default PopulatePartListForm;
