import {
  PartFragment,
  useRefreshPartMutation,
  useRemovePartMutation,
  useUpdatePartAmountMutation,
} from "Apollo/graphql";
import { OrderStore } from "Modules/Order/store/types";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import PartRow from "./PartRow";

interface Props {
  part: PartFragment;
  removePartFromStore: OrderStore["removePart"];
  changeAmount: OrderStore["changeAmount"];
  updatePart: OrderStore["updatePart"];
}
const RowLogic = ({
  part,
  removePartFromStore,
  changeAmount,
  updatePart,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [removePartMutation, removePartResult] = useRemovePartMutation({
    variables: {
      id: part.id,
    },
    onCompleted: (data) => {
      if (data.removePart) {
        removePartFromStore(data.removePart);
        enqueueSnackbar(t("part:notificationDeleted", { id: part.number }), {
          variant: "warning",
        });
      }
    },
    onError(error) {
      enqueueSnackbar(t("part:notificationError", { id: part.number }), {
        variant: "error",
      });
      console.error(error);
    },
  });

  const [updatePartMutation, updatePartResult] = useUpdatePartAmountMutation({
    onError(error) {
      enqueueSnackbar(t("part:notificationError", { id: part.number }), {
        variant: "error",
      });
      console.error(error);
    },
    onCompleted(data) {
      if (data.updatePartAmount) {
        changeAmount({
          id: data.updatePartAmount.id,
          amount: data.updatePartAmount.amount,
        });
        enqueueSnackbar(t("part:notificationUpdated", { id: part.number }), {
          variant: "success",
        });
      }
    },
  });
  const [refreshPartMutation, refreshPartResult] = useRefreshPartMutation({
    variables: {
      id: part.id,
    },
    onCompleted: (data) => {
      if (data.refreshPart) {
        updatePart(data.refreshPart);
        enqueueSnackbar(t("part:notificationUpdated", { id: part.number }), {
          variant: "success",
        });
      }
    },
    onError(error) {
      enqueueSnackbar(t("part:notificationError", { id: part.number }), {
        variant: "error",
      });
      console.error(error);
    },
  });

  const isExecuting =
    removePartResult.loading ||
    updatePartResult.loading ||
    refreshPartResult.loading;

  return (
    <PartRow
      isExecuting={isExecuting}
      part={part}
      refreshPart={refreshPartMutation}
      removePart={removePartMutation}
      updatePart={updatePartMutation}
    />
  );
};
export default RowLogic;
