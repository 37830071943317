import { useCallback, useState, } from 'react';
import { useApolloClient, } from '@apollo/client';
import { Formik, FormikHelpers, } from 'formik';

import { ResetPasswordDocument, ResetPasswordMutation, ResetPasswordMutationVariables, } from 'Apollo/graphql';
import { initResetPasswordFormValues, resetPasswordFormValidation, } from './constants';
import { ChangePasswordFormValues, } from './types';
import ViewForm from './ViewForm';


interface Props {
  code: string,
}


const LogicForm = ({
  code,
}: Props): JSX.Element => {
  const apolloClient = useApolloClient();

  const [ isSent, setSent, ] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (
      values: ChangePasswordFormValues,
      { setStatus, }: FormikHelpers<ChangePasswordFormValues>,
    ) => {
      try {
        setStatus(undefined);
        await apolloClient.mutate<ResetPasswordMutation, ResetPasswordMutationVariables>({
          mutation: ResetPasswordDocument,
          variables: {
            resetPasswordInput: {
              resetCode: code,
              newPassword: values.password,
            },
          },
        });
        setSent(true);
      } catch (error) {
        setStatus({ error, });
      }
    },
    [ apolloClient, code, ],
  );


  return (
    <Formik
      initialValues={initResetPasswordFormValues}
      onSubmit={handleSubmit}
      validationSchema={resetPasswordFormValidation}
    >
      <ViewForm
        isSent={isSent}
      />
    </Formik>
  );
};


export default LogicForm;
