import React, { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { UseErrorMessageProps, useErrorMessage, } from 'Tools/useErrorMessage';


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: Theme) => createStyles<any, PartErrorProps>({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: (props) => props.minHeight || '4rem',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
  },
  icon: {
    fontSize: 30,
  },
}));


export interface PartErrorProps extends UseErrorMessageProps {
  minHeight?: string,
  title?: ReactNode,
  text?: ReactNode,
}


// TODO error messages
const PartError = ({
  minHeight,
  title,
  text,
  ...rest
}: PartErrorProps): JSX.Element => {
  const classes = useStyles({ minHeight, });
  const { t, } = useTranslation();

  const errorText = useErrorMessage(rest);


  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        <ErrorOutlineOutlinedIcon
          className={classes.icon}
          color="error"
        />
      </div>
      <div>
        <div>
          <Typography
            variant="subtitle2"
            color="error"
          >
            {title || t('errors:commonErrorTitle')}
          </Typography>
        </div>
        <div>
          <Typography
            variant="body2"
            color="error"
          >
            {text || errorText || t('errors:somethingHappened')}
          </Typography>
        </div>
      </div>
    </div>
  );
};


export default PartError;
