import i18n from 'i18next';
import { initReactI18next, } from 'react-i18next';

import auth from './cs/auth.json';
import errors from './cs/errors.json';
import common from './cs/common.json';
import space from './cs/space.json';
import user from './cs/user.json';
import currentUser from './cs/currentUser.json';
import part from './cs/part.json';
import order from './cs/order.json';
import billing from './cs/billing.json';


const resources = {
  cs: {
    auth,
    currentUser,
    errors,
    common,
    space,
    user,
    part,
    order,
    billing,
  },
};


i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'cs',
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
