import { TableCell, TableRow } from "@material-ui/core";
import { PartFragment } from "Apollo/graphql";
import LocalizedNumber from "Tools/localization/LocalizedNumber";

interface Props {
  part: PartFragment;
}

const PartRow = ({ part }: Props): JSX.Element => {
  const totalPrice = (part.price || 0) * part.amount;
  return (
    <TableRow
      key={part.id}
      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>{part.number}</TableCell>
      <TableCell>{part.amount}</TableCell>
      <TableCell>
        <LocalizedNumber value={totalPrice} postfix=" Kč"/>
      </TableCell>
    </TableRow>
  );
};

export default PartRow;
