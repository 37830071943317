import { useUser, } from 'Tools/auth';
import ModalUserCreate from '../modals/ModalUserCreate';
import ModalUserEdit from '../modals/ModalUserEdit';
import ModalUserImport from '../modals/ModalUserImport';
import UserListPage from './UserListPage';


const UsersRouter = (): JSX.Element => {
  const user = useUser();


  return (
    <>

      {user?.permissions.USERS_SPACE_MANAGE && (
        <>
          <ModalUserImport />
          <ModalUserCreate />
          <ModalUserEdit />
        </>
      )}

      <UserListPage />

    </>
  );
};


export default UsersRouter;
