import React, { useState } from 'react';
import { Container, Step, Stepper, StepLabel, Button } from '@mui/material';
import TutorialStep from './TutorialStep'; // Import komponent pro jednotlivé kroky
import { ResponsiveStepper } from './ResponsiveStepper';

function Tutorial() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Vytvoření objednávky', 'Zadání čísla dílu', 'Výběr počtu kusů', 'Přidání do košíku', 'Kontrola výběru', 'Vyplnění údajů', 'Platba', 'Potvrzení objednávky'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Container maxWidth="sm">
      <ResponsiveStepper steps={steps} activeStep={activeStep} />
      <TutorialStep step={activeStep} handleNext={handleNext} handleBack={handleBack} />
    </Container>
  );
}

export default Tutorial;
