import { useCallback, } from 'react';
import { Formik, FormikHelpers, } from 'formik';
import { Redirect, useHistory } from 'react-router-dom';

import { useRegister, useUser, } from 'Tools/auth';
import { registerInitFormValues, registerValidationScheme, } from './constants';
import { RegisterFormValues, } from './types';
import View from './View';
import { useSnackbar } from "notistack";
import { ROUTES } from 'Utils/globals';

const RegisterPage = (): JSX.Element => {
  const register = useRegister();
  const history = useHistory();
  const { enqueueSnackbar, } = useSnackbar();
  const user = useUser();

  const handleSubmit = useCallback(
    async (
      values: RegisterFormValues,
      { setStatus, }: FormikHelpers<RegisterFormValues>,
    ) => {
      try {
        setStatus(undefined);
        await register({
          variables: {
            userRegisterInput: {
              spaceName: values.company,
              email: values.email,
            },
          },
        });

        enqueueSnackbar('Registrace byla úspěšná. Prosím pokračujte dle instrukcí ve Vašem e-mailu', { variant: 'success' });
      
        history.push(ROUTES.LOGIN);

      } catch (error) {
        setStatus({ error, });
      }
    },
    [ register, ],
  );


  if (user) {
    return (
      <Redirect
        to="/"
      />
    );
  }

  return (
    <Formik<RegisterFormValues>
      initialValues={registerInitFormValues}
      onSubmit={handleSubmit}
      validationSchema={registerValidationScheme}
    >
      <View />
    </Formik>
  );
};

export default RegisterPage;
