import { Link as RouterLink, } from 'react-router-dom';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as LogoImg, } from 'Utils/icons/logo.svg';


const useStyles = makeStyles((theme: Theme) => createStyles({
  logoWrapper: {
    background: `linear-gradient(115deg, ${theme.palette.common.white} calc(100% - 30px), ${theme.palette.primary.main} calc(100% - 29px))`,
    minHeight: 'inherit',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 60,
  },
  logo: {
    display: 'inline-flex',
    color: 'inherit',
    textDecoration: 'none',
    userSelect: 'none',
  },
  img: {
    height: 50,
  },
}));


const Logo = (): JSX.Element => {
  const classes = useStyles();


  return (
    <div className={classes.logoWrapper}>
      <Typography
        className={classes.logo}
        variant="h6"
        component={RouterLink}
        to="/"
      >
        <LogoImg className={classes.img} />
      </Typography>
    </div>
  );
};


export default Logo;
