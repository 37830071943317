import React, { MouseEvent, } from 'react';
import { useTranslation, } from 'react-i18next';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import { DatePicker, } from '@material-ui/pickers';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

import { ChangeFilter, } from '../types';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '& .MuiFilledInput-root': {
      background: theme.palette.common.white,
    },
    '& input': {
      padding: theme.spacing(1),
    },
    '& .MuiFilledInput-adornedEnd': {
      paddingRight: 9,
    },
  },
}));

export interface Props {
  value: Date | null,
  name: string,
  format: string,
  onChange: ChangeFilter<Date | null>,
  placeholder?: string,
}


const FilterDate: React.FC<Props> = ({
  value,
  name,
  placeholder,
  format = 'dd.MM.yyyy',
  onChange,
}: Props) => {
  const classes = useStyles();
  const { t, } = useTranslation();

  const currentPlaceholder = placeholder || t('common:table.inputs.placeholder');

  const handleChange = (date: Date | null) => {
    onChange({
      name,
      value: date,
    });
  };

  const handleRemove = (e: MouseEvent) => {
    e.stopPropagation();
    onChange({
      name,
      value: null,
    });
  };


  return (
    <DatePicker
      autoOk
      className={classes.root}
      disableToolbar
      variant="inline"
      format={format}
      id="date-picker-inline"
      value={value}
      clearable
      onChange={handleChange}
      inputVariant="filled"
      size="small"
      placeholder={currentPlaceholder}
      InputProps={value ? {
        endAdornment: (
          <IconButton
            onClick={handleRemove}
            size="small"
          >
            <ClearIcon />
          </IconButton>
        ),
      } : undefined}
    />
  );
};

export default FilterDate;

