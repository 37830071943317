import { ReactNode, } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import LabelWithDescription from './LabelWithDescription';

interface Props {
  value: string,
  title: ReactNode,
  description: ReactNode,
}

const RadioOptionWithDescription = ({
  value,
  title,
  description,
}: Props): JSX.Element => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio color="primary" />}
      label={
        <LabelWithDescription title={title} description={description} />
      }
    />
  );
};

export default RadioOptionWithDescription;
