import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useApolloClient, MutationTuple, ApolloError, } from '@apollo/client';
import { useSnackbar, } from 'notistack';

import { parseApolloError, } from 'Tools/parseApolloError';
import { useLogoutMutation, LogoutMutation, Exact, } from 'Apollo/graphql';
import { AuthStore, useAuthStore, } from '../store/authStore';


const selectorSetUser = (state: AuthStore) => state.setUser;
const selectorSetAccessToken = (state: AuthStore) => state.setAccessToken;


export const useLogout = (): MutationTuple<LogoutMutation, Exact<{ [key: string]: never; }>> => {
  const apolloClient = useApolloClient();
  const setUser = useAuthStore(selectorSetUser);
  const setAccessToken = useAuthStore(selectorSetAccessToken);
  const { t, } = useTranslation();
  const { enqueueSnackbar, } = useSnackbar();


  const handleRefreshCompleted = useCallback(
    () => {
      setUser(null);
      setAccessToken(null);
      apolloClient.resetStore();
    },
    [ apolloClient, setUser, setAccessToken, ],
  );

  const handleError = useCallback(
    (error: ApolloError) => {
      const errorKey = parseApolloError(error) || { key: 'errors:somethingHappened', };
      enqueueSnackbar(t(errorKey.key), { variant: 'error', });
    },
    [ enqueueSnackbar, t, ],
  );

  const ret = useLogoutMutation({
    fetchPolicy: 'no-cache',
    onCompleted: handleRefreshCompleted,
    onError: handleError,
  });

  return ret;
};
