import { useCallback, useMemo, useState } from "react";
import { useDropzone, FileRejection } from "react-dropzone";
import Box from "@material-ui/core/Box";

import ErrorMessage from "Components/ErrorMessage";
import { InputErrorMessage } from "Components/Form";
import {
  DropArea,
  parseDropZoneRejected,
  formatSizeParam,
} from "Components/File";
import { useImportUsersMutation, GqlErrorCodeEnum } from "Apollo/graphql";
import Result from "./Result";
import ErrorAlert from "Components/ErrorAlert";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const MAX_SIZE = 0.5 * 1_000_000;
const ACCEPT = [
  "text/plain",
  "application/vnd.ms-excel",
  "text/x-csv",
  "application/csv",
  "application/x-csv",
  "text/csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "text/tab-separated-values",
];

const ImportZone = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] =
    useState<InputErrorMessage | null>(null);

  const [importMutation, { loading, error, data }] = useImportUsersMutation({
    fetchPolicy: "no-cache",
    onError: () => {
      enqueueSnackbar(t("errors:somethingHappened"), { variant: "error" });
    },
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (acceptedFiles.length > 0) {
        console.log(acceptedFiles[0])
        importMutation({
          variables: {
            csv: acceptedFiles[0],
          },
        });
        setErrorMessage(null);
      } else if (fileRejections.length > 0) {
        const fileErrors = parseDropZoneRejected(
          fileRejections,
          ".csv",
          MAX_SIZE
        );
        setErrorMessage(fileErrors[0]?.errorMessage || null);
      }
    },
    [importMutation]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: ACCEPT,
    disabled: false,
    multiple: false,
    noClick: true,
    maxSize: MAX_SIZE,
    onDrop: handleDrop,
  });

  const customError = useMemo(
    () => ({
      [GqlErrorCodeEnum.UnsupportedFileFormat]: {
        key: "errors:input.fileType",
        params: {
          types: ".csv",
        },
      },
      [GqlErrorCodeEnum.FileSizeExceeded]: {
        key: "errors:input.fileMax",
        params: {
          maxSize: formatSizeParam(MAX_SIZE),
        },
      },
    }),
    []
  );

  return (
    <>
      <Box mb={2}>
        <DropArea
          infoTextKey="user:importModal.dropZoneText"
          loading={loading}
          error={!!error}
          onButtonClick={open}
          isDragActive={isDragActive}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
        />
        <ErrorMessage
          apolloError={error}
          apolloErrorMessages={customError}
          errorMessage={errorMessage}
          textAlign="left"
        />
        <ErrorAlert error={error} />
      </Box>
      <Result data={data} />
    </>
  );
};

export default ImportZone;
