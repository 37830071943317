import { useTranslation, } from 'react-i18next';
import { FormInputText, FormInputSelect, } from 'Components/Form';
import { useRoleOptions, } from 'Utils/options/useRoleOptions';
import { useSpaceOptions, } from 'Utils/options/useSpaceOptions';
import { User, } from 'Tools/auth';


interface Props {
  currentUser: User | null,
}


const UserForm = ({
  currentUser,
}: Props): JSX.Element => {
  const { t, } = useTranslation();
  // option hooks
  const roleOptions = useRoleOptions();
  const spaceOptions = useSpaceOptions();

  const spaceList = spaceOptions.data?.spaces.list || [];

  return (
    <>
      <FormInputText
        name="email"
        margin="normal"
        label={t('user:label.email')}
      />
      <FormInputSelect
        name="role"
        margin="normal"
        options={roleOptions.options}
        getOptionLabel={roleOptions.getOptionLabel}
        getOptionSelected={roleOptions.getOptionSelected}
        placeholder={t('user:label.role')}
      />

      {currentUser?.permissions.USERS_MANAGE && (
        <FormInputSelect
          name="space"
          margin="normal"
          loading={spaceOptions.loading}
          error={!!spaceOptions.error}
          options={spaceList}
          getOptionLabel={spaceOptions.getOptionLabel}
          getOptionSelected={spaceOptions.getOptionSelected}
          placeholder={t('user:label.space')}
        />
      )}

    </>
  );
};

export default UserForm;
