import { useTranslation, } from 'react-i18next';
import { makeStyles, Theme, createStyles, } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from 'Components/Link';

import { BreadcrumbLinkType, } from './types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  list: BreadcrumbLinkType[]
};

const Breadcrumb = ({
  list,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t, } = useTranslation();


  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
      <Link color="inherit" to="/">{t('common:breadcrumb.home')}</Link>
      {list.map((item) => (
        item.link
          ? <Link key={item.id} color="inherit" to={item.link}>{item.name}</Link>
          : <Typography key={item.id} color="textPrimary">{item.name}</Typography>
      ))}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
