import { useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { withStyles, makeStyles, createStyles, } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { ImportUsersMutation, } from 'Apollo/graphql';
import UserListItem from './UserListItem';


const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
    overflow: 'hidden',
  },
}))(MuiAccordionDetails);


const EXPANDED = {
  success: 'success',
  error: 'error',
};


const useStyles = makeStyles(() => createStyles({
  list: {
    width: '100%',
    maxHeight: 480,
    overflowY: 'auto',
  },
}));


interface Props {
  data?: ImportUsersMutation | null
}


const Result = ({
  data,
}: Props): JSX.Element | null => {
  const { t, } = useTranslation();
  const classes = useStyles();

  const [ expanded, setExpanded, ] = useState<string | null>(null);

  const handleChange = (newState: string) => setExpanded(
    (prevState) => (prevState === newState ? null : newState),
  );


  if (!data?.importUsers) return null;


  return (
    <Box mb={2}>
      <Accordion square expanded={expanded === EXPANDED.success} onChange={() => handleChange(EXPANDED.success)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{t('user:importModal.succeededTitle', { count: data.importUsers.succeeded.length || 0, })}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className={classes.list}>
            {data.importUsers.succeeded.map((item) => (
              <UserListItem
                key={item.email}
                icon={<CheckIcon />}
                title={item.email}
              />
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === EXPANDED.error} onChange={() => handleChange(EXPANDED.error)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{t('user:importModal.errorsTitle', { count: data.importUsers.errored.length || 0, })}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className={classes.list}>
            {data.importUsers.errored.map((item) => (
              <UserListItem
                key={item.email}
                icon={<ErrorOutlineIcon />}
                title={item.email}
                errorCode={item.statusCode}
              />
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};


export default Result;
