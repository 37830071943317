import { StoreVariables } from "./types";


export const EMPTY_STORE_STATE: StoreVariables = {
  orderSelection: null,
  pagination: {
    limit: 1000,
    offset: 0,
  },
  phase: "overview",
  orderList: [],
};
