import { useCallback, } from 'react';
import { useSnackbar, } from 'notistack';
import { useTranslation, } from 'react-i18next';
import { useApolloClient, } from '@apollo/client';
import { Formik, FormikHelpers, } from 'formik';

import {
  EditSpaceDocument,
  EditSpaceMutation,
  EditSpaceMutationVariables,
  SpaceFragment,
} from 'Apollo/graphql';
import { APP_EVENTS, useEmitAppEvent, } from 'Tools/appEvent';
import {
  initSpaceForm,
  validationSchemeSpaceForm,
} from '../../utils/constants';
import { SpaceInputVariables, } from '../../utils/variables';
import { SpaceForm, } from '../../types';
import View from './View';


interface Props {
  onClose: () => void,
  space: SpaceFragment,
}


const Logic = ({
  space,
  onClose,
}: Props): JSX.Element => {
  const apolloClient = useApolloClient();
  const emitAppEvent = useEmitAppEvent();
  const { t, } = useTranslation();
  const { enqueueSnackbar, } = useSnackbar();

  const handleSubmit = useCallback(
    async (
      values: SpaceForm,
      { setStatus, }: FormikHelpers<SpaceForm>,
    ) => {
      try {
        setStatus(undefined);

        await apolloClient.mutate<EditSpaceMutation, EditSpaceMutationVariables>({
          mutation: EditSpaceDocument,
          variables: {
            id: space.id,
            spaceInput: SpaceInputVariables(values),
          },
        });

        emitAppEvent?.(APP_EVENTS.SPACE_LIST);
        enqueueSnackbar(t('space:spacesTable.notificationEdited'), { variant: 'success', });

        onClose();
      } catch (error) {
        setStatus({ error, });
      }
    },
    [ apolloClient, onClose, space, emitAppEvent, enqueueSnackbar, t, ],
  );


  return (
    <Formik
      initialValues={{
        ...initSpaceForm,
        name: space.name,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchemeSpaceForm}
    >
      <View
        onClose={onClose}
        space={space}
      />
    </Formik>
  );
};


export default Logic;
