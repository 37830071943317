import { useTranslation, } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { useValidateResetCodeQuery, } from 'Apollo/graphql';
import { PartLoading, } from 'Components/Parts';
import ErrorMessageAlert from 'Components/ErrorMessageAlert';
import LogicForm from './LogicForm';


const customError = {
  key: 'auth:resetPassword.errorText',
};


interface Props {
  code: string,
}


const FetchCode = ({
  code,
}: Props): JSX.Element => {
  const { t, } = useTranslation();

  const { loading, error, data, } = useValidateResetCodeQuery({
    fetchPolicy: 'no-cache',
    variables: {
      code,
    },
  });


  if (loading) {
    return (
      <PartLoading minHeight="140px">
        <Typography color="textSecondary">
          {t('auth:resetPassword.loadingText')}
        </Typography>
      </PartLoading>
    );
  }

  if (error || !data?.validateResetCode) {
    return (
      <ErrorMessageAlert
        apolloError={error}
        errorMessage={customError}
      />
    );
  }

  return (
    <LogicForm
      code={code}
    />
  );
};


export default FetchCode;
