import { useMemo, ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import { FieldMetaProps, } from 'formik';

import { InputErrorMessage, } from '../types';
import { INPUT_ERROR_KEYS, } from '../constants';


export const useInputMessage = (meta: FieldMetaProps<unknown>, helperText?: ReactNode): {
  error: boolean,
  helperText?: ReactNode,
} => {
  const { t, } = useTranslation();

  return useMemo(
    () => {
      const error = meta.error as unknown as InputErrorMessage | null | undefined;
      const isError = error && meta.touched;
      if (isError) {
        let errorMessage;

        try {
          errorMessage = t([ error?.key || INPUT_ERROR_KEYS.unknown, INPUT_ERROR_KEYS.unknown, ], error?.params);
        } catch {
          errorMessage = t(INPUT_ERROR_KEYS.unknown);
        }
        return ({
          error: true,
          helperText: errorMessage,
        });
      }
      return {
        error: false,
        helperText,
      };
    },
    [ meta, helperText, t, ],
  );
};
