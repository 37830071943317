import { useCallback, } from 'react';
import { useSnackbar, } from 'notistack';
import { useTranslation, } from 'react-i18next';
import { useApolloClient, } from '@apollo/client';
import { Formik, FormikHelpers, } from 'formik';

import {
  CreateUserDocument,
  CreateUserMutation,
  CreateUserMutationVariables,
} from 'Apollo/graphql';
import { APP_EVENTS, useEmitAppEvent, } from 'Tools/appEvent';
import { useUser } from 'Tools/auth';
import {
  initUserForm,
  validationSchemeUserForm,
  validationSchemeUserFormAdmin,
} from '../../utils/constants';
import {
  userInputVariables,
} from '../../utils/variables';
import { UserForm, } from '../../types';
import View from './View';

interface Props {
  onClose: () => void,
}

const Logic = ({
  onClose,
}: Props): JSX.Element => {
  const apolloClient = useApolloClient();
  const emitAppEvent = useEmitAppEvent();
  const { t, } = useTranslation();
  const { enqueueSnackbar, } = useSnackbar();
  const currentUser = useUser();

  const handleSubmit = useCallback(
    async (
      values: UserForm,
      { setStatus, }: FormikHelpers<UserForm>,
    ) => {
      try {
        setStatus(undefined);
        await apolloClient.mutate<CreateUserMutation, CreateUserMutationVariables>({
          mutation: CreateUserDocument,
          variables: {
            userInput: userInputVariables(values, currentUser),
          },
        });

        emitAppEvent?.(APP_EVENTS.USER_LIST);
        enqueueSnackbar(t('user:usersTable.notificationCreated'), { variant: 'success', });

        onClose();
      } catch (error) {
        setStatus({ error, });
      }
    },
    [ apolloClient, onClose, emitAppEvent, enqueueSnackbar, t, currentUser, ],
  );

  return (
    <Formik
      initialValues={{
        ...initUserForm,
        space: currentUser?.space || null,
      }}
      onSubmit={handleSubmit}
      validationSchema={currentUser?.permissions.USERS_MANAGE
        ? validationSchemeUserFormAdmin
        : validationSchemeUserForm
      }
    >
      <View
        currentUser={currentUser}
        onClose={onClose}
      />
    </Formik>
  );
};


export default Logic;

