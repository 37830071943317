import create from 'zustand';


export type CurrentUserStore = {
  modalChangePassword: {
    isOpen: boolean,
  },
  openModalChangePassword: () => void,
  closeModalChangePassword: () => void,
};


/**
 * Store / Hook
 */
export const useCurrentUserStore = create<CurrentUserStore>((set) => ({
  // state
  modalChangePassword: {
    isOpen: false,
  },
  // actions
  openModalChangePassword: () => set({
    modalChangePassword: {
      isOpen: true,
    },
  }),
  closeModalChangePassword: () => set({
    modalChangePassword: {
      isOpen: false,
    },
  }),
}));
