import { useGetOrdersCustomerQuery } from "Apollo/graphql";
import ErrorMessage from "Components/ErrorMessage";
import LoadingBoxWithMessage from "Components/LoadingBoxWithMessage";
import { useUser } from "Tools/auth";
import { useTranslation } from "react-i18next";
import CustomerOrderTable from "./CustomerOrderTable";

const Logic = (): JSX.Element => {
  const authUser = useUser();
  const { t } = useTranslation("common");
  const { data, loading, error } = useGetOrdersCustomerQuery({
    variables: {
      filterInput: { userId: authUser?.id },
      paginationInput: { limit: 1000, offset: 0 },
    },
  });
  if (loading) {
    return <LoadingBoxWithMessage label={t("loading.loadingOrderTable")} />;
  }
  if (error || !data) return <ErrorMessage apolloError={error} />;
  return <CustomerOrderTable orders={data.getOrders}/>;
};

export default Logic;
