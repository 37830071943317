import OrderProcessBox from "Components/OrderProcessBox";
import FetchData from "./FetchData";

const CheckOrderPhase = (): JSX.Element => {
  return (
    <OrderProcessBox>
      <FetchData />
    </OrderProcessBox>
  );
};
export default CheckOrderPhase;
