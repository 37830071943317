import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSnackbar, } from 'notistack';
import { useApolloClient, } from '@apollo/client';
import { Formik, FormikHelpers, } from 'formik';

import { ChangePasswordMutation, ChangePasswordMutationVariables, ChangePasswordDocument, } from 'Apollo/graphql';
import { initChangePasswordFormValues, changePasswordFormValidation, } from '../../../utils/constants';
import { ChangePasswordFormValues, } from '../../../types';
import View from './View';


const ChangePassword = (): JSX.Element => {
  const apolloClient = useApolloClient();
  const { t, } = useTranslation();
  const { enqueueSnackbar, } = useSnackbar();


  const handleSubmit = useCallback(
    async (
      values: ChangePasswordFormValues,
      { setStatus, resetForm, }: FormikHelpers<ChangePasswordFormValues>,
    ) => {
      try {
        setStatus(undefined);
        await apolloClient.mutate<ChangePasswordMutation, ChangePasswordMutationVariables>({
          mutation: ChangePasswordDocument,
          variables: {
            changePasswordInput: {
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
            },
          },
        });

        enqueueSnackbar(t('currentUser:settings.changePassword.notifPasswordChanged'), { variant: 'success', });
        resetForm();
      } catch (error) {
        setStatus({ error, });
      }
    },
    [ apolloClient, enqueueSnackbar, t, ],
  );


  return (
    <Formik
      initialValues={initChangePasswordFormValues}
      onSubmit={handleSubmit}
      validationSchema={changePasswordFormValidation}
    >
      <View />
    </Formik>
  );
};


export default ChangePassword;
