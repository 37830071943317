import { Form, } from 'formik';
import { useTranslation, } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { ModalHeader, ModalBody, ModalFooter, } from 'Components/Modal';
import { FormSubmit, FormError, } from 'Components/Form';
import SpaceForm from 'Modules/Spaces/components/SpaceForm';


interface Props {
  onClose: () => void
}


const View = ({
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation();
  return (
    <>

      <ModalHeader onClose={onClose}>
        {t('space:createModal.title')}
      </ModalHeader>

      <Form>

        <ModalBody>
          <SpaceForm />
        </ModalBody>

        <ModalFooter>
          <Grid container justify="space-between" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="default"
                onClick={onClose}
              >
                {t('common:action.back')}
              </Button>
            </Grid>
            <Grid item>
              <FormSubmit
                color="primary"
              >
                {t('common:action.create')}
              </FormSubmit>
            </Grid>
          </Grid>
          <FormError />
        </ModalFooter>

      </Form>

    </>
  );
};
export default View;
