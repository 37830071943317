import { usePrepareDispatchForOrdersMutation } from "Apollo/graphql";
import LoadingBoxWithMessage from "Components/LoadingBoxWithMessage";
import { useDispatchOrdersStore } from "Modules/ManageDispatchOrders/store";
import { DispatchOrderStore } from "Modules/ManageDispatchOrders/store/types";
import { openInNewTab } from "Utils/openInNewTab";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { onlySelectedInOrderSelection } from "../OverView/helpers";
import View from "./View";
import ErrorAlert from "Components/ErrorAlert";

const selectStoreData = (s: DispatchOrderStore) => ({
  setPhase: s.setPhase,
  orderList: s.orderSelection,
});

const FetchData = (): JSX.Element => {
  const { t ,} = useTranslation('common')
  const { orderList, setPhase } =
    useDispatchOrdersStore(selectStoreData);
  const [mutation, result] = usePrepareDispatchForOrdersMutation({
    variables: { orderIds: onlySelectedInOrderSelection(orderList || {}) },
    onCompleted(data) {
      if (data.prepareDispatchForOrders) {
        openInNewTab(data.prepareDispatchForOrders.url)
      }
    },
    onError(_) {}
  });
  useEffect(() => {
    mutation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (result.loading) return <LoadingBoxWithMessage label={t('loading.loadingOrderTable')}/>; 
  if (result.error || !result.data?.prepareDispatchForOrders) return <ErrorAlert error={result.error} />
  return (
    <View data={result.data?.prepareDispatchForOrders} setPhase={setPhase}/>
  );
};
export default FetchData;
