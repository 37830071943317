import { QueryResult, } from '@apollo/client';
import MuiTableBody from '@material-ui/core/TableBody';

import { SpacesQuery, } from 'Apollo/graphql';
import { User, } from 'Tools/auth';
import { TableError, TableEmpty, } from 'Components/Table';
import { SpacesFilter, SpacesStore, useSpacesStore, } from '../../../store/useSpacesStore';
import TableRow from './TableRow';
import SkeletonRow from './SkeletonRow';


const selectorOpenModalEdit = (store: SpacesStore) => store.openModalEdit;


interface Props {
  user: User | null,
  filter: SpacesFilter,
  spacesQuery: QueryResult<SpacesQuery>,
}


const TableBody = ({
  user,
  filter,
  spacesQuery: {
    loading,
    error,
    data,
  },
}: Props): JSX.Element => {
  const openModalEdit = useSpacesStore(selectorOpenModalEdit);


  if (loading) {
    const loadingRowsCount = data?.spaces.list.length || 6;

    return (
      <MuiTableBody>
        {[ ...Array(loadingRowsCount), ].map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <SkeletonRow key={index + 1} />
        ))}
      </MuiTableBody>
    );
  }

  if (error || !data) return <TableError colSpan={8} apolloError={error} />;

  if (data.spaces.list.length < 1) return <TableEmpty colSpan={8} />;

  return (
    <MuiTableBody>
      {data.spaces.list.map((item) => (
        <TableRow
          user={user}
          key={item.id}
          row={item}
          onDetail={openModalEdit}
        />
      ))}
    </MuiTableBody>
  );
};

export default TableBody;
