import { useCallback, } from 'react';
import { Formik, FormikHelpers, } from 'formik';
import { Redirect, } from 'react-router-dom';

import { useLogin, useUser, } from 'Tools/auth';
import { loginInitFormValues, loginValidationScheme, } from './constants';
import { LoginFormValues, } from './types';
import View from './View';


const LoginPage = (): JSX.Element => {
  const login = useLogin();
  const user = useUser();

  const handleSubmit = useCallback(
    async (
      values: LoginFormValues,
      { setStatus, }: FormikHelpers<LoginFormValues>,
    ) => {
      try {
        setStatus(undefined);
        await login({
          variables: {
            loginInput: {
              email: values.email,
              password: values.password,
            },
          },
        });
      } catch (error) {
        setStatus({ error, });
      }
    },
    [ login, ],
  );


  if (user) {
    return (
      <Redirect
        to="/"
      />
    );
  }

  return (
    <Formik<LoginFormValues>
      initialValues={loginInitFormValues}
      onSubmit={handleSubmit}
      validationSchema={loginValidationScheme}
    >
      <View />
    </Formik>
  );
};


export default LoginPage;
