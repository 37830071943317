import Box from "@mui/material/Box";
import { useUser } from "Tools/auth";
import OverView from "./Phases/OverView";
import { useManageOrdersStore } from "./store";
import { ManageOrdersStore, Phases } from "./store/types";
import Edit from "./Phases/Edit";
const selectStoreData = (s: ManageOrdersStore) => ({
  phase: s.phase,
});

const ManageIncomingOrders = (): JSX.Element => {
  const phaseToComponents: { [key in Phases]: JSX.Element } = {
    overview: <OverView />,
    edit: <Edit />,
  };
  const { phase } = useManageOrdersStore(selectStoreData);
  const user = useUser();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box
        sx={(theme) => ({
          marginBottom: theme.spacing(10),
          [theme.breakpoints.up("md")]: {
            maxWidth: theme.spacing(120),
            padding: theme.spacing(2),
          },
          [theme.breakpoints.down("md")]: {
            width: "auto",
            height: "auto",
            overflowX: "auto",
            overflowY: "auto",
            padding: theme.spacing(1),
            marginX: "auto",
          },
        })}
      >
        {user?.permissions.MANAGE_ORDERS && phaseToComponents[phase]}
      </Box>
    </Box>
  );
};

export default ManageIncomingOrders;
