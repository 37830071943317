import { FORM_VALIDATORS, } from 'Components/Form';
import { newPasswordRules, } from 'Utils/password';
import * as Yup from 'yup';


export const initResetPasswordFormValues = {
  password: '',
  passwordCheck: '',
};


export const resetPasswordFormValidation = Yup.object().shape({
  password: newPasswordRules,
  passwordCheck: newPasswordRules
    .oneOf([ Yup.ref('password'), null, ], FORM_VALIDATORS.REF_PASSWORD_MATCH),
});
