import { FORM_VALIDATORS, } from 'Components/Form';
import { newPasswordRules, } from 'Utils/password';
import * as Yup from 'yup';


export const initChangePasswordFormValues = {
  oldPassword: '',
  newPassword: '',
  newPasswordCheck: '',
};


export const changePasswordFormValidation = Yup.object().shape({
  oldPassword: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .max(256, FORM_VALIDATORS.STRING_MAX),
  newPassword: newPasswordRules,
  newPasswordCheck: newPasswordRules
    .oneOf([ Yup.ref('newPassword'), null, ], FORM_VALIDATORS.REF_PASSWORD_MATCH),
});
