import { DropzoneRootProps, DropzoneInputProps, } from 'react-dropzone';
import { Trans, useTranslation, } from 'react-i18next';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import clsx from 'clsx';


type DropZoneStyleProps = {
  disabled?: boolean,
  error?: boolean,
};


const BORDER_COLOR = '#0000003b';


const useStyles = makeStyles((theme: Theme) => createStyles({
  dropZoneWrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 120,
    padding: theme.spacing(2),
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: BORDER_COLOR,
    borderRadius: theme.shape.borderRadius,
  },
  dropZoneWrapperActive: {
    borderColor: theme.palette.primary.main,
  },
  dropZoneWrapperError: {
    borderColor: theme.palette.error.main,
  },
  info: ({ disabled, }: DropZoneStyleProps) => ({
    marginBottom: theme.spacing(2),
    color: disabled ? theme.palette.text.disabled : undefined,
  }),
  loadingText: {
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.text.disabled,
  },
  spinner: {
    marginRight: 4,
  },
  icon: {
    color: BORDER_COLOR,
    fontSize: '2rem',
  },
}));


interface Props {
  loading?: boolean,
  error?: boolean,
  isDragActive?: boolean,
  infoTextKey?: string
  isMulti?: boolean,
  disabled?: boolean,
  onButtonClick: () => void,
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T,
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T
}


const DropArea = ({
  loading,
  error,
  isDragActive,
  infoTextKey,
  isMulti,
  disabled,
  onButtonClick,
  getRootProps,
  getInputProps,
}: Props): JSX.Element => {
  const i18nKeyInfo = infoTextKey || (isMulti ? 'common:files.dropAreaInfoMulti' : 'common:files.dropAreaInfoSingle');
  const isDisabledState = disabled || loading;

  const classes = useStyles({ disabled: isDisabledState, error, });
  const { t, } = useTranslation();


  return (
    <div
      {...getRootProps()}
      className={clsx({
        [classes.dropZoneWrapper]: true,
        [classes.dropZoneWrapperActive]: isDragActive,
        [classes.dropZoneWrapperError]: error,
      })}
    >

      <input {...getInputProps()} />

      <Typography className={classes.info}>
        <Trans
          i18nKey={i18nKeyInfo}
          components={{
            button: (
              <Button
                variant="contained"
                color="primary"
                disabled={isDisabledState}
                onClick={onButtonClick}
              />
            ),
          }}
        />
      </Typography>

      {loading
        ? (
          <Typography component="div" className={classes.loadingText} variant="body2">
            <CircularProgress className={classes.spinner} size={20} />
            {t('common:files.uploading')}
          </Typography>
        )
        : <CloudUploadIcon className={classes.icon} />
      }

    </div>
  );
};


export default DropArea;
