import { InputErrorMessage, } from './types';


export const INPUT_ERROR_KEYS = {
  unknown: 'errors:input.unknown',
  required: 'errors:input.required',
  email: 'errors:input.email',
  stringMin: 'errors:input.stringMin',
  stringMax: 'errors:input.stringMax',
  stringPassword: 'errors:input.stringPassword',
  numberMin: 'errors:input.numberMin',
  numberMax: 'errors:input.numberMax',
  dateMinToday: 'errors:input.dateMinToday',
  refPasswordMatch: 'errors:input.refPasswordMatch',
  refEmailMatch: 'errors:input.refEmailMatch',
  wrongFormat: 'errors:input.wrongFormat',
};


export const FORM_VALIDATORS = {
  REQUIRED: {
    key: INPUT_ERROR_KEYS.required,
  },
  EMAIL: {
    key: INPUT_ERROR_KEYS.email,
  },
  DATE_MIN_TODAY: (): InputErrorMessage => ({
    key: INPUT_ERROR_KEYS.dateMinToday,
  }),
  STRING_MIN: (({ min, }: { min: number, }): InputErrorMessage => ({
    key: INPUT_ERROR_KEYS.stringMin,
    params: { min, },
  })),
  STRING_MAX: (({ max, }: { max: number, }): InputErrorMessage => ({
    key: INPUT_ERROR_KEYS.stringMax,
    params: { max, },
  })),
  STRING_PASSWORD: {
    key: INPUT_ERROR_KEYS.stringPassword,
  },
  NUMBER_MIN: (({ min, }: { min: number, }): InputErrorMessage => ({
    key: INPUT_ERROR_KEYS.numberMin,
    params: { min, },
  })),
  NUMBER_MAX: (({ max, }: { max: number, }): InputErrorMessage => ({
    key: INPUT_ERROR_KEYS.numberMax,
    params: { max, },
  })),
  REF_PASSWORD_MATCH: {
    key: INPUT_ERROR_KEYS.refPasswordMatch,
  },
  REF_EMAIL_MATCH: {
    key: INPUT_ERROR_KEYS.refEmailMatch,
  },
  WRONG_FORMAT: {
    key: INPUT_ERROR_KEYS.wrongFormat,
  }
};
