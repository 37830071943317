import OrderProcessBox from "Components/OrderProcessBox";
import { useOrderStore } from "../store";
import { OrderStore } from "../store/types";
import View from "./View";

const selectStoreData = (s: OrderStore) => ({
  paymentLink: s.paymentLink,
  billingInfo: s.billingInfo,
  resetStore: s.resetStore,
});

const CompletePhase = (): JSX.Element => {
  const { paymentLink, resetStore, billingInfo } =
    useOrderStore(selectStoreData);
  return (
    <OrderProcessBox>
      <View
        paymentLink={paymentLink}
        resetStore={resetStore}
        email={billingInfo?.email || ""}
      />
    </OrderProcessBox>
  );
};
export default CompletePhase;
