import Chip from '@mui/material/Chip';
import { OrderStatusEnum } from "Apollo/graphql";
import { useTranslation } from "react-i18next";
import { match } from 'ts-pattern';

interface Props {
  status: OrderStatusEnum;
}

const OrderStatusChip: React.FC<Props> = ({
  status,
}: Props) => {
  const { t } = useTranslation();
  const label = t(`common:orderStatus.${status}`);
  const color = match(status).with(
    OrderStatusEnum.Canceled, () => 'error',
  ).with(
    OrderStatusEnum.Completed, () => 'success',
  ).otherwise(() => 'warning');

  return (
    <Chip
      size="small"
      variant="outlined"
      label={label}
      color={color as any}
    />
  );
};

export default OrderStatusChip;
