import * as Yup from 'yup';
import { FORM_VALIDATORS, } from 'Components/Form';
import { BrandEnum } from 'Apollo/graphql';

export const initCheckPartForm = () => ({
  brand: null,
})

export const validationSchemeCheckPartForm = Yup.object().shape({
  brand: Yup.object({
    id: Yup.string()
      .oneOf(Object.values(BrandEnum), FORM_VALIDATORS.REQUIRED)
      .required(FORM_VALIDATORS.REQUIRED),
    label: Yup.string().required(FORM_VALIDATORS.REQUIRED)
  })
  .nullable() 
  .required(FORM_VALIDATORS.REQUIRED)
});
