import {
  Button,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { CustomerTableOrderFragment } from "Apollo/graphql";
import OrderStatusChip from "Components/OrderStatusChip";
import { useTranslation } from "react-i18next";
import OrderDetails from "./PartTable";
import { openInNewTab } from "Utils/openInNewTab";
import moment from "moment";
interface Props {
  orders: CustomerTableOrderFragment[];
}
const CustomerOrderTable = ({ orders }: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table
        aria-label="simple table"
        width={undefined}
      >
        <TableHead>
          <TableRow>
            <TableCell>{t("order:orders.orderNumber")}</TableCell>
            <TableCell>{t("order:orders.orderedAt")}</TableCell>
            <TableCell>{t("order:orders.status")}</TableCell>
            <TableCell>{t("order:orders.invoice")}</TableCell>
            <TableCell width={"40%"}>{t("order:orders.parts")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow key={order.id}>
              <TableCell>{order.orderNumber}</TableCell>
              <TableCell>{order.orderedAt ? moment(order.orderedAt).format('LL') : "-"}</TableCell>
              <TableCell>
                <OrderStatusChip status={order.status} />
              </TableCell>
              <TableCell>
                {order?.invoiceUrl ? (<Button onClick={()=> order.invoiceUrl ? openInNewTab(order.invoiceUrl) : "-"}>{t("order:orders.invoiceUrl")}</Button>) : "-"}
              </TableCell>
              <TableCell width={"40%"}>
                <OrderDetails order={order}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomerOrderTable;
