import { TableCell, TableRow } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
interface Props {
  showCheckbox?: boolean;
  showEdit?: boolean;
  toggleAll: (incomingState: boolean) => void;
  allSelected: boolean;
  showPartDropDown?: boolean;
}
const Headers = ({
  showCheckbox,
  showEdit,
  allSelected,
  toggleAll,
  showPartDropDown,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {showCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={allSelected}
              onChange={(e) => toggleAll(e.target.checked)}
            />
          </TableCell>
        )}
        {showPartDropDown && <TableCell />}
        <TableCell style={{ minWidth: "200px" }}>
          {t("order:adminTable.orderId")}
        </TableCell>
        <TableCell style={{ minWidth: "200px" }}>
          {t("order:adminTable.topTransId")}
        </TableCell>
        <TableCell style={{ minWidth: "150px" }}>
          {t("order:adminTable.name")}
        </TableCell>
        <TableCell style={{ minWidth: "150px" }}>
          {t("order:adminTable.partAmount")}
        </TableCell>
        <TableCell style={{ minWidth: "150px" }}>
          {t("order:adminTable.status")}
        </TableCell>
        {showEdit && <TableCell />}
      </TableRow>
    </TableHead>
  );
};
export default Headers;
