import * as Yup from 'yup';
import { FORM_VALIDATORS, } from 'Components/Form';


export const loginInitFormValues = {
  email: '',
  password: '',
};


export const loginValidationScheme = Yup.object().shape({
  email: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .max(256, FORM_VALIDATORS.STRING_MAX),
  password: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .max(256, FORM_VALIDATORS.STRING_MAX),
});
