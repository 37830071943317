import { useField, } from 'formik';

import { useInputMessage, } from '../utils/useInputMessage';
import TextField, { TextFieldProps } from '@mui/material/TextField';


interface Props extends Omit<TextFieldProps,'onChange'> {
  name: string,
}


const FormInputText = ({
  defaultValue,
  name,
  autoComplete,
  onBlur,
  ...rest
}: Props): JSX.Element => {
  const [ field, meta, ] = useField({
    name,
    onBlur,
  });
  const { error, helperText, } = useInputMessage(meta);

  return (
    <TextField
      fullWidth
      autoComplete={autoComplete}
      name={name}
      onChange={field.onChange}
      value={field.value}
      defaultValue={defaultValue}
      onBlur={field.onBlur}
      error={error}
      helperText={helperText}
      {...rest}
    />
  );
};


export default FormInputText;
