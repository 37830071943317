import { DispatchOrderStore } from "Modules/ManageDispatchOrders/store/types";
import { onlySelectedInOrderSelection, sumOrderSelection } from "./helpers";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useResetTopTransForOrdersMutation } from "Apollo/graphql";

interface Props {
  orderSelection: DispatchOrderStore["orderSelection"];
  setPhase: DispatchOrderStore["setPhase"];
  resetOrderSelection: DispatchOrderStore["resetOrderSelection"];
  removeOrders: DispatchOrderStore["removeOrders"];
}

const DispatchSelected = ({
  orderSelection,
  setPhase,
  resetOrderSelection,
  removeOrders,
}: Props): JSX.Element => {
  const numberSelected = orderSelection ? sumOrderSelection(orderSelection) : 0;
  const { t } = useTranslation("order");
  const [resetMutation, resetResult] = useResetTopTransForOrdersMutation({
    variables: {
      orderIds: onlySelectedInOrderSelection(orderSelection || {}),
    },
    onCompleted(data) {
      if (data.resetTopTransForOrders) {
        removeOrders(onlySelectedInOrderSelection(orderSelection || {}));
        resetOrderSelection();
      }
    },
  });
  return (
    <Box display="flex" flexDirection="column" alignSelf="flex-end" gap={2}>
      <Typography variant="body1">{`${t(
        "dispatch.selected"
      )} ${numberSelected}`}</Typography>
      <Button
        disabled={resetResult.loading}
        variant="contained"
        color="warning"
        onClick={() => resetMutation()}
      >
        {t("dispatch.reset")}
      </Button>
      <Button
        disabled={resetResult.loading}
        variant="contained"
        onClick={() => setPhase("dispatch")}
      >
        {t("dispatch.dispatch")}
      </Button>
    </Box>
  );
};
export default DispatchSelected;
