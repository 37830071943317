import { ReactNode, } from 'react';
import Typography from '@material-ui/core/Typography';

interface Props {
  title: ReactNode,
  description: ReactNode,
}


const LabelWithDescription = ({
  title,
  description,
}: Props): JSX.Element => {
  return (
    <>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <Typography variant="body1" gutterBottom>{description}</Typography>
    </>
  );
};

export default LabelWithDescription;
