import create from 'zustand';
import { UserFragment, SpaceOptionFragment, OrderEnum, } from 'Apollo/graphql';
import { OptionBoolean, } from 'Utils/types';
import { Sort, } from 'Components/Table';
import { UserRoleType, } from '../types';


export type UserFilter = {
  email: string,
  active: OptionBoolean | null,
  role: UserRoleType | null,
  space: SpaceOptionFragment | null,
  offset: number,
  limit: number,
  sort: Sort,
};

export type ChangeUserFilter = (payload: { name: string, value: any, }) => void;

export type UserStore = {
  filter: UserFilter,
  modalCreate: {
    isOpen: boolean,
  },
  modalEdit: {
    isOpen: boolean,
    user: UserFragment | null
  },
  modalExport: {
    isOpen: boolean,
  },
  modalImport: {
    isOpen: boolean,
  },
  changeFilter: ChangeUserFilter,
  openModalCreate: () => void,
  closeModalCreate: () => void,
  openModalEdit: (user: UserFragment) => void,
  closeModalEdit: () => void,
  openModalExport: () => void,
  closeModalExport: () => void,
  openModalImport: () => void,
  closeModalImport: () => void,
};

/**
 * Store / Hook
 */
export const useUserStore = create<UserStore>((set) => ({
  // state
  filter: {
    email: '',
    active: null,
    role: null,
    space: null,
    offset: 0,
    limit: 20,
    sort: {
      by: '',
      order: OrderEnum.Asc,
    },
  },
  modalCreate: {
    isOpen: false,
  },
  modalEdit: {
    isOpen: false,
    user: null,
  },
  modalExport: {
    isOpen: false,
  },
  modalImport: {
    isOpen: false,
  },
  // actions
  changeFilter: ({ name, value, }) => set((state) => ({
    filter: {
      ...state.filter,
      offset: 0,
      [name]: value,
    },
  })),
  openModalCreate: () => set({
    modalCreate: {
      isOpen: true,
    },
  }),
  closeModalCreate: () => set({
    modalCreate: {
      isOpen: false,
    },
  }),
  openModalEdit: (user) => set({
    modalEdit: {
      isOpen: true,
      user,
    },
  }),
  closeModalEdit: () => set({
    modalEdit: {
      isOpen: false,
      user: null,
    },
  }),
  openModalExport: () => set({
    modalExport: {
      isOpen: true,
    },
  }),
  closeModalExport: () => set({
    modalExport: {
      isOpen: false,
    },
  }),
  openModalImport: () => set({
    modalImport: {
      isOpen: true,
    },
  }),
  closeModalImport: () => set({
    modalImport: {
      isOpen: false,
    },
  }),
}));
