import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { BrandEnum } from "Apollo/graphql";
import { Parts } from "Modules/Order/store/types";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { useOptionsBrands } from "./useBrandOptions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

interface Props {
  parts: Parts | null;
}
const CheckPartForm = ({ parts }: Props): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const brandOptions = useOptionsBrands();
  const [field, meta, helpers] = useField("brand");
  const firstPartBrand = parts ? parts[Object.keys(parts)[0]]?.brand : null;
  const actualOptions = firstPartBrand
    ? match(firstPartBrand)
        .with(BrandEnum.Bmw, () => brandOptions.bmwOptions)
        .with(BrandEnum.MercedesSmart, () => brandOptions.mercedesOptions)
        .otherwise(() => brandOptions.otherBrandOptions)
    : brandOptions.options;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }} gap={2}>
      <Autocomplete
        fullWidth
        disablePortal
        id="brand"
        options={actualOptions}
        value={field.value} // use field value from Formik
        onChange={(_, newValue) => helpers.setValue(newValue)} // update Formik field value
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("part:label.brand")}
            error={meta.touched && !!meta.error}
            sx={(theme) => ({
              backgroundColor: theme.palette.common.white,
              borderRadius: theme.spacing(0.5),
            })}
            size={isMobile ? 'small' : 'medium'}
          />
        )}
      />
      <Typography sx={theme => ({ fontStyle: "oblique", marginBottom: theme.spacing(1) })}>
        {t("order:searchBmwAndMercedesLimitationNotification")}
      </Typography>
    </Box>
  );
};

export default CheckPartForm;
