import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DayData } from "../utils";
import moment from "moment";

interface Props {
  callBack: (date: Date) => void;
  data: DayData;
  selectedDay: string;
}

const DayButton = ({ data, callBack, selectedDay }: Props): JSX.Element => {
  const momentDate = moment(data.date).format("YYYY-MM-DD");
  const currentDate = moment(new Date()).format('YYYY-MM-DD');
  const isCurrentDay = momentDate === currentDate;
  const isSelectedDay = selectedDay === momentDate;
  const displayDate = moment(data.date).format("DD.MM.YYYY");
  
  return (
    <Button
      variant="contained"
      color={isCurrentDay ? "success" : "inherit"}
      onClick={() => callBack(data.date)}
      style={{
        border: "4px solid",
        borderColor: isSelectedDay ? "green" : "grey",
      }}
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="body1">{data.name}</Typography>
        <Typography variant="body1">{displayDate}</Typography>
      </Box>
    </Button>
  );
};

export default DayButton;
