import * as Yup from 'yup';
import { FORM_VALIDATORS, } from 'Components/Form';
import { UserForm, } from '../types';

export const initUserForm = {
  email: '',
  space: null,
  role: null,
} as UserForm;

const schemaUser = {
  email: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .email(FORM_VALIDATORS.EMAIL)
    .max(256, FORM_VALIDATORS.STRING_MAX),
  role: Yup.object()
    .required(FORM_VALIDATORS.REQUIRED).nullable(),
};

export const validationSchemeUserForm = Yup.object().shape(schemaUser);


export const validationSchemeUserFormAdmin = Yup.object().shape({
  ...schemaUser,
  space: Yup.object()
    .required(FORM_VALIDATORS.REQUIRED).nullable(),
});

