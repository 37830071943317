import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { useOrderStore } from "Modules/Order/store";
import { OrderStore, Parts } from "Modules/Order/store/types";
import { parseStorePartToPart } from "Modules/Order/store/utils";
import Headers from "./Headers";
import RowLogic from "./RowLogic";

interface Props {
  parts: Parts | null;
  removePartFromStore: OrderStore["removePart"];
  changeAmount: OrderStore["changeAmount"];
  updatePart: OrderStore["updatePart"];
}

const PartTable = ({
  parts,
  removePartFromStore,
  changeAmount,
  updatePart,
}: Props): JSX.Element => (
  <TableContainer
    component={Paper}
    sx={(theme) => ({
      maxWidth: theme.spacing(112),
      marginBottom: theme.spacing(2),
    })}
  >
    <Table aria-label="simple table">
      <Headers />
      <TableBody>
        {parseStorePartToPart(parts).map((part) => (
          <RowLogic
            part={part}
            key={part.id}
            changeAmount={changeAmount}
            removePartFromStore={removePartFromStore}
            updatePart={updatePart}
          />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default PartTable;
