import { useTranslation, } from 'react-i18next';
import { Form, } from 'formik';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import UserIcon from '@material-ui/icons/PersonOutlineOutlined';
import Paper from '@material-ui/core/Paper';

import Link from 'Components/Link';
import { FormInputText, FormSubmit, FormError, } from 'Components/Form';
import { ROUTES, } from 'Utils/globals';
import InitLayout from '../../components/InitLayout';


const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    padding: theme.spacing(6, 4),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  form: {
    marginBottom: theme.spacing(1),
  },
  inputIcon: {
    pointerEvents: 'none',
  },
  submit: {
    marginTop: theme.spacing(1),
  },
}));


interface Props {
  isSent: boolean,
}


const View = ({ isSent, }: Props): JSX.Element => {
  const classes = useStyles();
  const { t, } = useTranslation();

  return (
    <InitLayout>
      <Paper
        className={classes.paper}
        elevation={6}
      >

        <Typography
          className={classes.title}
          variant="h3"
          color="primary"
          align="center"
        >
          {t('auth:forgottenPassword.title')}
        </Typography>

        {isSent ? (
          <Typography variant="body2">
            {t('auth:forgottenPassword.isSentText')}
          </Typography>
        ) : (
          <Form className={classes.form}>

            <Typography color="textSecondary">
              {t('auth:forgottenPassword.info')}
            </Typography>

            <FormInputText
              name="email"
              size="medium"
              margin="normal"
              autoFocus
              label={t('auth:label.email')}
              autoComplete="username"
              type="email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={classes.inputIcon}>
                    <UserIcon />
                  </InputAdornment>
                ),
              }}
            />

            <FormSubmit
              fullWidth
              color="primary"
              size="large"
              className={classes.submit}
            >
              {t('auth:forgottenPassword.submit')}
            </FormSubmit>

            <FormError
              alert
              boxProps={{
                mt: 1,
              }}
            />

          </Form>
        )}

        <Link
          onMouseDown={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => { event.preventDefault(); }}
          color="textSecondary"
          variant="body1"
          to={`/${ROUTES.LOGIN}`}
        >
          {t('auth:forgottenPassword.loginLink')}
        </Link>
        <br/>
        <Link
          onMouseDown={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => { event.preventDefault(); }}
          color="textSecondary"
          variant="body1"
          to={`/${ROUTES.REGISTER}`}
        >
          {t('auth:forgottenPassword.linkRegister')}
        </Link>
      </Paper>
    </InitLayout>
  );
};


export default View;
