import { FORM_VALIDATORS, } from 'Components/Form';
import * as Yup from 'yup';


const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;


export const newPasswordRules = Yup.string()
  .required(FORM_VALIDATORS.REQUIRED)
  .max(256, FORM_VALIDATORS.STRING_MAX)
  .matches(
    passwordRegex,
    {
      message: FORM_VALIDATORS.STRING_PASSWORD,
      excludeEmptyString: false,
    },
  );
