import { useTranslation, } from 'react-i18next';
import { Modal, ModalHeader, } from 'Components/Modal';
import FetchData from './FetchData';
import { UserStore, useUserStore, } from '../../store/useUserStore';


const selectorModalData = (store: UserStore) => (store.modalEdit);
const selectorClose = (store: UserStore) => (store.closeModalEdit);

const ModalOrganizationEdit = (): JSX.Element | null => {
  const modalData = useUserStore(selectorModalData);
  const close = useUserStore(selectorClose);

  const { t, } = useTranslation();

  if (!modalData.isOpen || !modalData.user) return null;

  return (
    <Modal
      open
      onClose={close}
      maxWidth="sm"
    >
      <ModalHeader onClose={close}>
        {t('user:editModal.title')}
      </ModalHeader>

      <FetchData
        onClose={close}
        userId={modalData.user.id}
      />

    </Modal>
  );
};


export default ModalOrganizationEdit;
