import { StoreVariables } from "./types";


export const EMPTY_STORE_STATE: StoreVariables = {
  activeOrderId: "",
  phase: "overview",
  dateInterval: null,
  activeOrder: null,
  orderList: [],
};
