import { useOrderStore } from "../store";
import CustomerBillingInfo from "../components/CustomerBillingInfo";
import { BillingInfoFragment, useCreateInvoiceMutation } from "Apollo/graphql";
import OwnerBillingInfo from "../components/OwnerBillingInfo";
import Divider from "@mui/material/Divider";
import PartsDataTable from "../components/PartsDataTable";
import { parseStorePartToPart } from "../store/utils";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import PaymentConditions from "../components/PaymentConditions";
import { OrderStore } from "../store/types";
import { Button } from "@mui/material";
import ErrorAlert from "Components/ErrorAlert";

const selectStoreData = (s: OrderStore) => ({
  changePhase: s.changePhase,
  setPaymentLink: s.setPaymentLink,
  shippingPrice: s.shippingPrice,
  parts: s.parts,
  billingInfo: s.billingInfo,
  orderId: s.orderId,
});

const Logic = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    shippingPrice,
    parts,
    billingInfo,
    orderId,
    changePhase,
    setPaymentLink,
  } = useOrderStore(selectStoreData);
  const [mutation, result] = useCreateInvoiceMutation({
    variables: { orderId },
    onCompleted: (result) => {
      if (result.createInvoice) {
        setPaymentLink(result.createInvoice);
        changePhase("complete");
      }
    },
  });
  const onSubmit = async () => {
    await mutation();
  };
  return (
    <Box display="flex" flexDirection="column">
      <Box
        marginBottom={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="1rem"
      >
        <Typography variant="h2">{t("billing:overViewTitle")}</Typography>
        <Typography variant="body1">{t("billing:overViewInfo")}</Typography>
      </Box>

      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <OwnerBillingInfo />
        <Divider orientation="vertical" flexItem sx={{ marginX: 2, display: { xs: 'none', md: 'block' } }} />
        <CustomerBillingInfo data={billingInfo as BillingInfoFragment} />
      </Box>

      <PartsDataTable
        parts={parseStorePartToPart(parts)}
        shippingPrice={shippingPrice}
      />
      
      <PaymentConditions onSubmit={onSubmit} />

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        marginBottom={2}
      >
        <Button
          color="inherit"
          variant="contained"
          onClick={() => changePhase("billing")}
        >
          {t("common:action.back")}
        </Button>
      </Box>
      <ErrorAlert error={result.error} />
    </Box>
  );
};

export default Logic;
