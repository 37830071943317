import { Modal, } from 'Components/Modal';

import { UserStore, useUserStore, } from '../../store/useUserStore';
import Logic from './Logic';


const selectorModalData = (store: UserStore) => (store.modalImport);
const selectorClose = (store: UserStore) => (store.closeModalImport);


const ModalUserImport = (): JSX.Element | null => {
  const modalData = useUserStore(selectorModalData);
  const close = useUserStore(selectorClose);

  if (!modalData.isOpen) return null;

  return (
    <Modal
      open
      maxWidth="sm"
    >
      <Logic
        onClose={close}
      />
    </Modal>
  );
};


export default ModalUserImport;
