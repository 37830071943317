import { makeStyles, createStyles, } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import InitLayout from '../../components/InitLayout';


const useStyles = makeStyles(() => createStyles({
  wrapper: {
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));


const InitLoader = (): JSX.Element => {
  const classes = useStyles();


  return (
    <InitLayout>
      <div className={classes.wrapper}>
        <CircularProgress size={60} />
      </div>
    </InitLayout>
  );
};


export default InitLoader;
