import { useCallback, useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';

import { UserRoleEnum, } from 'Apollo/graphql';
import { UserRoleType, } from 'Modules/Users/types';
import { useUser, } from 'Tools/auth';


const getOptionSelected = (o: UserRoleType, v: UserRoleType) => o.id === v.id;


export const roleOptions: UserRoleType[] = [
  {
    id: UserRoleEnum.User,
    value: 'auth:optionRole.user',
  },
  {
    id: UserRoleEnum.SpaceManager,
    value: 'auth:optionRole.spaceManager',
  },
  {
    id: UserRoleEnum.Admin,
    value: 'auth:optionRole.admin',
  },
];


type UseRoleOptions = () => {
  options: UserRoleType[];
  getOptionLabel: (o: UserRoleType) => any;
  getOptionSelected: (o: UserRoleType, v: UserRoleType) => boolean;
};


export const useRoleOptions: UseRoleOptions = () => {
  const { t, } = useTranslation();
  const user = useUser();

  const options = useMemo(
    () => {
      if (user?.permissions.USERS_MANAGE) return roleOptions;
      if (user?.permissions.USERS_SPACE_MANAGE) return [ roleOptions[0] ];
      return [];
    },
    [ user, ],
  );

  const getOptionLabel = useCallback(
    (o: UserRoleType) => (t(o.value)),
    [ t, ],
  );

  return {
    options,
    getOptionLabel,
    getOptionSelected,
  };
};
