import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import { BillingInfoFragment } from "Apollo/graphql";
import BillingInfo from "Modules/Order/components/BillingInfo";
import { useTranslation } from "react-i18next";

interface Props {
  data: BillingInfoFragment
}

const CustomerBillingInfo = ({ data }: Props) => {
  const { t } = useTranslation("billing");
  return (
    <Box padding={3}>
      <Box marginBottom={2}>
        <Typography variant="h4">{t("userInfo")}</Typography>
      </Box>
      <BillingInfo data={data}/>
    </Box>
  );
};

export default CustomerBillingInfo;
