export const parseInteger = (value?: string | null): string => {
  return value ? value.replace(/\D/g, '') : '';
};


export const parseValue = (value?: number | null): string => {
  if (value === null || value === undefined || Number.isNaN(value)) return '';
  return value.toString();
};


const patternInteger = /^[-+]?[1-9]\d|0*$/;
const regexInteger = new RegExp(patternInteger);


export const isValid = (internalValue: string): boolean => regexInteger.test(internalValue) || internalValue === '';


export const parseInternalValue = (internalValue: string): number | null => {
  const parsed = parseInt(internalValue, 10);
  if (parsed === null || parsed === undefined || Number.isNaN(parsed)) return null;

  return parsed;
};
