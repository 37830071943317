import { Typography } from "@material-ui/core";
import Box from "@mui/material/Box";
import LoadingBox from "./LoadingBox";

interface Props {
  label: string;
}
const LoadingBoxWithMessage = ({ label }: Props) => {
  return (
    <Box alignSelf="center" display="flex" flexDirection="column" gap={2} alignItems='center'>
      <Typography variant="h3">{label}</Typography>
      <LoadingBox />
    </Box>
  );
};
export default LoadingBoxWithMessage;
