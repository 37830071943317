import { TableCell, TableRow } from "@material-ui/core";
import { PartFragment } from "Apollo/graphql";
import LocalizedNumber from "Tools/localization/LocalizedNumber";
import { useTranslation } from "react-i18next";

interface Props {
  parts: PartFragment[];
  shippingPrice?: number | null;
}

const TotalRow = ({ parts, shippingPrice }: Props): JSX.Element => {
  const { t } = useTranslation();
  const totalPrice = parts.reduce((acc, part) => {
    return acc + part.amount * (part.price || 0);
  }, 0);
  const totalPriceWithShipping =
    Math.round((totalPrice + (shippingPrice || 0)) * 100) / 100;
  const totalPriceWithDPH = Math.ceil(totalPriceWithShipping * 1.21);
  return (
    <TableRow>
      <TableCell style={{ fontWeight: "bold" }}>
        {t("order:table.total")}
      </TableCell>
      <TableCell />
      <TableCell style={{ fontWeight: "bold" }}>
        <LocalizedNumber value={totalPriceWithShipping} postfix=" Kč"/> 
        <br />
        <LocalizedNumber value={totalPriceWithDPH} postfix=" Kč (včetně DPH)"/>
      </TableCell>
    </TableRow>
  );
};

export default TotalRow;
