import { FORM_VALIDATORS, } from 'Components/Form';
import * as Yup from 'yup';


export const initChangeEmailFormValues = {
  password: '',
  newEmail: '',
  newEmailCheck: '',
};


export const changeEmailFormValidation = Yup.object().shape({
  password: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED),
  newEmail: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .email(FORM_VALIDATORS.EMAIL)
    .max(256, FORM_VALIDATORS.STRING_MAX),
  newEmailCheck: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .email(FORM_VALIDATORS.EMAIL)
    .max(256, FORM_VALIDATORS.STRING_MAX)
    .oneOf([ Yup.ref('newEmail'), null, ], FORM_VALIDATORS.REF_EMAIL_MATCH),
});
