import { Typography } from "@material-ui/core";

interface Props {
    label: string;
    value: string;
}
const UneditableText = ({label,value}: Props): JSX.Element => {
    return <div><Typography color="primary">{label}</Typography><Typography>{value}</Typography></div>
}

export default UneditableText
