import { ReactNode, } from 'react';
import { makeStyles, createStyles, } from '@material-ui/core/styles';

import Header from 'Components/Header';


const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    marginLeft: 0,
    flexGrow: 1,
    paddingTop: '8em',
  },
}));


type Props = {
  children?: ReactNode
};


const Layout = ({ children, }: Props): JSX.Element => {
  const classes = useStyles();


  return (
    <div className={classes.root}>

      <Header />

      <main className={classes.main}>
        {children}
      </main>

    </div>
  );
};


export default Layout;
