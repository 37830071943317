import { BrowserRouter as Router, } from 'react-router-dom';
import { ApolloProvider, } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, StylesProvider, } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SnackbarProvider, } from 'notistack';

import { apolloClient, } from 'Apollo';
import theme from 'Utils/theme';
import GlobalStyles from 'Utils/GlobalStyles';
import MainCore from 'Modules/Main/MainCore';

import csLocale from 'date-fns/locale/cs';
import './i18n/config';
import { AppEventProvider, } from 'Tools/appEvent';
import { ConfirmModalProvider, } from 'Tools/confirmModal';
import moment from 'moment';
import 'moment/locale/cs'
import { useLocale } from 'Tools/localization/hooks/useLocale';

const App = (): JSX.Element => {
  const locale = useLocale()
  moment.locale(locale.key);
  return <MuiThemeProvider theme={theme}>
    <StylesProvider injectFirst>

      <CssBaseline />
      <GlobalStyles />

      <AppEventProvider>
        <ConfirmModalProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <ApolloProvider client={apolloClient}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={csLocale}>
                <Router>
                  <MainCore />
                </Router>
              </MuiPickersUtilsProvider>
            </ApolloProvider>
          </SnackbarProvider>
        </ConfirmModalProvider>
      </AppEventProvider>
    </StylesProvider>
  </MuiThemeProvider>
}


export default App;
