import OrderProcessBox from "Components/OrderProcessBox";
import BillingForm from "./BillingForm";

const BillingPhase = (): JSX.Element => {

  return (
    <OrderProcessBox>
      <BillingForm />
    </OrderProcessBox>
  );
};
export default BillingPhase;
