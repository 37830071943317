import { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import Button from '@material-ui/core/Button';

import { PartError, PartErrorProps, } from 'Components/Parts';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';


interface Props extends PartErrorProps {
  onClose: () => void,
  label?: ReactNode,
}


const ModalError: React.FC<Props> = ({
  onClose,
  label,
  ...rest
}: Props) => {
  const { t, } = useTranslation();
  
  
  return (
    <>

      <ModalBody>
        <PartError {...rest} />
      </ModalBody>

      <ModalFooter>
        <Button
          variant="outlined"
          color="default"
          onMouseDown={onClose}
          size="medium"
        >
          {label || t('common.action.close')}
        </Button>
      </ModalFooter>

    </>
  );
};


export default ModalError;
