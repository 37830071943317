export const APP_EVENTS = {
  CHECK_PART: 'checkPart',
  SPACE_LIST: 'spaceList',
  USER_LIST: 'userList',
  WEBINAR_TERMS_LIST: 'webinarTermsList',
  WEBINAR_LIST: 'webinarList',
  WEBINAR_TERM_USERS_LIST: 'webinarTermUsersList',
  COURSE_LIST: 'courseList',
  COURSE_ORGANIZATION_LIST: 'courseOrganizationList',
};
