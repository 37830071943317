import Table from '@material-ui/core/Table';
import { QueryResult, } from '@apollo/client';

import { User, } from 'Tools/auth';
import { SpacesQuery, } from 'Apollo/graphql';
import Pagination from 'Components/Pagination';
import { ChangeSpacesFilter, SpacesFilter, } from '../../../store/useSpacesStore';
import TableHead from './TableHead';
import TableBody from './TableBody';


interface Props {
  user: User | null,
  spacesQuery: QueryResult<SpacesQuery>
  filter: SpacesFilter,
  onChangeFilter: ChangeSpacesFilter,
}

const TableLayout = ({
  user,
  filter,
  spacesQuery,
  onChangeFilter,
}: Props): JSX.Element => {
  return (
    <>

      <Table className="mb-2">
        <TableHead
          filter={filter}
          onChangeFilter={onChangeFilter}
        />
        <TableBody
          user={user}
          filter={filter}
          spacesQuery={spacesQuery}
        />
      </Table>

      <Pagination
        className="mb-2"
        inputName="offset"
        limit={filter.limit}
        offset={filter.offset}
        count={spacesQuery.data?.spaces.pagination?.count}
        onChange={onChangeFilter}
      />

    </>
  );
};


export default TableLayout;
