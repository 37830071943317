import { Button, Typography, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

function TutorialStep({ step, handleNext, handleBack }) {
    let history = useHistory();

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                        <Grid item xs={12} sm={10} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>1. Krok: V menu najděte položku s názvem říká "Vytvořit objednávku". Tím se dostanete do nové sekce pro zadání objednávky. Na toto tlačítko klikněte, pokud chcete objednat nááhradní díly. Doporučujeme však vyčkat na konec tutoriálu a všechny instrukce a budete tam přesměrování přímo.</Typography>
                            <Button variant="contained" onClick={handleNext} style={{ marginTop: '5vh' }}>Další</Button>
                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                        <Grid item xs={12} sm={10} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>2. Krok: Teď přichází na řadu zadávání čísla dílu. Do prvního pole vepište číslo dílu, který potřebujete. Velmi důležité je používat pouze originální čísla dílů (OE čísla). To zajistí, že nedojde k chybě a objednáte si přesně to, co potřebujete. Pamatujte, všechny díly, co od nás dostanete, jsou originální, takže nemusíte mít obavu z jejich kvality.</Typography>
                            <Button variant="contained" onClick={handleBack} style={{ marginTop: '5vh', marginRight: '2vh' }}>Zpět</Button>
                            <Button variant="contained" color="primary" onClick={handleNext} style={{ marginTop: '5vh' }}>Další</Button>
                        </Grid>
                    </Grid>
                );
            case 2:
                return (
                    <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                        <Grid item xs={12} sm={10} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>3. Krok: Po zadání čísla dílu si vyberte, kolik kusů tohoto dílu chcete. Jednoduše napište počet do příslušného pole.</Typography>
                            <Button variant="contained" onClick={handleBack} style={{ marginTop: '5vh', marginRight: '2vh' }}>Zpět</Button>
                            <Button variant="contained" color="primary" onClick={handleNext} style={{ marginTop: '5vh' }}>Další</Button>
                            </Grid>
                    </Grid>
                );
            case 3:
                return (
                    <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                        <Grid item xs={12} sm={10} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>4. Krok: Když máte všechna potřebná čísla dílů, přidejte je všechny do košíku. Tím zajistíte, že máte v objednávce všechny díly, které potřebujete objednat. Poté vyberte značku originálního dílu. Je třeba myslet na to že u Mercedes a BMW náhradních dílů je nutné objednávat vždy separátně.</Typography>
                            <Button variant="contained" onClick={handleBack} style={{ marginTop: '5vh', marginRight: '2vh' }}>Zpět</Button>
                            <Button variant="contained" color="primary" onClick={handleNext} style={{ marginTop: '5vh' }}>Další</Button>
                            </Grid>
                    </Grid>
                );
            case 4:
                return (
                    <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                        <Grid item xs={12} sm={10} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>5. Krok: Po přidání všech dílů klikněte na tlačítko "Zkontrolovat výběr". Počkejte chvíli a uvidíte celkovou cenu objednávky bez DPH, včetně ceny za dopravu po České republice (v případě objednání do sousedních zemí uvidíte v dalším kroku cenu za dopravu navíc), a také informaci, kdy nejpozději můžete očekávat doručení dílů.</Typography>
                            <Button variant="contained" onClick={handleBack} style={{ marginTop: '5vh', marginRight: '2vh' }}>Zpět</Button>
                            <Button variant="contained" color="primary" onClick={handleNext} style={{ marginTop: '5vh' }}>Další</Button>
                        </Grid>
                    </Grid>
                );
            case 5:
                return (
                    <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                        <Grid item xs={12} sm={10} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>6. Krok: Teď jste připraveni na finální objednávku. Vyplňte vaše iniciály společnosti a adresu, kam má být zboží doručeno.</Typography>
                            <Button variant="contained" onClick={handleBack} style={{ marginTop: '5vh', marginRight: '2vh' }}>Zpět</Button>
                            <Button variant="contained" color="primary" onClick={handleNext} style={{ marginTop: '5vh' }}>Další</Button>
                        </Grid>
                    </Grid>
                );
            case 6:
                return (
                    <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                        <Grid item xs={12} sm={10} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>7. Krok: Následuje platba. Můžete platit přes platební bránu, což je rychlý a snadný způsob. Použít můžete kreditní kartu, Google Pay nebo Apple Pay. Pokud byste raději platili bankovním převodem, kontaktujte naši podporu a domluvíme se na detailech. Platba přes platební bránu je ale preferovaná, protože je to rychlejší a všechno máme plně zautomatizované.</Typography>
                            <Button variant="contained" onClick={handleBack} style={{ marginTop: '5vh', marginRight: '2vh' }}>Zpět</Button>
                            <Button variant="contained" color="primary" onClick={handleNext} style={{ marginTop: '5vh' }}>Další</Button>
                        </Grid>
                    </Grid>
                );
            case 7:
                return (
                    <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                        <Grid item xs={12} sm={10} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>8. Krok: O průběhu vaší objednávky vás budeme informovat e-mailem, takže budete mít přehled o všem důležitém.</Typography>
                            <Button variant="contained" color="primary" onClick={() => history.push("/order")} style={{ marginTop: '5vh' }}>Nová objednávka</Button>
                        </Grid>
                    </Grid>
                );
            default:
                return (
                    <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>Neznámý krok</Typography>
                        </Grid>
                    </Grid>
                );
        }
    };

    return renderStepContent(step);
}

export default TutorialStep;
