import { UserInput, UserRoleEnum, } from 'Apollo/graphql';
import { User, } from 'Tools/auth';
import { UserForm, } from '../types';


export const userInputVariables = (values: UserForm, user?: User | null): UserInput => {
  const spaceId = user?.permissions.USERS_MANAGE ? values.space?.id : user?.space?.id;

  return {
    email: values.email,
    spaceId: spaceId || '',
    role: values.role?.id as UserRoleEnum,
  };
};
