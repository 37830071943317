import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

export default responsiveFontSizes(createMuiTheme({
  palette: {
    primary: {
      main: '#0052CC', // A vibrant, tech-friendly blue, ideal for a futuristic automotive theme
    },
    secondary: {
      main: '#455A64', // A solid, reliable gray for secondary elements, complementing the primary blue
    },
    background: {
      default: '#f4f6f8', // A light, airy background to keep the interface bright and inviting
      paper: '#ffffff', // Pure white for cards and surfaces to ensure content stands out
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '2.8rem',
      lineHeight: 1.1,
      letterSpacing: '0.00735em',
      color: '#0D47A1', // Using a darker shade of blue for headings to add depth
    },
    h2: {
      fontWeight: 500,
      fontSize: '2.4rem',
      lineHeight: 1.3,
      letterSpacing: '0.00735em',
    },
    // Adjust other headings and text styles for clarity and modernity
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 24, // More pronounced rounded corners for a modern, friendly look
        fontWeight: 500,
        color: 'white',
        backgroundColor: '455A64',
        boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
        transition: 'transform 0.1s ease-in-out', // Smooth transform effect for interactivity
        '&:hover': {
          transform: 'scale(1.05)',
        },
      },
      containedPrimary: {
        backgroundColor: '#455A64',
        color: 'white',
        '&:hover': {
          backgroundColor: '#65737e', // A lighter shade on hover for a dynamic effect
        },
      },
      containedSecondary: {
        backgroundColor: '#455A64',
        color: 'white',
        '&:hover': {
          backgroundColor: '#65737e', // A lighter shade on hover
        },
      },
    },
    // Enhance other components with futuristic styles as needed
  },
  props: {
    MuiButton: {
      disableElevation: true, // Flat design, complementing the futuristic aesthetic
    },
    // Define other component props to ensure a cohesive and user-friendly experience
  },
}));