import { useCallback, useState, } from 'react';
import { useApolloClient, } from '@apollo/client';
import { Formik, FormikHelpers, } from 'formik';

import { ForgottenPasswordDocument, ForgottenPasswordMutation, ForgottenPasswordMutationVariables, } from 'Apollo/graphql';
import { initForgotPasswordFormValues, validationSchemeForgotPasswordForm, } from './constants';
import { ForgottenPasswordFormValues, } from './types';
import View from './View';


const ForgottenPasswordPage = (): JSX.Element => {
  const apolloClient = useApolloClient();

  const [ isSent, setSent, ] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (
      values: ForgottenPasswordFormValues,
      { setStatus, }: FormikHelpers<ForgottenPasswordFormValues>,
    ) => {
      try {
        setStatus(undefined);
        await apolloClient.mutate<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>({
          mutation: ForgottenPasswordDocument,
          variables: {
            email: values.email,
          },
        });
        setSent(true);
      } catch (error) {
        setStatus({ error, });
      }
    },
    [ apolloClient, ],
  );


  return (
    <Formik
      initialValues={initForgotPasswordFormValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchemeForgotPasswordForm}
    >
      <View
        isSent={isSent}
      />
    </Formik>
  );
};


export default ForgottenPasswordPage;
