import { useCallback, } from 'react';
import { useApolloClient, MutationOptions, } from '@apollo/client';

import {
  RegisterUserDocument,
  RegisterUserMutation,
  MutationRegisterUserArgs,
  CurrentUserDocument,
  CurrentUserQueryVariables,
  CurrentUserQuery,
} from 'Apollo/graphql';
import {
  removeAccessToken,
  setAccessToken,
  AuthStore,
  useAuthStore,
} from '../store/authStore';

type Register = (options: Omit<MutationOptions<RegisterUserMutation, MutationRegisterUserArgs>, 'mutation' | 'fetchPolicy'>) => Promise<void>;

const selectorSetUser = (state: AuthStore) => state.setUser;

export const useRegister = (): Register => {
  const setUser = useAuthStore(selectorSetUser);
  const apolloClient = useApolloClient();

  const register = useCallback(
    async (options = {}) => {
      try {

        const responseRegister = await apolloClient.mutate<RegisterUserMutation, MutationRegisterUserArgs>({
          ...options,
          mutation: RegisterUserDocument,
          fetchPolicy: 'no-cache',
        });

      } catch (error) {
        removeAccessToken();
        setUser(null);
        throw error;
      }
    },
    [ apolloClient, setUser, ],
  );


  return register;
};
