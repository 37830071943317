
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stepper, Step, StepLabel, StepIcon, Container } from '@mui/material';
// Import ikon
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import AddBoxIcon from '@mui/icons-material/AddBox';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import EditIcon from '@mui/icons-material/Edit';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export function ResponsiveStepper({ steps, activeStep }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Přiřazení ikony ke kroku dle jeho názvu nebo účelu
  const stepIcons = {
    0: <AddShoppingCartIcon />,
    1: <KeyboardIcon />,
    2: <PlusOneIcon />,
    3: <AddBoxIcon />,
    4: <FactCheckIcon />,
    5: <EditIcon />,
    6: <PaymentIcon />,
    7: <CheckCircleIcon />,
  };

  return (
    <Container>
      <Stepper activeStep={activeStep} orientation={isSmallScreen ? "vertical" : "horizontal"} alternativeLabel={!isSmallScreen}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              // Přidání ikony ke StepLabel
              StepIconComponent={() => activeStep > index ? <CheckCircleIcon /> : stepIcons[index] || null}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Container>
  );
}
