export enum LocaleEnum {
  cs = 'cs',
};


export enum CurrencyEnum {
  CZK = 'CZK',
};


export type Currency = {
  code: CurrencyEnum,
  symbol: string,
  format: (value: string) => string,
};


export type Currencies = {
  [key in CurrencyEnum]: Currency
};


export type Locale = {
  key: LocaleEnum,
  code: string,
  label: string,
  currency: Currency,
};


export type Locales = {
  [key in LocaleEnum]: Locale
};
