import React from 'react';
import { createStyles, Theme, makeStyles, } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography, { TypographyProps, } from '@material-ui/core/Typography';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    paddingRight: 64,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));


interface Props {
  typographyProps?: TypographyProps,
  children: React.ReactNode,
  onClose?: () => void,
}


const ModalHeader: React.FC<Props> = ({
  typographyProps,
  children,
  onClose,
}: Props) => {
  // classes
  const classes = useStyles();


  return (
    <DialogTitle
      disableTypography
      className={classes.root}
    >

      <Typography
        variant="h6"
        {...typographyProps}
      >
        {children}
      </Typography>

      {onClose ? (
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}

    </DialogTitle>
  );
};


export default ModalHeader;
