import { OrderEnum, } from 'Apollo/graphql';


export const getSortLabelDirection = (
  isActive: boolean,
  sortOrder: OrderEnum,
): 'asc' | 'desc' => {
  if (isActive) return sortOrder === OrderEnum.Asc ? 'asc' : 'desc';
  return 'asc';
};
