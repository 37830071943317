import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TableHead } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { CustomerTableOrderFragment, } from "Apollo/graphql";
import { useTranslation } from "react-i18next";

interface Props {
  order: CustomerTableOrderFragment;
}
const OrderDetails = ({ order }: Props) => {
  const { t } = useTranslation("part");
  const { parts } = order
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{t("order:orders.parts")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell>{t("order:table.partNumber")}</TableCell>
            <TableCell>{t("order:table.amount")}</TableCell>
            <TableCell>{t("order:table.price")}</TableCell>
          </TableRow>
        </TableHead>
          <TableBody>
            {parts.map((part) => (
              <TableRow key={part.id}>
                <TableCell>{part.number}</TableCell>
                <TableCell>{part.amount}</TableCell>
                <TableCell>{part.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderDetails;
