import { useSpacesQuery, } from 'Apollo/graphql';
import { APP_EVENTS, useListenAppEvent, } from 'Tools/appEvent';
import { User, } from 'Tools/auth';
import { SpacesStore, useSpacesStore, } from '../../../store/useSpacesStore';
import TableLayout from './TableLayout';
import ModalSpaceExport from '../../../modals/ModalSpaceExport';


interface Props {
  user: User | null,
}


const selectorFilter = (store: SpacesStore) => (store.filter);
const selectorChangeFilter = (store: SpacesStore) => (store.changeFilter);


const SpacesTable = ({
  user,
}: Props): JSX.Element => {
  const filter = useSpacesStore(selectorFilter);
  const changeFilter = useSpacesStore(selectorChangeFilter);

  const spacesQuery = useSpacesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      spacesInput: {
        filter: {
          name: filter.name ? filter.name : undefined,
          active: filter.active?.value,
          approved: filter.approved?.value,
        },
        sort: {
          by: filter.sort.by,
          order: filter.sort.order,
        },
      },
      paginationInput: {
        limit: filter.limit,
        offset: filter.offset,
      },
    },
  });


  useListenAppEvent(
    APP_EVENTS.SPACE_LIST,
    spacesQuery.refetch
  );


  return (
    <>
      <ModalSpaceExport
        spacesQuery={spacesQuery}
      />
      <TableLayout
        user={user}
        filter={filter}
        spacesQuery={spacesQuery}
        onChangeFilter={changeFilter}
      />
    </>

  );
};


export default SpacesTable;
