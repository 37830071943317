import { BrandEnum } from 'Apollo/graphql';
import { useCallback, } from 'react';

const getOptionFieldId = <T extends { id: string, },>(o: T): string => o.id;
const areOptionsEqualById = <T extends { id: string | boolean, },>(o: T, v: T): boolean => v.id === o.id;

const MERCEDES_OPTIONS = [
    {id:BrandEnum.MercedesSmart,
    label:"Mercedes-Benz & Smart",}
]
const BMW_OPTIONS = [
    {id:BrandEnum.Bmw,
      label:"BMW",},  
]
const OTHER_BRAND_OPTIONS = [
    // {id:BrandEnum.ChryslerJeepHummer,
    // label:"Chrysler,Jeep,Hummmer"},
    // {id:BrandEnum.Daihatsu,
    // label:"Daithatsu"},
    // {id:BrandEnum.Fca,
    // label:"FCA"},
    // {id:BrandEnum.Ferrari,
    // label:"Ferrari"},
    // {id:BrandEnum.FiatAlfaRomeoDodgeLancia,
    // label:"Fiat, Alfa Romeo, Dodge, Lancia"},
    // {id:BrandEnum.Fisker,
    // label:"Fisker"},
    // {id:BrandEnum.Ford,
    // label:"Ford"},
    // {id:BrandEnum.Honda,
    // label:"Honda"},
    // {id:BrandEnum.Hyundai,
    // label:"Hyundai"},
    // {id:BrandEnum.Isuzu,
    // label:"Isuzu"},
    // {id:BrandEnum.Jaguar,
    // label:"Jaguar"},
    // {id:BrandEnum.Kia,
    // label:"Kia"},
    // {id:BrandEnum.Lada,
    // label:"Lada"},
    // {id:BrandEnum.LandRover,
    // label:"LandRover"},
    // {id:BrandEnum.Maserati,
    // label:"Maserati"},
    // {id:BrandEnum.Mazda,
    // label:"Mazda"},
    // {id:BrandEnum.Mitsubishi,
    // label:"Mitsubishi"},
    // {id:BrandEnum.NissanInfinity,
    // label:"NissanInfinity"},
    // {id:BrandEnum.PeugeotCitroenChevroletOpel,
    // label:"Peugeot, Citroen, Chevrolet, Opel"},
    // {id:BrandEnum.Proton,
    // label:"Proton"},
    // {id:BrandEnum.RenaultDacia,
    // label:"Renault, Dacia"},
    // {id:BrandEnum.Rover,
    // label:"Rover"},
    // {id:BrandEnum.Saab,
    // label:"SAAB"},
    // {id:BrandEnum.Ssangyong,
    // label:"Ssangyong"},
    // {id:BrandEnum.Subaru,
    // label:"Subaru"},
    // {id:BrandEnum.Suzuki,
    // label:"Suzuki"},
    // {id:BrandEnum.ToyotaLexus,
    // label:"Toyota, Lexus"},
    // {id:BrandEnum.VolkswagenAudiSeatSkoda,
    // label:"Volkswagen, Audi, Seat, Skoda"},
    // {id:BrandEnum.Volvo,
    // label:"Volvo"},
    {id:BrandEnum.Other,
    label:"Ostatní značky"},
    // {id:BrandEnum.Ate,
    // label:"Ate"},
    // {id:BrandEnum.Airmatic,
    // label:"Airmatic"},
    // {id:BrandEnum.BeTurbo,
    // label:"BeTurbo"},
    // {id:BrandEnum.Banner,
    // label:"Banner"},
    // {id:BrandEnum.Bosal,
    // label:"Bosal"},
    // {id:BrandEnum.Bosch,
    // label:"Bosch"},
    // {id:BrandEnum.Continental,
    // label:"Continental"},
    // {id:BrandEnum.Contitech,
    // label:"Contitech"},
    // {id:BrandEnum.Diederich,
    // label:"Diederich"},
    // {id:BrandEnum.Eibach,
    // label:"Eibach"},
    // {id:BrandEnum.Elring,
    // label:"Elring"},
    // {id:BrandEnum.Ernst,
    // label:"Ernst"},
    // {id:BrandEnum.Fag,
    // label:"Fag"},
    // {id:BrandEnum.Fte,
    // label:"Fte"},
    // {id:BrandEnum.Febi,
    // label:"Febi"},
    // {id:BrandEnum.Huf,
    // label:"Huf"},
    // {id:BrandEnum.Hella,
    // label:"Hella"},
    // {id:BrandEnum.HerthBuss,
    // label:"HerthBuss"},
    // {id:BrandEnum.Hitachi,
    // label:"Hitachi"},
    // {id:BrandEnum.Ina,
    // label:"Ina"},
    // {id:BrandEnum.Jakoparts,
    // label:"Jakoparts"},
    // {id:BrandEnum.Luk,
    // label:"Luk"},
    // {id:BrandEnum.Lemfoerder,
    // label:"Lemfoerder"},
    // {id:BrandEnum.Mann,
    // label:"Mann"},
    // {id:BrandEnum.Marelli,
    // label:"Marelli"},
    // {id:BrandEnum.MeyerMotoren,
    // label:"MeyerMotoren"},
    // {id:BrandEnum.Ngk,
    // label:"Ngk"},
    // {id:BrandEnum.Nrf,
    // label:"Nrf"},
    // {id:BrandEnum.Ntk,
    // label:"Ntk"},
    // {id:BrandEnum.Phillips,
    // label:"Phillips"},
    // {id:BrandEnum.Pierburg,
    // label:"Pierburg"},
    // {id:BrandEnum.Skf,
    // label:"Skf"},
    // {id:BrandEnum.Swf,
    // label:"Swf"},
    // {id:BrandEnum.Sachs,
    // label:"Sachs"},
    // {id:BrandEnum.Sekurit,
    // label:"Sekurit"},
    // {id:BrandEnum.Stabilus,
    // label:"Stabilus"},
    // {id:BrandEnum.Trw,
    // label:"Trw"},
    // {id:BrandEnum.Vdo,
    // label:"Vdo"},
    // {id:BrandEnum.Valeo,
    // label:"Valeo"},
    // {id:BrandEnum.VanWezel,
    // label:"VanWezel"},
    // {id:BrandEnum.Varta,
    // label:"Varta"},
    // {id:BrandEnum.Waeco,
    // label:"Waeco"},
    // {id:BrandEnum.Zf,
    // label:"Zf"},
]
export type BrandOption = {
  id: BrandEnum,
  label: string,
};

type GetOptionLabel = (o: BrandOption) => string;
type GetOptionKey = (o: BrandOption) => string;

type UseOptionsBrands = () => {
  options: BrandOption[],
  mercedesOptions: BrandOption[],
  bmwOptions: BrandOption[],
  otherBrandOptions: BrandOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById, 
};

export const useOptionsBrands: UseOptionsBrands = () => {
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => o.label,
    [],
  );

  return {
    options: [...MERCEDES_OPTIONS,...BMW_OPTIONS,...OTHER_BRAND_OPTIONS],
    mercedesOptions: MERCEDES_OPTIONS,
    bmwOptions: BMW_OPTIONS,
    otherBrandOptions: OTHER_BRAND_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
