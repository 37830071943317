import { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import { makeStyles, createStyles, } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { ImportedUserStatusCodeEnum, } from 'Apollo/graphql';


const DEFAULT_ERROR = 'errors:somethingHappened';

const IMPORT_ERROR_CODES = {
  [ImportedUserStatusCodeEnum.SpaceDoesNotExist]: 'user:importModal.userErrors.spaceDoesNotExist',
  [ImportedUserStatusCodeEnum.UserAlreadyExist]: 'user:importModal.userErrors.userAlreadyExist',
  [ImportedUserStatusCodeEnum.UserInvalidEmail]: 'user:importModal.userErrors.userInvalidEmail',
} as {
  [key in ImportedUserStatusCodeEnum]?: string
};


interface Props {
  icon: ReactNode
  title: ReactNode
  errorCode?: ImportedUserStatusCodeEnum | null,
}


const useStyles = makeStyles(() => createStyles({
  listIcon: {
    minWidth: 40,
  },
  primaryText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const UserListItem = ({
  icon,
  title,
  errorCode,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t, } = useTranslation();

  const secondary = errorCode
    ? t([ IMPORT_ERROR_CODES?.[errorCode] || DEFAULT_ERROR, DEFAULT_ERROR, ])
    : undefined;

  return (
    <ListItem>
      <ListItemIcon className={classes.listIcon}>
        {icon}
      </ListItemIcon>
      <ListItemText
        classes={{
          primary: classes.primaryText,
        }}
        primary={title}
        secondary={secondary}
      />
    </ListItem>
  );
};


export default UserListItem;
