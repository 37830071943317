import React from 'react';
import { Route, Switch, } from 'react-router-dom';

import { useInitUser, } from 'Tools/auth';
import { ROUTES, } from 'Utils/globals';
import AuthRoute from 'Components/AuthRoute';
import MainAppPage from './pages/MainAppPage';
import LoadingPage from './pages/LoadingPage';
import LoginPage from './pages/LoginPage';
import ForgottenPasswordPage from './pages/ForgottenPasswordPage';
import ResetPassword from './pages/ResetPassword';
import RegisterPage from './pages/RegisterPage';


const AppCore: React.FC = () => {
  const loading = useInitUser();


  if (loading) return <LoadingPage />;

  return (
    <Switch>
      <Route exact path={`/${ROUTES.LOGIN}`} component={LoginPage} />
      <Route exact path={`/${ROUTES.REGISTER}`} component={RegisterPage} />
      <Route exact path={`/${ROUTES.FORGOTTEN_PASSWORD}`} component={ForgottenPasswordPage} />
      <Route exact path={`/${ROUTES.RESET_PASSWORD}/:code`} component={ResetPassword} />
      <AuthRoute component={MainAppPage} />
    </Switch>
  );
};


export default AppCore;
