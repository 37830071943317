import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';

import { useUser, } from 'Tools/auth';
import Desktop from './Desktop';
import Mobile from './Mobile';


const useStyles = makeStyles((theme: Theme) => createStyles({
  toolbar: {
    background: `linear-gradient(90deg, ${theme.palette.common.white} 50%, ${theme.palette.primary.main} calc(50% + 1px))`,
  },
}));


const Header = (): JSX.Element => {
  const classes = useStyles();
  const user = useUser();
  

  return (
      <AppBar
        className={classes.toolbar}
        position="fixed"
        color="inherit"
      >

          <Hidden smDown>
            <Desktop user={user} />
          </Hidden>

          <Hidden mdUp>
            <Mobile user={user} />
          </Hidden>

      </AppBar>
  );
};


export default Header;
