import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { AdminTableOrderFragment } from "Apollo/graphql";
import Headers from "./Headers";
import OrderRow from "./OrderRow";
import TablePagination from "@mui/material/TablePagination";
import { useState } from "react";
import { TableCell, TableFooter } from "@mui/material";
import { TableRow } from "@material-ui/core";

interface Props {
  orders: AdminTableOrderFragment[];
  editOrder?: (id: string) => void;
  selectRow?: (id: string) => void;
  rowIsSelected?: (id: string) => boolean;
  showPagination?: boolean;
  showPartDropDown?: boolean;
}
const OrderTable = ({
  orders,
  editOrder,
  selectRow,
  rowIsSelected,
  showPagination,
  showPartDropDown,
}: Props): JSX.Element => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allSelected, setAllSelected] = useState(false)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const toggleAll = (incomingState: boolean) => {
    return orders.map((order) => {
      if (!rowIsSelected || !selectRow) return null
      setAllSelected(incomingState)
      if (rowIsSelected(order.id) !== incomingState) {
        selectRow(order.id)
      }
      return null
    })
  }
  return (
    <TableContainer component={Paper}>
      <Table
        aria-label="simple table"
        width={undefined}
        size="small"
      >
        <Headers
          showCheckbox={!!selectRow && !!rowIsSelected}
          showEdit={!!editOrder}
          toggleAll={toggleAll}
          allSelected={allSelected}
          showPartDropDown={showPartDropDown}
        />
        <TableBody>
          {orders.map((order) => (
            <OrderRow
              key={order.id}
              editOrder={editOrder}
              order={order}
              selectRow={selectRow}
              rowIsSelected={rowIsSelected}
              showPartDropDown={showPartDropDown}
            />
          ))}
        </TableBody>
        {true && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={orders.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default OrderTable;
