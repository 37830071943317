import { useTranslation, } from 'react-i18next';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import { Form, } from 'formik';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

import { GqlErrorCodeEnum, } from 'Apollo/graphql';
import { FormInputText, FormSubmit, FormError, } from 'Components/Form';


const customError = {
  [GqlErrorCodeEnum.AlreadyExist]: {
    key: 'currentUser:settings.changeEmail.errorEmailExists',
  },
  [GqlErrorCodeEnum.BadLoginInput]: {
    key: 'currentUser:settings.changeEmail.errorBadPassword',
  },
};


const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    padding: theme.spacing(2),
    maxWidth: 500,
  },
  inputIcon: {
    pointerEvents: 'none',
  },
  submit: {
    marginTop: theme.spacing(1),
  },
}));


const View = (): JSX.Element => {
  const { t, } = useTranslation();
  const classes = useStyles();


  return (
    <Paper className={classes.paper}>

      <Typography variant="h6" gutterBottom>
        {t('currentUser:settings.changeEmail.title')}
      </Typography>

      <Form>

       <FormInputText
          name="password"
          type="password"
          margin="normal"
          label={t('currentUser:settings.changeEmail.labelPassword')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.inputIcon}>
                <LockOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />

        <FormInputText
          name="newEmail"
          margin="normal"
          label={t('currentUser:settings.changeEmail.labelNewEmail')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.inputIcon}>
                <MailOutlineIcon />
              </InputAdornment>
            ),
          }}
        />

        <FormInputText
          name="newEmailCheck"
          margin="normal"
          label={t('currentUser:settings.changeEmail.labelNewEmailCheck')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.inputIcon}>
                <MailOutlineIcon />
              </InputAdornment>
            ),
          }}
        />

        <Grid container justify="flex-end">
          <Grid item>
            <FormSubmit
              color="primary"
              className={classes.submit}
            >
              {t('currentUser:settings.changeEmail.submit')}
            </FormSubmit>
          </Grid>
        </Grid>

        <FormError
          apolloErrorMessages={customError}
        />

      </Form>

    </Paper>
  );
};


export default View;
