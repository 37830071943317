import BillingPhase from "./BillingPhase";
import BuildPhase from "./BuildPhase";
import CheckOrderPhase from "./CheckOrderPhase";
import CompletePhase from "./CompletePhase";
import PaymentPhase from "./PaymentPhase";
import { useOrderStore } from "./store";
import { OrderStore, Phases } from "./store/types";

const selectStoreData = (s: OrderStore) => ({
    phase: s.phase,
});
  
const Order = (): JSX.Element => {
    const phaseToComponents: {[key in Phases]:JSX.Element} = {
        'build' : <BuildPhase />,
        'billing': <BillingPhase />,
        'payment': <PaymentPhase />,
        'complete': <CompletePhase />,
        'checkOrder': <CheckOrderPhase />
    }
    const { phase } = useOrderStore(selectStoreData);

    return phaseToComponents[phase]
}

export default Order
