import Box from "@mui/material/Box";
import { Dispatch, SetStateAction } from "react";
import PartChip from "./PartChip";
import { ButtonBase, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";

interface Props {
  setPartSelection: Dispatch<SetStateAction<any[]>>;
  partSelection: any[];
}

const PartsList = ({ partSelection, setPartSelection }: Props): JSX.Element => {
  const { t } = useTranslation();
  const removePart = (index: number) => () => {
    setPartSelection(partSelection.filter((_, i) => i !== index));
  };
  const removeAllParts = () => {
    setPartSelection([]);
  };
  const updatePart =
    (index: number) => (partNumber: string, amount: number) => {
      setPartSelection(()=> {
        const existingPartIndex = partSelection.findIndex(
          (part) => part.partNumber === partNumber
        );
        if (existingPartIndex > -1 && existingPartIndex!== index) {
          const partListWithoutIndex = partSelection.filter(
            (_, i) => i !== index
          );
          return partListWithoutIndex.map((part) =>
            part.partNumber === partNumber ? { partNumber, amount: part.amount + amount } : part
          );
        }
        return partSelection.map((part, i) =>
          i === index ? { partNumber, amount } : part
        )}
      );
    };
  if (partSelection.length === 0)
    return (
      <Typography sx={{ color: "red" }} marginTop={2} marginBottom={2}>
        {t("part:noPartsSelected")}
      </Typography>
    );
  return (
    <Box
      display="flex"
      marginTop={2}
      flexWrap={"wrap"}
      gap={1}
      marginBottom={2}
    >
      {partSelection.map((part, index) => (
        <PartChip
          key={part.partNumber}
          handleDelete={removePart(index)}
          handleUpdate={updatePart(index)}
          partAmount={part.amount}
          partNumber={part.partNumber}
        />
      ))}
    <ButtonBase onClick={removeAllParts}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={1}
        sx={{
          borderRadius: 5,
          border: 1,
          width: 'min', // You might want to adjust this to '100%' or a specific width
          borderColor: "darkred",
        }}
      >
        <Typography marginLeft={2} sx={{ color: "darkred" }}>
          {t("part:removeAllParts")}
        </Typography>
        <IconButton sx={{ color: "red" }}>
          <ClearIcon sx={{ color: "red" }}/>
        </IconButton>
      </Box>
    </ButtonBase>
    </Box>
  );
};

export default PartsList;
