import { useTranslation, } from 'react-i18next';
import { Modal, ModalHeader, } from 'Components/Modal';
import { SpacesQueryResult, } from 'Apollo/graphql';
import Logic from './Logic';
import { SpacesStore, useSpacesStore, } from '../../store/useSpacesStore';

const selectorModalData = (store: SpacesStore) => (store.modalExport);
const selectorClose = (store: SpacesStore) => (store.closeModalExport);
const selectorFilter = (store: SpacesStore) => (store.filter);

interface Props {
  spacesQuery: SpacesQueryResult
}

const ModalSpaceExport = ({
  spacesQuery,
}: Props): JSX.Element | null => {
  const { t, } = useTranslation();
  const modalData = useSpacesStore(selectorModalData);
  const close = useSpacesStore(selectorClose);
  const filter = useSpacesStore(selectorFilter);

  if (!modalData.isOpen) return null;

  const actualPageCount = !spacesQuery.loading ? spacesQuery.data?.spaces.list.length : 0;
  const totalCount = !spacesQuery.loading ? spacesQuery.data?.spaces.pagination?.count : 0;

  return (
    <Modal
      open
      maxWidth="sm"
    >
      <ModalHeader onClose={close}>
        {t('space:exportModal.title')}
      </ModalHeader>
      <Logic
        onClose={close}
        filter={filter}
        totalCount={totalCount}
        actualPageCount={actualPageCount}
      />
    </Modal>
  );
};

export default ModalSpaceExport;
