import { ExportUsersMutationVariables, } from 'Apollo/graphql';
import { EXPORT_ITEMS, } from 'Utils/globals';
import { UserFilter, } from '../../store/useUserStore';


export const exportVariables = (page: string, filter: UserFilter): ExportUsersMutationVariables => {
  return {
    usersInput: {
      filter: {
        email: filter.email ? filter.email : undefined,
        active: filter.active?.value,
        role: filter.role?.id,
        spaceId: filter.space?.id,
      },
      sort: {
        by: filter.sort.by,
        order: filter.sort.order,
      },
    },
    paginationInput: page === EXPORT_ITEMS.ALL_PAGES
      ? undefined
      : {
        limit: filter.limit,
        offset: filter.offset,
      },
  };
};
