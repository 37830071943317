import { ApolloError, } from '@apollo/client';

import {
  useSpaceOptionsQuery,
  SpaceOptionFragment,
  SpaceOptionsQuery,
} from 'Apollo/graphql';


const getOptionSelected = (o: SpaceOptionFragment, v: SpaceOptionFragment) => o.id === v.id;
const getOptionLabel = (o: SpaceOptionFragment) => o.name;


type UseSpaceOptions = () => {
  loading: boolean;
  error?: ApolloError;
  data?: SpaceOptionsQuery;
  getOptionLabel: (o: SpaceOptionFragment) => string;
  getOptionSelected: (o: SpaceOptionFragment, v: SpaceOptionFragment) => boolean;
};


export const useSpaceOptions: UseSpaceOptions = () => {
  const { loading, error, data, } = useSpaceOptionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      spacesInput: {
        filter: {},
      },
    },
  });


  return {
    loading,
    error,
    data,
    getOptionLabel,
    getOptionSelected,
  };
};
