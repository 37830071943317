import { useCheckManyPartsAvailabilityMutation } from "Apollo/graphql";
import { OrderStore } from "Modules/Order/store/types";
import { useUser } from "Tools/auth";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOrderStore } from "../../store";
import PartInputForm from "./PartInputForm";
import View from "./View";
import { validationSchemeCheckPartForm } from "./constants";
import { CheckPartFormType, PartPair } from "./types";
import { Box, Typography } from "@mui/material";
import ErrorAlert from "Components/ErrorAlert";

const selectStoreData = (s: OrderStore) => ({
  orderId: s.orderId,
  updatePart: s.updatePart,
  parts: s.parts,
});

const CheckPart = (): JSX.Element => {
  const formikRef = useRef<any>(null);
  const { t } = useTranslation();
  const { orderId, updatePart, parts } = useOrderStore(selectStoreData);
  const user = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [mutation, result] = useCheckManyPartsAvailabilityMutation({
    errorPolicy: "all",
  });
  const [partPairs, setPartPairs] = useState<PartPair[]>([]);

  const handleMutation = async (
    values: CheckPartFormType,
    partPairs: PartPair[]
  ) => {
    try {
      if (values.brand && orderId && user?.space.id) {
        const response = await mutation({
          variables: {
            checkManyPartsAvailabilityInput: {
              brand: values.brand.id,
              orderId,
              partParams: partPairs,
              spaceId: user?.space.id,
            },
          },
        });
        if (response.data?.checkManyPartsAvailability) {
          for (const part of response.data?.checkManyPartsAvailability) {
            updatePart(part);
            enqueueSnackbar(
              t("part:notificationCreated", {
                id: part.number,
              }),
              {
                variant: "success",
              }
            );
          }
        }
      }
    } catch (error) {
      formikRef.current?.setStatus(error);
      console.error(error);
    }
  };

  const handleSubmit = (values: CheckPartFormType) =>
    handleMutation(values, partPairs);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="row"
      justifyContent="center"
      gap={1}
      sx={theme => ({
        borderRadius: 3,
        border: 1,
        backgroundColor: "lightgrey",
        borderColor: "lightgrey",
        marginBottom: theme.spacing(2),
        maxWidth: theme.spacing(80)
      })}
    >
      <Typography
        marginTop={2}
        marginX={2}
        textAlign="center"
        variant="h4"
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            fontSize: theme.spacing(3),
          },
        })}
      >
        {t("part:check")}
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          borderRadius: 3,
          border: 1,
          width: "min",
          margin: 1,
          backgroundColor: "white",
          borderColor: "lightgrey",
        }}
      >
        <PartInputForm
          partSelection={partPairs}
          setPartSelection={setPartPairs}
        />
      </Box>
      <Formik
        initialValues={{ brand: null }}
        onSubmit={handleSubmit}
        validationSchema={validationSchemeCheckPartForm}
        innerRef={formikRef}
      >
        <View
          loading={result.loading}
          parts={parts}
          partSelection={partPairs}
        />
      </Formik>
      <ErrorAlert error={result.error} />
    </Box>
  );
};

export default CheckPart;
