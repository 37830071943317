import { ReactNode, useState, useCallback, } from 'react';

import { ConfirmOptions, Confirm, AppResolve, } from './types';
import ConfirmModalContext from './ConfirmModalContext';
import ConfirmModal from './ConfirmModal';


const DEFAULT_APP_RESOLVE = {
  resolve: null,
};


interface Props {
  children: ReactNode,
}


const ConfirmModalProvider = ({
  children,
}: Props): JSX.Element => {
  const [ modalOptions, setOptions, ] = useState<ConfirmOptions>({});
  const [ appResolve, setAppResolve, ] = useState<AppResolve>(DEFAULT_APP_RESOLVE);

  const confirm = useCallback<Confirm>(
    (options = {}) => new Promise<boolean>((resolve) => {
      setOptions(options);
      setAppResolve({ resolve, });
    }),
    [],
  );

  const handleCancel = useCallback(
    () => {
      appResolve.resolve?.(false);
      setAppResolve(DEFAULT_APP_RESOLVE);
    },
    [ appResolve, ],
  );

  const handleConfirm = useCallback(
    () => {
      appResolve.resolve?.(true);
      setAppResolve(DEFAULT_APP_RESOLVE);
    },
    [ appResolve, ],
  );

  return (
    <>

      <ConfirmModalContext.Provider value={confirm}>
        {children}
      </ConfirmModalContext.Provider>

      <ConfirmModal
        isOpen={!!appResolve.resolve}
        options={modalOptions}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />

    </>
  );
};


export default ConfirmModalProvider;
