import * as Yup from 'yup';
import { FORM_VALIDATORS, } from 'Components/Form';

export const initSpaceForm = {
  name: '',
};

export const validationSchemeSpaceForm = Yup.object().shape({
  name: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .max(256, FORM_VALIDATORS.STRING_MAX),
});
