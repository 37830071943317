import { useTranslation, } from 'react-i18next';
import { Link as RouterLink, } from 'react-router-dom';
import { usePopupState, bindTrigger, bindMenu, } from 'material-ui-popup-state/hooks';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { ROUTES, } from 'Utils/globals';
import { User, } from 'Tools/auth';
import { useLogout, } from 'Tools/auth/hooks/useLogout';


const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    fontSize: '0.75rem',
  },
  buttonText: {
    maxWidth: 185,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textTransform: 'none',
  },
  paper: {
    background: theme.palette.secondary.main,
  },
  menuItem: {
    color: theme.palette.common.white,
    '&$selected': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));


interface Props {
  user: User | null,
}


const UserMenu = ({
  user,
}: Props): JSX.Element | null => {
  const classes = useStyles();
  const { t, } = useTranslation();
  const popupState = usePopupState({ variant: 'popover', popupId: 'menuLogout', });

  const [ logout, logoutData, ] = useLogout();

  const handleClose = () => popupState.close();
  const handleLogout = () => logout();


  if (!user) return null;

  return (
    <>

      <Button
        className={classes.button}
        variant="text"
        endIcon={<AccountCircleIcon />}
        {...bindTrigger(popupState)}
      >
        <span className={classes.buttonText}>{user?.email}</span>
      </Button>

      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        <MenuItem
          className={classes.menuItem}
          component={RouterLink}
          to={`/${ROUTES.CURRENT_USER}`}
          button
          onClick={handleClose}
        >
          {t('common:header.userMenu.profile')}
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          disabled={logoutData.loading}
          onClick={handleLogout}
          button
        >
          {t('common:header.userMenu.logout')}
        </MenuItem>
      </Menu>

    </>
  );
};


export default UserMenu;
