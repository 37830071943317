import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  PrepareDispatchResultFragment,
  useSendOrdersInTopTransMutation,
} from "Apollo/graphql";
import OrderTable from "Components/AdminOrderTable/OrderTable";
import { DispatchOrderStore } from "Modules/ManageDispatchOrders/store/types";
import { openInNewTab } from "Utils/openInNewTab";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

interface Props {
  data: {
    __typename?: "PrepareDispatchForOrdersResult";
  } & PrepareDispatchResultFragment;
  setPhase: DispatchOrderStore["setPhase"];
}
const View = ({ data, setPhase }: Props) => {
  const { t } = useTranslation("order");
  const { enqueueSnackbar } = useSnackbar();
  const [mutation, result] = useSendOrdersInTopTransMutation({
    variables: {
      orderIds: data.validOrders.map((order) => order.id),
    },
    onCompleted(data) {
      enqueueSnackbar(t("dispatch.success"), { variant: "success" });
      if (data.sendOrdersInTopTrans) {
        setPhase("overview");
      }
    },
  });
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={2}
      marginTop={2}
    >
      <Typography
        variant="h3"
        sx={theme => ({
          [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(4)
          }
        })}
      >
        {t("dispatch.titleDispatch")}
      </Typography>
      <Typography variant="h6">
        {t("dispatch.validOrders")}
      </Typography>
      <OrderTable orders={data.validOrders} showPartDropDown={true} />
      {data.invalidOrders.length > 0 && (
        <>
          <Typography marginTop={2} variant="h4">
            {t("dispatch.invalidOrders")}
          </Typography>
          <OrderTable orders={data.validOrders} showPartDropDown={true} />
        </>
      )}
      <Box>
        <Typography>{t("dispatch.printInfo")}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignSelf="flex-end" gap={2}>
        <Button
          variant="contained"
          color="info"
          onClick={() => openInNewTab(data.url)}
        >
          {t("dispatch.pdfLink")}
        </Button>
        <Button
          disabled={result.loading}
          variant="contained"
          color="success"
          onClick={() => mutation()}
        >
          {t("dispatch.dispatch")}
        </Button>
        <Button
        disabled={result.loading}
        variant="contained"
        color="warning"
        onClick={() => setPhase("overview")}
      >
        {t("common:action.back")}
      </Button>

      </Box>
    </Box>
  );
};
export default View;
