import { CSSProperties, } from '@material-ui/core/styles/withStyles';


export const COLS = {
  name: {
    minWidth: 200,
    maxWidth: 200,
  } as CSSProperties,
  CRN: {
    minWidth: 100,
    maxWidth: 100,
  } as CSSProperties,
  contactName: {
    minWidth: 200,
    maxWidth: 200,
  } as CSSProperties,
  email: {
    minWidth: 200,
    maxWidth: 200,
  } as CSSProperties,
  phone: {
    minWidth: 125,
    maxWidth: 125,
  } as CSSProperties,
  status: {
    minWidth: 130,
    maxWidth: 130,
  } as CSSProperties,
  confirmed: {
    minWidth: 140,
    maxWidth: 140,
  } as CSSProperties,
  actions: {
    minWidth: 144,
    maxWidth: 144,
    'boxSizing': 'content-box',
  } as CSSProperties,
};
