import { useTranslation, } from 'react-i18next';
import { DialogContentText, Grid, } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { Modal, ModalFooter, ModalBody, ModalHeader, } from 'Components/Modal';
import { ConfirmOptions, } from './types';


interface Props {
  isOpen: boolean
  options: ConfirmOptions,
  onCancel: () => void,
  onConfirm: () => void,
}


const ConfirmModal = ({
  isOpen,
  options: {
    title,
    description,
    content,
    confirmText,
    cancelText,
    dialogProps = {},
    confirmButtonProps = {},
    cancelButtonProps = {},
  },
  onCancel,
  onConfirm,
}: Props): JSX.Element => {
  const { t, } = useTranslation();


  return (
    <Modal
      fullWidth
      {...dialogProps}
      open={isOpen}
      onClose={onCancel}
    >

      <ModalHeader onClose={onCancel}>
        {title || t('common:confirmModal.title')}
      </ModalHeader>

      <ModalBody>
        {content || <DialogContentText style={{ marginBottom: 0, }}>{description}</DialogContentText>}
      </ModalBody>

      <ModalFooter>
        <Grid container spacing={2} justify="space-between">
          <Grid item>
            <Button
              {...cancelButtonProps}
              variant="outlined"
              onClick={onCancel}
            >
              {cancelText || t('common:confirmModal.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              {...confirmButtonProps}
              onClick={onConfirm}
            >
              {confirmText || t('common:confirmModal.confirm')}
            </Button>
          </Grid>
        </Grid>
      </ModalFooter>

    </Modal>
  );
};


export default ConfirmModal;
