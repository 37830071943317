import { Dispatch, SetStateAction } from "react";

export const moveToReservoir =
  ({
    setReservoirList,
    setScannedList,
  }: {
    setReservoirList: Dispatch<SetStateAction<string[]>>;
    setScannedList: Dispatch<SetStateAction<string[]>>;
  }) =>
  (id: string) => {
    setScannedList((arr) => {
      const index = arr.indexOf(id);

      if (index !== -1) {

        setReservoirList((arr) => [...arr, id]);
        arr.splice(index, 1);
      }
      return arr;
    });
  };

export const moveToScanned =
  ({
    setReservoirList,
    setScannedList,
  }: {
    setReservoirList: Dispatch<SetStateAction<string[]>>;
    setScannedList: Dispatch<SetStateAction<string[]>>;
  }) =>
  (id: string) => {
    setReservoirList((arr) => {
      const index = arr.indexOf(id);

      if (index !== -1) {
        arr.splice(index, 1);
        setScannedList((arr) => [...arr, id]);
      }
      return arr;
    });
  };
