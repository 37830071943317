import { useDispatchOrdersStore } from "Modules/ManageDispatchOrders/store";
import { DispatchOrderStore } from "Modules/ManageDispatchOrders/store/types";
import FetchData from "./FetchData";

const selectStoreData = (s: DispatchOrderStore) => ({
  setOrderList: s.setOrderList,
  orderList: s.orderList,
  setPhase: s.setPhase,
  setOrderSelection: s.setOrderSelection,
});

const OverView = (): JSX.Element => {
  const { setOrderList, orderList,setOrderSelection } =
    useDispatchOrdersStore(selectStoreData);
  return (
    <FetchData
      setOrderList={setOrderList}
      orders={orderList}
      setOrderSelection={setOrderSelection}
    />
  );
};
export default OverView;
