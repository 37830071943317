import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, CardMedia, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useUser, } from 'Tools/auth';

const AboutUs = () => {
  const user = useUser();

  return (
    <Container maxWidth="md">
      <Box my={4} p={3}>
        <Typography variant="h5" gutterBottom>
          Kolego, vítej v origo.parts, kde máme všechny ty špičkové originální díly, co bys mohl potřebovat pro tvoji práci.
        </Typography>
        <Typography paragraph>
          Já, Milan Hasala, spolu s Romanem Almashim, jsme tohle všechno rozjeli, abys nemusel zbytečně dlouho čekat na díly, které se těžko shání.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                sx={{ height: "100%", width: "100%" }}
                component="img"
                image="/mh.png" // Změňte na správnou cestu k obrázku
                alt="Milan Hasala"
              />
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Milan Hasala
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Spoluzakladatel origo.parts a majitel několika společnosti okolo automotive (<a target='_blank' href="https://topdriveclub.com">TopDriveClub</a>, a další). Expert, který se přes 20 let pohybuje v oblasti automotive a poskytuje rychlé, kvalitní a spolehlivé služby.
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <PhoneIcon />
                    </ListItemIcon>
                    <a href="tel:+420731888887"><ListItemText primary="+420 731 888 887" /></a>
                  </ListItem>
                  <ListItem button component="a" target='_blank' href="https://www.linkedin.com/in/milan-hasala">
                    <ListItemIcon>
                      <LinkedInIcon />
                    </ListItemIcon>
                    <ListItemText primary="LinkedIn profil" />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                sx={{ height: "100%", width: "100%" }}
                image="/ra.png" // Změňte na správnou cestu k obrázku
                alt="Roman Almashi"
              />
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Roman Almashi
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Spoluzakladatel origo.parts, spolumajitel několika IT firem a softwarových produktů (<a target='_blank' href="https://trueslav.dev">trueslav</a>, <a href="https://screenmanager.tech" target='_blank'>ScreenManager</a>, a další). Expert ve spojení byznysu a informačních technologií. Zapálený autař a inovátor v oblasti automotive.
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <PhoneIcon />
                    </ListItemIcon>
                    <a href="tel:+420604487886"><ListItemText primary="+420 604 487 886" /></a>
                  </ListItem>
                  <ListItem button component="a" target='_blank' href="https://www.linkedin.com/in/roman-almashi-a39a7ba8">
                    <ListItemIcon>
                      <LinkedInIcon />
                    </ListItemIcon>
                    <ListItemText primary="LinkedIn profil" />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <br/>
        <Typography variant="h5">
          Tak co ti nabízíme?
        </Typography>
        <br/>
        <Typography variant="h6">Rychlost a spolehlivost</Typography>
        <Typography paragraph>
          Máš auto na rampě a chybí ti díl? Žádný stres. Každý díl, co u nás objednáš, ti doručíme kamkoliv po Česku do dvou dnů.
        </Typography>
        <Typography variant="h6">Jistota správného dílu</Typography>
        <Typography paragraph>
          Víme, jak je důležité dostat ten správný díl, abys nemusel nic vracet. Naše systémy jsou nastavený tak, aby ti to vždy sedlo jak ulité. A kdyby náhodou něco nesedělo, dej nám vědět a my to rychle vyřešíme.
        </Typography>
        <Typography variant="h6">Jednoduchost objednávání</Typography>
        <Typography paragraph>
          Nechceš trávit celé dny listováním katalogů? U nás objednáš vše pohodlně a rychle přímo z mobilu. Naše aplikace je tak jednoduchá, že to zvládneš i při kávičce.
        </Typography>
        <Typography variant="h6">Bezpečné a moderní platby</Typography>
        <Typography paragraph>
          Platit u nás můžeš moderně a bezpečně. Ať už máš rád platby kartou, Apple Pay, nebo Google Pay, u nás to frčí všechno. Všechny transakce jsou šifrované a bezpečné, takže o prachy nemusíš mít strach.
        </Typography>
        <br/>
        <Typography paragraph>
          Tak co říkáš, zkusíš to s námi? Chápeme tvoje obavy, ale jsme tady, abychom ti ukázali, že objednávat díly může být jednoduché a bez rizika. Kdykoliv něco potřebuješ, stačí šáhnout po telefonu a zavolat nám na číslo <a href="tel:+420731888887">+420 731 888 887</a>. A jestli máš nějaké dotazy, klidně zavolej nebo napiš do chatu, jsme tu pro tebe.
        </Typography>
        <Typography paragraph>
          Těšíme se na spolupráci! Milan a Roman
        </Typography>
      </Box>
    </Container>
  );
};

export default AboutUs;
