import { Modal, } from 'Components/Modal';
import Logic from './Logic';
import { UserStore, useUserStore, } from '../../store/useUserStore';

const selectorModalData = (store: UserStore) => (store.modalCreate);
const selectorClose = (store: UserStore) => (store.closeModalCreate);

const ModalUserCreate = (): JSX.Element | null => {
  const modalData = useUserStore(selectorModalData);
  const close = useUserStore(selectorClose);

  if (!modalData.isOpen) return null;

  return (
    <Modal
      open
      maxWidth="sm"
    >
      <Logic onClose={close} />
    </Modal>
  );
};


export default ModalUserCreate;
