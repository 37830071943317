import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Part } from "Apollo/graphql";
import { useTranslation } from "react-i18next";

const PartOrderList = ({ partList }: { partList: Part[] }): JSX.Element => {
  const { t } = useTranslation("part");
  return (
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('adminTable.number')}</TableCell>
            <TableCell align="right">{t('adminTable.stickerNumbers')}</TableCell>
            <TableCell align="right">{t('adminTable.weight')}</TableCell>
            {/* <TableCell align="right">{t('adminTable.width')}</TableCell>
            <TableCell align="right">{t('adminTable.length')}</TableCell>
            <TableCell align="right">{t('adminTable.height')}</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {partList.map((part, index, array) => {
            const sumUntilNow = array
              .slice(0, index)
              .reduce((acc, curr) => acc + curr.amount, 0);
            return (
              <TableRow
                key={part.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {part.number}
                </TableCell>
                <TableCell align="right">{`${sumUntilNow + 1} - ${
                  sumUntilNow + part.amount
                }`}</TableCell>
                <TableCell align="right">{part?.weight + ' kg'}</TableCell>
                {/* <TableCell align="right">{part.originalPart?.width + ' cm'}</TableCell>
                <TableCell align="right">{part.originalPart?.length + ' cm'}</TableCell>
                <TableCell align="right">{part.originalPart?.height + ' cm'}</TableCell> */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
  );
};

export default PartOrderList;
