import { useUser, } from 'Tools/auth';
import SpacesListPage from './SpacesListPage';
import ModalSpaceCreate from '../modals/ModalSpaceCreate';
import ModalSpaceEdit from '../modals/ModalSpaceEdit';

const SpaceRouterPage = (): JSX.Element => {
  const user = useUser();


  return (
    <>

      {user?.permissions.SPACE_MANAGE && (
        <>
          <ModalSpaceCreate />
          <ModalSpaceEdit />
        </>
      )}

      <SpacesListPage />

    </>
  );
};

export default SpaceRouterPage;
