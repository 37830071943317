import { Typography } from "@material-ui/core";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { openInNewTab } from "Utils/openInNewTab";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  resetStore: () => void;
  paymentLink: string;
  email: string;
}
const View = ({ paymentLink, resetStore, email }: Props): JSX.Element => {
  const { t } = useTranslation("billing");
  useEffect(() => {
    openInNewTab(paymentLink);
  }, [paymentLink]);
  const onClick = () => {
    openInNewTab(paymentLink);
  };
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h2">{t("completeTitle")}</Typography>
      <Box display="flex" alignContent="center" flexDirection="row" mt={2}>
        <Typography variant="body1">{t("completeInfo", { email })}</Typography>
      </Box>
      <Box display="flex" alignSelf="flex-end" mt={2}>
        <Button variant="contained" color="primary" onClick={onClick}>
          {t("paymentBtn")}
        </Button>
      </Box>
      <Box display="flex" alignSelf="flex-end" mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => resetStore()}
        >
          {t("newOrderBtn")}
        </Button>
      </Box>
    </Box>
  );
};
export default View;
