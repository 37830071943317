import { useGetOrderWithIdQuery } from "Apollo/graphql";
import ErrorMessage from "Components/ErrorMessage";
import LoadingBoxWithMessage from "Components/LoadingBoxWithMessage";
import { useManageOrdersStore } from "Modules/ManageIncomingOrders/store";
import { ManageOrdersStore } from "Modules/ManageIncomingOrders/store/types";
import { useTranslation } from "react-i18next";
import Logic from "./Logic";
import { orderToPartsNumberArray } from "./helpers";
const selectStoreData = (s: ManageOrdersStore) => ({
  activeOrderId: s.activeOrderId,
  setActiveOrder: s.setActiveOrder,
  activeOrder: s.activeOrder,
  setPhase: s.setPhase,
});
const Edit = (): JSX.Element => {
  const { t, } = useTranslation("common")
  const { activeOrderId, setActiveOrder, activeOrder,setPhase } =
    useManageOrdersStore(selectStoreData);
  const { data, loading, error } = useGetOrderWithIdQuery({
    variables: { id: activeOrderId },
    onCompleted(data) {
      if (data.getOrderWithId) {
        setActiveOrder(data.getOrderWithId);
      }
    },
  });
  if (loading) return <LoadingBoxWithMessage label={t('loading.loadingOrder')}/>;
  if (error || !data?.getOrderWithId || !activeOrder)
    return <ErrorMessage apolloError={error} />;
  return <Logic setPhase={setPhase} orderId={activeOrder.id} numberList={orderToPartsNumberArray(activeOrder)} note={activeOrder.note || ""}/>;
};

export default Edit;
