import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { Theme, useTheme } from "@mui/material/styles";
import { useEffect } from "react";

export const StyledBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "100vh",
  flexDirection: "column",
  padding: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    maxWidth: theme.spacing(120),
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down("md")]: {
    width: "auto",
    height: "auto",
    overflowX: "auto",
    overflowY: "auto",
    padding: theme.spacing(1),
    marginX: "auto",
  },
}));
const OrderProcessBox = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const theme = useTheme();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '100px'
      }}
    >
      <StyledBox theme={theme}>{children}</StyledBox>
    </Box>
  );
};
export default OrderProcessBox;
