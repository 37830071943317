import { Form, } from 'formik';
import { useTranslation, } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { SpaceFragment, } from 'Apollo/graphql';
import { ModalFooter, ModalBody, } from 'Components/Modal';
import { FormSubmit, FormError, } from 'Components/Form';
import SpaceForm from 'Modules/Spaces/components/SpaceForm';


interface Props {
  space: SpaceFragment
  onClose: () => void
}


const View = ({
  space,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation();
  return (
    <Form>

      <ModalBody>
        <SpaceForm />
      </ModalBody>

      <ModalFooter>
        <Grid container justify="space-between" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="default"
              onMouseDown={onClose}
            >
              {t('common:action.back')}
            </Button>
          </Grid>
          <Grid item>
            <FormSubmit
              fullWidth
              color="primary"
            >
              {space.approved ? t('common:action.edit') : t('common:action.approve')}
            </FormSubmit>
          </Grid>
        </Grid>
        <FormError />
      </ModalFooter>
    </Form>
  );
};

export default View;
