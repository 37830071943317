import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';

import { OptionBoolean, } from 'Utils/types';


const getOptionSelected = (o: OptionBoolean, v: OptionBoolean) => o.id === v.id;


export const optionsYeasNo: OptionBoolean[] = [
  {
    id: '1',
    value: true,
    label: 'common:activeOptions.active',
  },
  {
    id: '0',
    value: false,
    label: 'common:activeOptions.blocked',
  },
];


type UseOptionsActive = () => {
  options: OptionBoolean[],
  getOptionLabel: (o: OptionBoolean) => any,
  getOptionSelected: (o: OptionBoolean, v: OptionBoolean) => boolean,
};


export const useOptionsActive: UseOptionsActive = () => {
  const { t, } = useTranslation();

  const getOptionLabel = useCallback(
    (o: OptionBoolean) => (t(o.label)),
    [ t, ],
  );


  return {
    options: optionsYeasNo,
    getOptionLabel,
    getOptionSelected,
  };
};
