import { useTranslation, } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import ErrorMessage from 'Components/ErrorMessage';
import { ApolloError, } from '@apollo/client';
import { EXPORT_ITEMS, } from 'Utils/globals';
import { ModalBody, ModalFooter, } from 'Components/Modal';
import RadioOptionWithDescription from 'Components/RadioOptionWithDescription';

interface Props {
  pageToExport: string,
  error?: ApolloError,
  loading: boolean,
  totalCount?: number,
  actualPageCount?: number,
  onClose: () => void,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onSubmit: () => void,

}

const View = ({
  error,
  loading,
  pageToExport,
  totalCount,
  actualPageCount,
  onChange,
  onClose,
  onSubmit,
}: Props): JSX.Element => {
  const { t, } = useTranslation();
  return (
    <>
      <ModalBody>
        <Typography color="textSecondary">{t('space:exportModal.subtitle')}</Typography>
        <FormControl component="fieldset" disabled={loading}>
          <RadioGroup value={pageToExport} onChange={onChange}>
            <RadioOptionWithDescription
              value={EXPORT_ITEMS.CURRENT_PAGE}
              title={t('space:exportModal.titleCurrentPage')}
              description={t('space:exportModal.descriptionCurrentPage', { count: actualPageCount, })}
            />
            <RadioOptionWithDescription
              value={EXPORT_ITEMS.ALL_PAGES}
              title={t('space:exportModal.titleAllPages')}
              description={t('space:exportModal.descriptionAllPages', { count: totalCount, })}
            />
          </RadioGroup>
        </FormControl>
      </ModalBody>

      <ModalFooter>
        <Grid container justify="space-between" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="default"
              onMouseDown={onClose}
            >
              {t('common:action.back')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              onMouseDown={onSubmit}
              disabled={loading}
            >
              {t('space:exportModal.export')}
            </Button>
          </Grid>
        </Grid>
        <ErrorMessage apolloError={error} />
      </ModalFooter>
    </>
  );
};
export default View;
