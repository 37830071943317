import { ApolloError, } from '@apollo/client';
import { GqlErrorCodeEnum, } from 'Apollo/graphql';

import { InputErrorMessage, } from 'Utils/types';


export type AcceptedErrorMessages = {
  [key in GqlErrorCodeEnum]?: InputErrorMessage
};


export const parseApolloError = (
  apolloError: ApolloError,
  messages?: AcceptedErrorMessages,
): InputErrorMessage | null => {
  try {
    if (apolloError.networkError) {
      return {
        key: 'errors:networkError',
      };
    }

    const error = apolloError.graphQLErrors[0];
    const errorCode = error.extensions?.code as GqlErrorCodeEnum;

    return messages?.[errorCode] || null;
  } catch {
    return null;
  }
};
