import View from './View';


interface Props {
  onClose: () => void,
}


const Logic = ({
  onClose,
}: Props): JSX.Element => {


  return (
    <View
      onClose={onClose}
    />
  );
};


export default Logic;
