import create from "zustand";
import { DispatchOrderStore } from "./types";
import { EMPTY_STORE_STATE } from "./utils";
import { orderListToSelection } from "./helpers";

export const useDispatchOrdersStore = create<DispatchOrderStore>((set) => ({
  ...EMPTY_STORE_STATE, // actions
  removeOrders(ids) {
    set((state) => {
      const orderList = state.orderList.map(
        (order) => ({...order,topTransId:undefined})
      );
      return { orderList };
    });
  },
  resetOrderSelection() {
    set((state) => ({ orderSelection: null }));
  },
  toggleOrderSelection(id) {
    set((state) => {
      const orderSelection = { ...state.orderSelection };
      orderSelection[id] = !orderSelection[id];
      return { orderSelection };
    });
  },
  setOrderSelection(orderList) {
    set({ orderSelection: orderListToSelection(orderList) });
  },
  setPagination(pagination) {
    set({ pagination });
  },
  setOrderList(orderList) {
    set({ orderList });
  },
  setPhase(phase) {
    set({ phase });
  },
  resetStore: () => set(() => ({ ...EMPTY_STORE_STATE })),
}));
