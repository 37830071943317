import { useTranslation, } from 'react-i18next';
import { useSnackbar, } from 'notistack';
import MuiTableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import { User, } from 'Tools/auth';
import { APP_EVENTS, useEmitAppEvent, } from 'Tools/appEvent';
import { SpaceTableFragment, useRemoveSpaceMutation, useSetSpaceActiveMutation, } from 'Apollo/graphql';
import { useConfirmModal, } from 'Tools/confirmModal';
import ChipActive from 'Components/ChipActive';
import { COLS, } from './constants';


interface Props {
  user: User | null,
  row: SpaceTableFragment,
  onDetail: (Space: SpaceTableFragment) => void,
}

const TableRow = ({
  user,
  row,
  onDetail,
}: Props): JSX.Element => {
  const { t, } = useTranslation();
  const confirm = useConfirmModal();
  const emitEvent = useEmitAppEvent();
  const { enqueueSnackbar, } = useSnackbar();

  const [ removeMutation, removeMutationData, ] = useRemoveSpaceMutation({
    onCompleted: () => {
      emitEvent?.(APP_EVENTS.SPACE_LIST);
      enqueueSnackbar(t('space:spacesTable.notificationRemoved'), { variant: 'success', });
    },
    onError: () => {
      enqueueSnackbar(t('errors:somethingHappened'), { variant: 'error', });
    },
  });

  const [ setActiveMutation, setActiveMutationData, ] = useSetSpaceActiveMutation({
    onCompleted: () => {
      emitEvent?.(APP_EVENTS.SPACE_LIST);
      enqueueSnackbar(
        row.active
          ? t('space:spacesTable.notificationBlocked')
          : t('space:spacesTable.notificationActivated'),
        { variant: 'success', }
      );
    },
    onError: () => {
      enqueueSnackbar(t('errors:somethingHappened'), { variant: 'error', });
    },
  });

  const handleDetail = () => onDetail(row);

  const handleSetActive = async () => {
    const ret = await confirm?.({
      description: row.active
        ? t('space:spacesTable.confirmBlock', { Space: row.name, })
        : t('space:spacesTable.confirmUnblock', { Space: row.name, }),
    });

    if (ret) {
      setActiveMutation({
        variables: {
          id: row.id,
          active: !row.active,
        },
      });
    }
  };

  const handleRemove = async () => {
    const ret = await confirm?.({
      description: t('space:spacesTable.confirmRemove', { space: row.name, }),
    });

    if (ret) {
      removeMutation({
        variables: { id: row.id, },
      });
    }
  };


  return (
    <MuiTableRow hover>

      <TableCell style={COLS.name}>
        {row.name}
      </TableCell>

      <TableCell className="tableCell-action" style={COLS.status}>
        <ChipActive
          active={row.active}
          activeLabel={t('common:activeOptions.active')}
          activeIcon={<LockOpenIcon />}
          inActiveLabel={t('common:activeOptions.blocked')}
          inActiveIcon={<LockIcon />}
        />
      </TableCell>

      <TableCell className="tableCell-action" style={COLS.confirmed}>
        <ChipActive
          active={row.approved}
          activeLabel={t('common:approvedOptions.approved')}
          inActiveLabel={t('common:approvedOptions.disapproved')}
        />
      </TableCell>

      <TableCell style={COLS.actions} align="right" className="tableCell-action">
        {user?.permissions.SPACE_MANAGE && (
          <Grid container>
            <Grid item>
              <Tooltip title={<span>{row.approved ? t('common:action.detail') : t('common:action.approve')}</span>}>
                <span>
                  <IconButton
                    onClick={handleDetail}
                    color="primary"
                  >
                    {row.approved
                      ? <EditIcon />
                      : <CheckCircleIcon />
                    }
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={<span>{row.active ? t('common:action.block') : t('common:action.unblock')}</span>}>
                <span>
                  <IconButton
                    onClick={handleSetActive}
                    color="primary"
                    disabled={setActiveMutationData.loading}
                  >
                    {row.active
                      ? (<LockIcon />)
                      : (<LockOpenIcon />)
                    }
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={<span>{t('common:action.delete')}</span>}>
                <span>
                  <IconButton
                    onClick={handleRemove}
                    color="primary"
                    disabled={removeMutationData.loading}
                    className="iconButton-error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </TableCell>

    </MuiTableRow>
  );
};


export default TableRow;
