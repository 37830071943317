import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Scanner from "Components/BarcodeScanner/Scanner";
import TransferList from "Modules/ManageIncomingOrders/components/TransferList";
import {
  moveToReservoir,
  moveToScanned,
} from "Modules/ManageIncomingOrders/components/TransferList/helpers";
import { ManageOrdersStore } from "Modules/ManageIncomingOrders/store/types";
import { useEffect, useMemo, useState } from "react";
import SubmitForm from "./SubmitForm";
interface Props {
  numberList: string[];
  note: string;
  orderId: string;
  setPhase: ManageOrdersStore["setPhase"];
}
const Logic = ({ numberList, note, orderId, setPhase }: Props): JSX.Element => {
  const memoizedList = useMemo(
    () => [
      ...numberList,
    ],
    [numberList]
  );
  const [reservoirList, setReservoirList] = useState<string[]>(memoizedList);
  const [scannedList, setScannedList] = useState<string[]>([]);
  const scanNumber = moveToScanned({
    setReservoirList,
    setScannedList,
  });
  const resetNumber = moveToReservoir({
    setReservoirList,
    setScannedList,
  });
  const [detected, onDetected] = useState<string>("");
  const [lastScanTime, setLastScanTime] = useState<number>(Date.now());
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    return () => setIsMounted(false);
  }, []);

  return (
    <Box display="flex" flexDirection="column" padding={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding={2}
      >
        <Scanner
          onDetected={(result: string) => {
            if (!isMounted) return;
            const currentTime = Date.now();
            if ((currentTime - lastScanTime) > 1000) {
              setLastScanTime(()=>Date.now())
              scanNumber(result);
            } 

            onDetected((state) => {
              return result;
            });
          }}
        />
        <Typography variant="body1">{detected}</Typography>
      </Box>
      <TransferList
        resetNumber={resetNumber}
        scanNumber={scanNumber}
        reservoirList={reservoirList}
        scannedList={scannedList}
      />
      <Box display="flex" flexDirection="row" justifyContent="space-evenly">
        <SubmitForm
          note={note}
          reservoirList={reservoirList}
          orderId={orderId}
          setPhase={setPhase}
        />
      </Box>
    </Box>
  );
};

export default Logic;
