import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface Props {
  callback: (id: string) => void;
  id: string;
}
const ItemButton = ({ callback, id }: Props) => {
  return (
    <Button onClick={() => callback(id)}>
      <Typography variant="body1">{id}</Typography>
    </Button>
  );
};
export default ItemButton;
