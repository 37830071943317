import moment from "moment";
import { DateInterval } from "./store/types";

export const getInterval = (date: moment.Moment): DateInterval => {
  const correctedDay = date.clone();
  let intervalStart = correctedDay.clone().hours(0).minutes(0).seconds(0);
  let intervalEnd = intervalStart.clone().add(1, "days");
  // if (intervalEnd.day() === 5) {
  //   intervalEnd = intervalEnd.add(2, "days");
  // }
  // if (intervalEnd.day() === 6) {
  //   intervalEnd = intervalEnd.subtract(1, "days");
  //   intervalStart = intervalStart.add(1, "days");
  // }
  // if (intervalEnd.day() === 0) {
  //   intervalEnd = intervalEnd.subtract(2, "days");
  // }

  return {
    from: intervalStart.toDate(),
    to: intervalEnd.toDate(),
  };
};
export type DayData = {
  name: string;
  date: Date;
};
export const getWeekDays = ({
  date,
}: {
  date: moment.Moment;
}): DayData[] => {
  const startDate = date.clone().startOf("week");
  let days: DayData[] = [];
  for (let i = 0; i < 5; i++) {
    const day = startDate.clone().add(i, "days");
    days.push({
      name: day.format("dddd"),
      date: day.toDate(),
    });
  }
  return days;
};
