import { Box } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { PartFragment } from "Apollo/graphql";
import Headers from "./Headers";
import PartRow from "./PartRow";
import TotalRow from "./TotalRow";
import ShippingPriceRow from "./ShippingPriceRow";

interface Props {
  parts: PartFragment[];
  shippingPrice?: number | null;
}

const PartsDataTable = ({ parts, shippingPrice }: Props): JSX.Element => {
  return (
    <Box width="100%" margin="auto">
      <TableContainer component={Paper}>
        <Table
          sx={{
            maxWidth: "100%",
            tableLayout: "fixed",
            '& th, & td': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minWidth: 60, // Set a reasonable minimum width
            },
            '& tbody tr': {
              '&:last-child td, &:last-child th': {
                border: 0,
              },
            },
          }}
        >
          <Headers />
          <TableBody>
            {parts.map((part) => (
              <PartRow part={part} key={part.id} />
            ))}
            <ShippingPriceRow shippingPrice={shippingPrice ?? 0} />
            <TotalRow parts={parts} shippingPrice={shippingPrice} />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PartsDataTable;
