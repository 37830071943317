import React, { ReactNode, } from 'react';
import {
  makeStyles,
  createStyles,
} from '@material-ui/core/styles';
import CircularProgress, { CircularProgressProps, } from '@material-ui/core/CircularProgress';


export interface PartLoadingProps extends CircularProgressProps {
  minHeight?: string,
  children?: ReactNode,
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme) => createStyles<any, PartLoadingProps>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: (props) => props.minHeight || '4rem',
  },
  children: {
    marginTop: theme.spacing(1),
  },
}));


const PartLoading: React.FC<PartLoadingProps> = (props: PartLoadingProps) => {
  const {
    minHeight,
    children,
    ...rest
  } = props;
  const classes = useStyles(props);


  return (
    <div className={classes.root}>
      <CircularProgress {...rest} />
      {children && (
        <div className={classes.children}>
          {children}
        </div>
      )}
    </div>
  );
};


export default PartLoading;
