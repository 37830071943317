import { useContext, } from 'react';

import AppEventContext from './AppEventContext';


export const useEmitAppEvent = (): ((eventName: string) => void) | undefined => {
  const { emit, } = useContext(AppEventContext);

  return emit;
};
