import {
  AdminTableOrderFragment,
  OrderStatusEnum,
  useGetOrdersQuery,
} from "Apollo/graphql";
import ErrorMessage from "Components/ErrorMessage";
import LoadingBoxWithMessage from "Components/LoadingBoxWithMessage";
import { DispatchOrderStore } from "Modules/ManageDispatchOrders/store/types";
import { useTranslation } from "react-i18next";
import Table from "./Table";

interface Props {
  setOrderList: DispatchOrderStore["setOrderList"];
  orders: AdminTableOrderFragment[];
  setOrderSelection: DispatchOrderStore["setOrderSelection"];
}
const FetchData = ({
  setOrderList,
  orders,
  setOrderSelection,
}: Props): JSX.Element => {
  const { t ,} = useTranslation("common")
  const { data, loading, error } = useGetOrdersQuery({
    variables: {
      filterInput: {
        status: OrderStatusEnum.Reviewed,
      },
      paginationInput: {
        // Todo for all table queries
        limit: 1000,
        offset: 0,
      },
    },
    onCompleted(data) {
      if (data) {
        setOrderList(data.getOrders);
        setOrderSelection(data.getOrders)
      }
    },
  });
  if (loading) return <LoadingBoxWithMessage label={t('loading.loadingOrderTable')} />;
  if (error || !data) return <ErrorMessage apolloError={error} />;
  return <Table orders={orders} />;
};
export default FetchData;
