import { Order, OrderFragment, Part, PartsOnlyOrderFragment } from "Apollo/graphql";

export const orderToPartsNumberArray = (
  order: PartsOnlyOrderFragment | OrderFragment | Order
): string[] => {
  const partNumberArray = (order.parts as any).reduce((acc:string[], part:Part) => {
    const { number, amount } = part;
    for (let i = 0; i < amount; i++) {
      acc.push(number);
    }
    return acc;
  }, []);
  return partNumberArray;
};
