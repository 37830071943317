import React, { useCallback, useState, useRef, } from 'react';
import { useTranslation, } from 'react-i18next';
import { Theme, withStyles, } from '@material-ui/core/styles';
import TextField, { FilledTextFieldProps, } from '@material-ui/core/TextField';

import { useTimeout, } from 'Tools/useTimeout';
import { ChangeFilter, } from '../types';


const CustomTextField = withStyles((t: Theme) => ({
  root: {
    '& input': {
      padding: t.spacing(1),
    },
    '& .MuiFilledInput-root': {
      background: t.palette.common.white,
    },
  },
}))(TextField);


export interface Props extends Omit<FilledTextFieldProps, 'onChange' | 'variant' | 'size' | 'label'> {
  value?: string,
  name: string,
  onChange: ChangeFilter<string>,
}


const FilterText: React.FC<Props> = ({
  value = '',
  name,
  onChange,
  onBlur,
  placeholder,
  ...rest
}: Props) => {
  const { t, } = useTranslation();
  const [ filterValue, setFilterValue, ] = useState<string>(value);
  const [ isFocused, setFocused, ] = useState<boolean>(false);
  const refFilterValue = useRef<string>(value);

  const currentPlaceholder = placeholder || t('common:table.inputs.placeholder');

  const cbTimer = useCallback(
    () => {
      onChange({
        name,
        value: refFilterValue.current,
      });
    },
    [ name, refFilterValue, onChange, ],
  );


  const { startTimeout, endTimeout, } = useTimeout(700, cbTimer);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
    refFilterValue.current = e.target.value;
    startTimeout();
  };
  const handleFocus = () => {
    setFilterValue(value);
    setFocused(true);
  };
  const handleBlur = () => {
    endTimeout();
    setFocused(false);
    onChange({
      name,
      value: refFilterValue.current,
    });
  };


  return (
    <CustomTextField
      {...rest}
      placeholder={currentPlaceholder}
      variant="filled"
      size="small"
      value={isFocused ? filterValue : value}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};


export default FilterText;
