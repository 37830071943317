import { TableCell, TableRow } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import { useTranslation } from "react-i18next";

const Headers = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell>{t("order:table.partNumber")}</TableCell>
        <TableCell>{t("order:table.amount")}</TableCell>
        <TableCell>{t("order:table.price")}</TableCell>
      </TableRow>
    </TableHead>
  );
};
export default Headers;
