import Container from '@material-ui/core/Container';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Tutorial from './Tutorial';


const HomePage = (): JSX.Element => {
  const [played, setPlayed] = useState(false);

  useEffect(() => {
    // Kontrola, zda bylo video již přehráno
    const hasPlayed = localStorage.getItem('videoPlayed');

    if (hasPlayed) {
      setPlayed(true);
    } else {
      setPlayed(false);
    }
  }, []);

  const handleVideoEnd = () => {
    // Nastavit video jako přehráné v localStorage
    localStorage.setItem('videoPlayed', 'true');
    setPlayed(true);
    const video = document.getElementById('background-video');
    video.style.display = 'none';
    const button = document.getElementById('start-button');
    button.style.display = 'none';
  };
  const play = () => {
    const video = document.getElementById('background-video');
    video?.play();
  };
  return (
    <>{!played ? <Container className="container-page">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button variant="contained" id="start-button" onClick={() => play()}>
          Podívat se na tutorial
        </Button>
      </div>
      <div className="video-container">
        <video id="background-video" playsInline={true} onEnded={() => handleVideoEnd()}>
          <source src="intro.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </Container>: <Container className="container-page"><Tutorial /></Container>}
    </>
  );
};

export default HomePage;
