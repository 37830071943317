import { useEffect, } from 'react';
import { Redirect, Route, Switch, } from 'react-router-dom';

import { useUser, } from 'Tools/auth';
import { PermissionEnum, } from 'Apollo/graphql';
import { ROUTES, } from 'Utils/globals';
import { CurrentUserStore, useCurrentUserStore, } from 'Modules/CurrentUser/store/useCurrentUserStore';
import AuthRoute from 'Components/AuthRoute';
import Layout from 'Components/Layout';
import HomePage from 'Modules/Home/pages/HomePage';
import CurrentUserRouter from 'Modules/CurrentUser/pages/CurrentUserRouter';
import UsersRouter from 'Modules/Users/pages/UsersRouter';
import SpacesRouterPage from 'Modules/Spaces/pages/SpaceRouterPage';
import ModalChangePassword from 'Modules/CurrentUser/modals/ModalChangePassword';
import DemandRouterPage from 'Modules/Demand/pages/Demand/DemandRouter';
import DemandDetailRouterPage from 'Modules/Demand/pages/Detail/DemandDetailRouter';
import Demands from 'Modules/Demands';
import Orders from 'Modules/Orders';
import Order from 'Modules/Order';
import ManageIncomingOrders from 'Modules/ManageIncomingOrders';
import ManageDispatchOrders from 'Modules/ManageDispatchOrders';
import AboutUs from 'Modules/AboutUs';


const selectOpenModalChangePassword = (store: CurrentUserStore) => store.openModalChangePassword;


const MainAppPage = (): JSX.Element => {
  const user = useUser();
  const openModalChangePassword = useCurrentUserStore(selectOpenModalChangePassword);


  useEffect(
    () => {
      if (user?.isFirstLogin) openModalChangePassword();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );


  return (
    <Layout>

      <ModalChangePassword />

      <Switch>

        <Route
          path={`/${ROUTES.CURRENT_USER}`}
          component={CurrentUserRouter}
        />

        <AuthRoute
          permission={PermissionEnum.UsersRead}
          path={`/${ROUTES.USERS}`}
          component={UsersRouter}
        />

        <AuthRoute
          permission={PermissionEnum.SpaceManage}
          path={`/${ROUTES.CUSTOMERS}`}
          component={SpacesRouterPage}
        />

        <AuthRoute
          permission={PermissionEnum.PartsOrder}
          path={`/${ROUTES.ORDERS}`}
          component={Orders}
        />
        
        <AuthRoute
          path={`/${ROUTES.ABOUT_US}`}
          component={AboutUs}
        />

        <AuthRoute
          permission={PermissionEnum.PartsManage}
          path={`/${ROUTES.ORDER}`}
          component={Order}
        />

        <AuthRoute
          permission={PermissionEnum.ManageOrders}
          path={`/${ROUTES.MANAGE_INCOMING_ORDERS}`}
          component={ManageIncomingOrders}
        />
                <AuthRoute
          permission={PermissionEnum.ManageOrders}
          path={`/${ROUTES.MANAGE_DISPATCH_ORDERS}`}
          component={ManageDispatchOrders}
        />
        <AuthRoute
          permission={PermissionEnum.PartsManage}
          path={`/${ROUTES.DEMANDS}/:id/${ROUTES.DETAIL}`}
          component={DemandDetailRouterPage}
        />

        <Route exact path="/" component={HomePage} />

        <Redirect to="/" />

      </Switch>
    </Layout>
  );
};


export default MainAppPage
