import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
} from '@apollo/client';
import { TokenRefreshLink, } from 'apollo-link-token-refresh';
import { createUploadLink, } from 'apollo-upload-client';

import {
  getAccessToken,
  isAccessTokenValidOrUndefined,
} from 'Tools/auth';
import {
  fetchRefresh,
  handleRefreshCompleted,
  handleRefreshError,
  RefreshLinkResult,
} from './utils';


const createApolloClient = () => {
  // http link
  const httpTerminatingLink = createUploadLink({
    uri: process.env.REACT_APP_HTTP_LINK,
    credentials: 'include',
  });


  // auth link
  const httpAuthLink = new ApolloLink((operation, forward) => {
    const accessToken = getAccessToken();

    operation.setContext(({ headers = {}, }) => ({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
    }));
    return forward(operation);
  });

  // refresh link
  const refreshLink = new TokenRefreshLink<RefreshLinkResult>({
    accessTokenField: 'refresh',
    isTokenValidOrUndefined: isAccessTokenValidOrUndefined,
    fetchAccessToken: fetchRefresh,
    handleFetch: handleRefreshCompleted,
    handleError: handleRefreshError,
  });

  // link
  const link = from([
    refreshLink,
    httpAuthLink,
    httpTerminatingLink as unknown as ApolloLink, // Hack - waiting till @types/apollo-upload-client
  ]);

  // apollo client
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
          },
        },
      },
    }),
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
      },
    },
  });

  return apolloClient;
};


export const apolloClient = createApolloClient();
