import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import * as lodash from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Box, TextField } from "@material-ui/core";
import { Theme, Tooltip } from "@mui/material";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import { OrderStore } from "Modules/Order/store/types";
import {
  PartFragment,
  useRefreshPartMutation,
  useRemovePartMutation,
  useUpdatePartAmountMutation,
} from "Apollo/graphql";
import PartCardSkeleton from "./PartCardSkeleton";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  label: {
    fontWeight: 600,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

interface Props {
  part: PartFragment;
  removePartFromStore: OrderStore["removePart"];
  changeAmount: OrderStore["changeAmount"];
  updatePart: OrderStore["updatePart"];
}

const PartCard = ({
  part,
  removePartFromStore,
  updatePart,
  changeAmount,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [amount, setAmount] = useState(part.amount);
  const [expanded, setExpanded] = useState(false);

  const totalPrice = (part.price || 0) * amount;

  const [removePartMutation, removePartResult] = useRemovePartMutation({
    variables: {
      id: part.id,
    },
    onCompleted: (data) => {
      if (data.removePart) {
        removePartFromStore(data.removePart);
        enqueueSnackbar(t("part:notificationDeleted", { id: part.number }), {
          variant: "warning",
        });
      }
    },
    onError(error) {
      enqueueSnackbar(t("part:notificationError", { id: part.number }), {
        variant: "error",
      });
      console.error(error);
    },
  });

  const [updatePartMutation, updatePartResult] = useUpdatePartAmountMutation({
    onError(error) {
      enqueueSnackbar(t("part:notificationError", { id: part.number }), {
        variant: "error",
      });
      console.error(error);
    },
    onCompleted(data) {
      if (data.updatePartAmount) {
        changeAmount({
          id: data.updatePartAmount.id,
          amount: data.updatePartAmount.amount,
        });
        enqueueSnackbar(t("part:notificationUpdated", { id: part.number }), {
          variant: "success",
        });
      }
    },
  });
  const [refreshPartMutation, refreshPartResult] = useRefreshPartMutation({
    variables: {
      id: part.id,
    },
    onCompleted: (data) => {
      if (data.refreshPart) {
        updatePart(data.refreshPart);
        enqueueSnackbar(t("part:notificationUpdated", { id: part.number }), {
          variant: "success",
        });
      }
    },
    onError(error) {
      enqueueSnackbar(t("part:notificationError", { id: part.number }), {
        variant: "error",
      });
      console.error(error);
    },
  });

  // Debounce the mutation function
  const debouncedMutation = useMemo(() => {
    return lodash.debounce(({ id, amount }: { id: string; amount: number }) => {
      updatePartMutation({
        variables: {
          updatePartAmountInput: { id, amount },
        },
      });
    }, 500);
  }, [updatePartMutation]);

  const handleAmountChange = (event: { target: { value: any } }) => {
    const newAmount = Number(event.target.value);
    setAmount(newAmount);
    debouncedMutation({ id: part.id, amount: newAmount });
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (
    removePartResult.loading ||
    updatePartResult.loading ||
    refreshPartResult.loading
  ) {
    return <PartCardSkeleton />;
  }

  return (
    <Card>
      <CardContent>
        <Box className={classes.row}>
          <Typography className={classes.label}>
            {t("order:table.partNumber")}
          </Typography>
          <Typography>{part.number}</Typography>
        </Box>
        <Box className={classes.row}>
          <Typography className={classes.label}>
            {t("order:table.price")}
          </Typography>
          <Tooltip
            placement="top"
            title={
              totalPrice
                ? t("order:table.priceInfo")
                : t("order:table.willBeDeleted")
            }
          >
            <Typography color={!!totalPrice ? "inherit" : "error"}>
              {totalPrice ? `${totalPrice} Kč` : t("order:table.unavailable")}
            </Typography>
          </Tooltip>
        </Box>
        <Box className={classes.row}>
          <Typography className={classes.label}>
            {t("order:table.deliveryDate")}
          </Typography>
          <Typography>{part.deliveryDate ? 
           new Date(part.deliveryDate).toLocaleDateString("cs-CZ")
          : "-"}</Typography>
        </Box>
        <Box className={classes.row}>
          <Typography className={classes.label}>
            {t("order:table.amount")}
          </Typography>
          <TextField
            value={amount}
            onChange={handleAmountChange}
            type="number"
            size="small"
            style={{ maxWidth: "100px" }}
          />
        </Box>
        <Box className={classes.row}>
          <Typography className={classes.label}>
            {t("order:table.brand")}
          </Typography>
          <Typography>{part.brand}</Typography>
        </Box>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          aria-label="add to favorites"
          onClick={() => refreshPartMutation()}
        >
          <RefreshIcon fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="add to favorites"
          onClick={() => removePartMutation()}
        >
          <DeleteIcon fontSize="small" color="error" />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Box className={classes.row}>
            <Typography>{t("part:partDetail.name")}</Typography>
            <Typography>{part.name ?? "-"}</Typography>
          </Box>
          <Box className={classes.row}>
            <Typography>{t("part:partDetail.height")}</Typography>
            <Typography>{part.height ?? "-"}</Typography>
          </Box>
          <Box className={classes.row}>
            <Typography>{t("part:partDetail.length")}</Typography>
            <Typography>{part.length ?? "-"}</Typography>
          </Box>
          <Box className={classes.row}>
            <Typography>{t("part:partDetail.width")}</Typography>
            <Typography>{part.width ?? "-"}</Typography>
          </Box>
          <Box className={classes.row}>
            <Typography>{t("part:partDetail.weight")}</Typography>
            <Typography>{part.weight ?? "-"}</Typography>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default PartCard;
