import React from 'react';
import MuiTableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';


const SkeletonRow: React.FC = () => (
  <MuiTableRow>
    <TableCell>
      <Skeleton animation="wave" variant="text" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" variant="text" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" variant="text" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" variant="text" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" variant="text" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" variant="text" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" variant="text" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" variant="text" width="100%" />
    </TableCell>
    <TableCell className="tableCell-action">
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Skeleton
            animation="wave"
            variant="circle"
            width={36}
            height={36}
          />
        </Grid>
        <Grid item>
          <Skeleton
            animation="wave"
            variant="circle"
            width={36}
            height={36}
          />
        </Grid>
        <Grid item>
          <Skeleton
            animation="wave"
            variant="circle"
            width={36}
            height={36}
          />
        </Grid>

      </Grid>
    </TableCell>
  </MuiTableRow>
);


export default SkeletonRow;
