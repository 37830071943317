import { PermissionEnum } from "Apollo/graphql";

import { ROUTES } from "Utils/globals";
import { useOrderStore } from "../../Modules/Order/store";
import { useDispatchOrdersStore } from "../../Modules/ManageDispatchOrders/store";

type MenuButton = {
  id: string;
  to: string;
  textKey: string;
  permission: PermissionEnum | null;
  onClick?: () => void;
};

export const MENU_BUTTONS: MenuButton[] = [
  // {
  //   id: 'demand',
  //   to: `/${ROUTES.DEMAND}`,
  //   textKey: 'common:header.navDemand',
  //   permission: PermissionEnum.PartsManage,
  // },
  // {
  //   id: 'demands',
  //   to: `/${ROUTES.DEMANDS}`,
  //   textKey: 'common:header.navDemands',
  //   permission: PermissionEnum.PartsManage,
  // },
  {
    id: "order",
    to: `/${ROUTES.ORDER}`,
    textKey: "common:header.navOrder",
    permission: PermissionEnum.PartsManage,
    onClick: () => useOrderStore.setState({ phase: "build" }),
  },
  {
    id: "orders",
    to: `/${ROUTES.ORDERS}`,
    textKey: "common:header.navOrders",
    permission: PermissionEnum.PartsManage,
  },
  {
    id: "aboutUs",
    to: `/${ROUTES.ABOUT_US}`,
    textKey: "common:header.navAboutUs",
    permission: null,
  },
  {
    id: "incomingOrders",
    to: `/${ROUTES.MANAGE_INCOMING_ORDERS}`,
    textKey: "common:header.navManageIncomingOrders",
    permission: PermissionEnum.ManageAll,
  },
  {
    id: "dispatchOrders",
    to: `/${ROUTES.MANAGE_DISPATCH_ORDERS}`,
    textKey: "common:header.navManageDispatchOrders",
    permission: PermissionEnum.ManageAll,
    onClick: () => useDispatchOrdersStore.setState({ phase: "overview" }),
  },

  {
    id: "customers",
    to: `/${ROUTES.CUSTOMERS}`,
    textKey: "common:header.navCustomers",
    permission: PermissionEnum.ManageAll,
  },
  {
    id: "users",
    to: `/${ROUTES.USERS}`,
    textKey: "common:header.navUsers",
    permission: PermissionEnum.ManageAll,
  },
];
