import * as Yup from 'yup';
import { FORM_VALIDATORS, } from 'Components/Form';

export const registerInitFormValues = {
  company: '',
  email: '',
};

export const registerValidationScheme = Yup.object().shape({
  company: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .max(256, FORM_VALIDATORS.STRING_MAX),
  email: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .required(FORM_VALIDATORS.EMAIL)
    .max(256, FORM_VALIDATORS.STRING_MAX),
});