import { useTranslation, } from 'react-i18next';
import { makeStyles, Theme, } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { useUser, } from 'Tools/auth';
import { SpacesStore, useSpacesStore, } from '../../store/useSpacesStore';
import SpacesTable from './SpacesTable';


const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'inline-block',
  },
}));


const selectorOpenModalCreate = (store: SpacesStore) => (store.openModalCreate);
const selectorOpenModalExport = (store: SpacesStore) => (store.openModalExport);


const SpacesListPage = (): JSX.Element => {
  const { t, } = useTranslation();
  const classes = useStyles();
  const user = useUser();

  const openModalCreate = useSpacesStore(selectorOpenModalCreate);
  const openModalExport = useSpacesStore(selectorOpenModalExport);


  return (
    <Container className="container-page">

      <Box mb={2}>
        <Typography variant="h4" gutterBottom>
          {t('space:spacesTable.title')}
        </Typography>

        <Grid container spacing={1}>

          {user?.permissions.SPACE_MANAGE && (
            <Grid item>
              <Button
                className="button-success"
                onClick={openModalCreate}
              >
                {t('space:spacesTable.createSpace')}
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              color="primary"
              onClick={openModalExport}
            >
              {t('user:exportModal.export')}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Paper className={classes.paper}>
        <SpacesTable user={user} />
      </Paper>

    </Container>
  );
};


export default SpacesListPage;
