import { Link as RouterLink, } from 'react-router-dom';
import MuiLink, { LinkProps, } from '@material-ui/core/Link';


interface Props extends LinkProps {
  to: string
}


const Link = ({ children, ...props }: Props): JSX.Element => (
  <MuiLink
    component={RouterLink}
    {...props}
  >
    {children}
  </MuiLink>
);


export default Link;
