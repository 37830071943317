import { useEffect, useContext, } from 'react';

import AppEventContext from './AppEventContext';


export const useListenAppEvent = (eventName: string, cb: () => void): void => {
  const { subscribe, unsubscribe, } = useContext(AppEventContext);

  useEffect(
    () => {
      if (subscribe && unsubscribe) {
        subscribe(eventName, cb);
        return () => unsubscribe(eventName);
      }
      return undefined;
    },
    [ subscribe, unsubscribe, eventName, cb, ],
  );
};
