import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import { useGetOrdersForIntervalLazyQuery } from "Apollo/graphql";
import moment from "moment";
import { useEffect, useState } from "react";
import DayButton from "../../components/DayButton";
import AdminOrderTable from "../../components/OrderTable";
import { useManageOrdersStore } from "../../store";
import { ManageOrdersStore } from "../../store/types";
import { getInterval, getWeekDays } from "../../utils";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import lodash from "lodash";
import { useLocale } from "Tools/localization/hooks/useLocale";
import { useTranslation } from "react-i18next";

const selectStoreData = (s: ManageOrdersStore) => ({
  dateInterval: s.dateInterval,
  setDateInterval: s.setDateInterval,
  orderList: s.orderList,
  setOrderList: s.setOrderList,
  setActiveOrderId: s.setActiveOrderId,
  changePhase: s.setPhase,
});

const OverView = (): JSX.Element => {
  const { t, } = useTranslation()
  const {
    dateInterval,
    orderList,
    setDateInterval,
    setOrderList,
    changePhase,
    setActiveOrderId,
  } = useManageOrdersStore(selectStoreData);
  const [query, result] = useGetOrdersForIntervalLazyQuery({
    onCompleted: (data) => {
      if (data.getOrdersForInterval) {
        setOrderList(data.getOrdersForInterval);
      }
    },
    fetchPolicy: "network-only",
  });
  const debouncedQuery = lodash.debounce(query, 500);

  useEffect(() => {
    if (!dateInterval) {
      const date = new Date();
      const momentDate = moment(date);
      const interval = getInterval(momentDate);
      setDateInterval(interval);
      setWorkingDate(momentDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dateInterval) {
      debouncedQuery({
        variables: {
          ...dateInterval,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateInterval]);

  const date = new Date();
  const momentDate = moment(date);

  const [workingDate, setWorkingDate] = useState(momentDate);

  const addWeek = () =>
    setWorkingDate((date: moment.Moment) => date.clone().add(1, "week"));
  const subtractWeek = () =>
    setWorkingDate((date: moment.Moment) => date.clone().subtract(1, "week"));

  const onDayButtonClick = (date: Date) => {
    console.log(date, "date")
    const dayDate = moment(date);
    console.log(dayDate, "dayDate")
    const interval = getInterval(dayDate);
    console.log(interval, "interval")
    setDateInterval(interval);
    setWorkingDate(dayDate);
  };
  const editOrder = (id: string) => {
    setActiveOrderId(id);
    changePhase("edit");
  };
  const weekDays = getWeekDays({ date: workingDate });
  const locale = useLocale();
  const weekIndex = moment(workingDate).week();
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      marginTop={2}
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="row" gap={1}>
        <Typography
          variant="h4"
          sx={(theme) => ({
            fontWeight: "bold",
            [theme.breakpoints.down("sm")]: {
              fontSize: theme.spacing(3),
            },
          })}
        >
          {`${weekIndex}.  ${t("common:week")}:`}
        </Typography>
        <Typography
          variant="h4"
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              fontSize: theme.spacing(3),
            },
          })}
        >
          {`${workingDate
            .startOf("week")
            .toDate()
            .toLocaleDateString(locale.key)}  -  ${workingDate
            .endOf("week")
            .toDate()
            .toLocaleDateString(locale.key)}`}
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: theme.spacing(1),
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <IconButton
          aria-label="edit"
          size="medium"
          onClick={() => subtractWeek()}
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
            width: theme.spacing(6),
            height: theme.spacing(6),
          })}
        >
          <ChevronLeftIcon fontSize="inherit" />
        </IconButton>
        {weekDays.map((day) => (
          <DayButton
            callBack={onDayButtonClick}
            data={day}
            key={day.name}
            selectedDay={
              dateInterval?.from
                ? moment(dateInterval?.from)
                    .format("YYYY-MM-DD")
                : workingDate.format("YYYY-MM-DD")
            }
          />
        ))}
        <IconButton
          aria-label="edit"
          size="medium"
          onClick={() => addWeek()}
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
            width: theme.spacing(6),
            height: theme.spacing(6),
          })}
        >
          <ChevronRightIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        })}
      >
        <IconButton
          aria-label="edit"
          size="medium"
          onClick={() => subtractWeek()}
          sx={(theme) => ({
            width: theme.spacing(6),
            height: theme.spacing(6),
          })}
        >
          <ChevronLeftIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          aria-label="edit"
          size="medium"
          onClick={() => addWeek()}
          sx={(theme) => ({
            width: theme.spacing(6),
            height: theme.spacing(6),
          })}
        >
          <ChevronRightIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <AdminOrderTable
        orders={orderList}
        result={result}
        dateInterval={dateInterval}
        editOrder={editOrder}
      />
    </Box>
  );
};

export default OverView;
