import { FileRejection, } from 'react-dropzone';

import { InputErrorMessage, } from 'Utils/types';
import { FileError, } from '../types';
import { formatSizeParam, } from './formatSizeParam';


type ErrorMessages = { [key: string]: InputErrorMessage, };


const getFileError = (
  fileRejection: FileRejection,
  errorMessages: ErrorMessages,
): InputErrorMessage => {
  const { errors, } = fileRejection;
  const errorCode = errors[0]?.code;

  if (fileRejection.errors.length > 0 && errorCode && Object.prototype.hasOwnProperty.call(errorMessages, errorCode)) {
    return errorMessages[errorCode];
  }
  return {
    key: 'errors:somethingHappened',
  };
};


export const parseDropZoneRejected = (
  fileRejections: FileRejection[],
  formatsErrorParam?: string,
  maxSize?: number,
  minSize?: number,
  maxFiles?: number,
): FileError[] => {
  const errorMessages: ErrorMessages = {
    'file-too-large': {
      key: maxSize ? 'errors:input.fileMaxParam' : 'errors:input.fileMax',
      params: maxSize ? { maxSize: formatSizeParam(maxSize), } : undefined,
    },
    'file-too-small': {
      key: minSize ? 'errors:input.fileMinParam' : 'errors:input.fileMin',
      params: minSize ? { minSize: formatSizeParam(minSize), } : undefined,
    },
    'too-many-files': {
      key: maxFiles ? 'errors:input.fileMaxFilesParam' : 'errors:input.fileMaxFiles',
      params: maxFiles ? { maxFiles, } : undefined,
    },
    'file-invalid-type': {
      key: formatsErrorParam ? 'errors:input.fileTypeParam' : 'errors:input.fileType',
      params: formatsErrorParam ? { formats: formatsErrorParam, } : undefined,
    },
  };

  const ret: FileError[] = [];

  for (let i = 0; i < fileRejections.length; i++) {
    const fileRejection = fileRejections[i];
    const fileErrorMessage = getFileError(fileRejection, errorMessages);

    ret.push({
      file: fileRejection.file,
      errorMessage: fileErrorMessage,
    });
  }

  return ret;
};
