import { TableCell, TableRow } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import { useTranslation } from "react-i18next";

const Headers = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ minWidth: "150px", fontWeight: 600 }}>
          {t("order:table.partNumber")}
        </TableCell>
        <TableCell style={{ minWidth: "100px", fontWeight: 600 }}>
          Značka
        </TableCell>
        <TableCell style={{ minWidth: "100px", fontWeight: 600 }}>
          {t("order:table.price")}
        </TableCell>
        <TableCell style={{ minWidth: "100px", fontWeight: 600 }}>
          {t("order:table.amount")}
        </TableCell>
        <TableCell style={{ minWidth: "100px", fontWeight: 600 }}>
          Termín doručení
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};
export default Headers;
