import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@mui/material";
import CardActions from "@material-ui/core/CardActions";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  lastRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
}));

const PartCardSkeleton = () => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <Box className={classes.row}>
          <Skeleton variant="rect" width={100} height={20} />
          <Skeleton variant="rect" width={100} height={20} />
        </Box>
        <Box className={classes.row}>
          <Skeleton variant="rect" width={100} height={20} />
          <Skeleton variant="rect" width={100} height={20} />
        </Box>
        <Box className={classes.row}>
          <Skeleton variant="rect" width={100} height={20} />
          <Skeleton variant="rect" width={100} height={20} />
        </Box>
        <Box className={classes.lastRow}>
          <Skeleton variant="rect" width={100} height={20} />
          <Skeleton variant="rect" width={100} height={20} />
        </Box>
      </CardContent>
      <CardActions>
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="circle" width={40} height={40} />
      </CardActions>
    </Card>
  );
};

export default PartCardSkeleton;
