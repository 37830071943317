import clsx from 'clsx';
import React, { ReactNode, } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';


type StyleProps = {
  disablePaddingBottom?: boolean
};


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: ({ disablePaddingBottom, }: StyleProps) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: disablePaddingBottom ? 0 : theme.spacing(2),
    paddingTop: theme.spacing(2),
    overflowY: 'unset',
  }),
}));


interface Props {
  className?: string,
  disablePaddingBottom?: boolean,
  children: ReactNode,
}


const ModalBody: React.FC<Props> = ({
  className,
  disablePaddingBottom,
  children,
}: Props) => {
  // classes
  const classes = useStyles({ disablePaddingBottom, });


  return (
    <MuiDialogContent dividers className={clsx(classes.root, className)}>
      {children}
    </MuiDialogContent>
  );
};


export default ModalBody;
