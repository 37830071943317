import { ExportSpacesMutationVariables, } from 'Apollo/graphql';
import { EXPORT_ITEMS, } from 'Utils/globals';
import { SpacesFilter, } from '../../store/useSpacesStore';


export const exportVariables = (page: string, filter: SpacesFilter): ExportSpacesMutationVariables => {
  return {
    spacesInput: {
      filter: {
        name: filter.name ? filter.name : undefined,
        active: filter.active?.value,
        approved: filter.approved?.value,
      },
      sort: {
        by: filter.sort.by,
        order: filter.sort.order,
      },
    },
    paginationInput: page === EXPORT_ITEMS.ALL_PAGES
      ? undefined
      : {
        limit: filter.limit,
        offset: filter.offset,
      },
  };
};
