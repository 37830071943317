import { useTranslation, } from 'react-i18next';
import { Modal, ModalHeader, } from 'Components/Modal';
import { UsersQueryResult, } from 'Apollo/graphql';
import Logic from './Logic';
import { UserStore, useUserStore, } from '../../store/useUserStore';

const selectorModalData = (store: UserStore) => (store.modalExport);
const selectorClose = (store: UserStore) => (store.closeModalExport);
const selectorFilter = (store: UserStore) => (store.filter);

interface Props {
  usersQuery: UsersQueryResult
}

const ModalUserExport = ({
  usersQuery,
}: Props): JSX.Element | null => {
  const { t, } = useTranslation();
  const modalData = useUserStore(selectorModalData);
  const close = useUserStore(selectorClose);
  const filter = useUserStore(selectorFilter);

  if (!modalData.isOpen) return null;

  const actualPageCount = !usersQuery.loading ? usersQuery.data?.users.list.length : 0;
  const totalCount = !usersQuery.loading ? usersQuery.data?.users.pagination?.count : 0;

  return (
    <Modal
      open
      maxWidth="sm"
    >
      <ModalHeader onClose={close}>
        {t('user:exportModal.title')}
      </ModalHeader>
      <Logic
        onClose={close}
        filter={filter}
        totalCount={totalCount}
        actualPageCount={actualPageCount}
      />
    </Modal>
  );
};


export default ModalUserExport;
