import { ChangeEvent, ReactNode, useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import clsx from 'clsx';
import { useField, useFormikContext, } from 'formik';
import { makeStyles, Theme, createStyles, } from '@material-ui/core/styles';
import Select, { SelectProps, } from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import { useInputMessage, } from '../utils/useInputMessage';


const useStyles = makeStyles((theme: Theme) => createStyles({
  placeholder: {
    color: theme.palette.grey[500],
  },
}));


interface Props<T> extends SelectProps {
  name: string,
  options: T[],
  loading?: boolean,
  getOptionLabel: (o: T) => ReactNode,
  getOptionKey: (o: T) => string,
}
const FormInputOptions = <T extends Record<string, unknown>>({
  name,
  placeholder,
  label,
  options,
  loading,
  getOptionLabel,
  getOptionKey,
}: Props<T>): JSX.Element => {
  const classes = useStyles();
  const { t, } = useTranslation();
  const [ { value, }, meta, ] = useField<T | null>({
    name,
  });
  const { error: inputError, helperText, } = useInputMessage(meta);
  const { setFieldValue, setFieldTouched, } = useFormikContext();


  const objectOptions = useMemo<{ [key: string]: T }>(
    () => options.reduce(
      (acc, curr) => ({
        ...acc,
        [getOptionKey(curr)]: curr,
      }),
      {},
    ),
    [ options, getOptionKey, ],
  );

  const handleChange = (
    e: ChangeEvent<unknown>,
    newValue: any,
  ) => {
    const parsed = (newValue?.props?.value || null) as string | null;
    setFieldValue(name, parsed ? objectOptions?.[parsed] || null : null);
  };

  const showLoading = loading && options.length;
  const showPlaceholder = placeholder && !showLoading;


  return (
    <FormControl
      error={inputError}
      fullWidth
      variant="outlined"
    >
      <InputLabel placeholder={placeholder}>{label}</InputLabel>
      <Select
        value={value ? getOptionKey(value) : ''}
        onChange={handleChange}
        onBlur={() => setFieldTouched(name, true)}
        variant="outlined"
        margin="dense"
        placeholder={placeholder}
        displayEmpty
        className={clsx({
          [classes.placeholder]: !value,
        })}
      >
        {showLoading && (
          <MenuItem
            value=""
            className={classes.placeholder}
          >
            {t('common:form.loading')}
          </MenuItem>
        )}
        {showPlaceholder && (
          <MenuItem
            value=""
            className={classes.placeholder}
          >
            {placeholder}
          </MenuItem>
        )}
        {options?.map((item) => (
          <MenuItem
            key={getOptionKey(item)}
            value={getOptionKey(item)}
          >
            {getOptionLabel(item)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};


export default FormInputOptions;
