import { OrderStore, Parts } from "Modules/Order/store/types";
import { makeStyles } from "@material-ui/core/styles";
import { parseStorePartToPart } from "Modules/Order/store/utils";
import PartCard from "./PartCard";
import Box from "@material-ui/core/Box";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  parts: Parts | null;
  removePartFromStore: OrderStore["removePart"];
  changeAmount: OrderStore["changeAmount"];
  updatePart: OrderStore["updatePart"];
}

const PartMobile = ({
  parts,
  removePartFromStore,
  changeAmount,
  updatePart,
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {parseStorePartToPart(parts).map((part) => (
        <PartCard
          key={part.id}
          part={part}
          removePartFromStore={removePartFromStore}
          changeAmount={changeAmount}
          updatePart={updatePart}
        />
      ))}
    </Box>
  );
};

export default PartMobile;
