import { FORM_VALIDATORS } from "Components/Form";
import { Formik } from "formik";
import { Dispatch, SetStateAction, useRef } from "react";
import * as Yup from "yup";
import PopulatePartListForm from "./components/PopulatePartListForm";

type PartInputFormValues = {
  amount: number;
  number: string;
};
interface Props {
  setPartSelection: Dispatch<SetStateAction<any[]>>;
  partSelection: any[];
}
const validationSchemeCheckPartForm = Yup.object().shape({
  number: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .max(256, FORM_VALIDATORS.STRING_MAX),

  amount: Yup.number().required(FORM_VALIDATORS.REQUIRED),
});

const PartInputForm = ({
  partSelection,
  setPartSelection,
}: Props): JSX.Element => {
  const formikRef = useRef<any>(null);
  const handleSubmit = (values: PartInputFormValues, { resetForm }: any) => {
    setPartSelection(() => {
      const existingPart = partSelection.find(
        (part) => part.partNumber === values.number
      );
      if (existingPart) {
        return partSelection.map((part, i) =>
          i === 0
            ? { partNumber: values.number, amount: part.amount + values.amount }
            : part
        );
      }
      return [
        ...partSelection,
        { partNumber: values.number, amount: values.amount },
      ];
    });
    resetForm();
  };
  return (
    <Formik
      initialValues={{ amount: 1, number: "" }}
      onSubmit={handleSubmit}
      validationSchema={validationSchemeCheckPartForm}
      innerRef={formikRef}
    >
      <PopulatePartListForm
        partSelection={partSelection}
        setPartSelection={setPartSelection}
      />
    </Formik>
  );
};
export default PartInputForm;
