import styled from "@emotion/styled";
import { Typography, withStyles } from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const StyledSpinner = styled(RefreshIcon)(({ theme }) => ({
  animation: `nfLoaderSpin infinite 700ms linear`,
  transformBox: "fill-box",
  color: "white",
  "@keyframes nfLoaderSpin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
}));

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF"
  }
})(Typography);


const LoadingResponse = (): JSX.Element => {
  const { t } = useTranslation("part");
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}
    >
      <Box display="flex" flexDirection="row" gap={2}>
        <StyledSpinner />
        <WhiteTextTypography>{t("changesLoading")}</WhiteTextTypography>
      </Box>
    </Box>
  );
};

export default LoadingResponse;
