import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useValidateOrderMutation } from "Apollo/graphql";
import { useTranslation } from "react-i18next";
import { OrderStore, Parts } from "../store/types";
import CheckPart from "./CheckPart";
import ErrorMessage from "Components/ErrorMessage";
import { parseStorePartToPart } from "../store/utils";
import PartList from "./PartList";
interface Props {
  changePhase: OrderStore["changePhase"];
  id: string;
  parts: Parts | null;
}
const View = ({ changePhase, id, parts }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [mutation, result] = useValidateOrderMutation({
    variables: { id },
    onCompleted(data) {
      if (data.validateOrder.success) {
        changePhase("billing");
      }
    },
  });
  const handelOk = () => mutation();
  const partList = parseStorePartToPart(parts || {});

  return (
    <Box
      marginTop={5}
      marginBottom={12}
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingX: theme.spacing(2),
      })}
    >
      <CheckPart />
      <PartList />
      <ErrorMessage apolloError={result.error} />
      <Box
        sx={(theme) => ({
          width: "100%",
          maxWidth: theme.spacing(112),
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        })}
      >
        <Button
          disabled={partList.length < 1}
          color="primary"
          variant="contained"
          onClick={handelOk}
        >
          {t("common:action.ok")}
        </Button>
      </Box>
    </Box>
  );
};
export default View;
