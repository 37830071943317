

import { useTranslation, } from 'react-i18next';
import { Typography, } from '@material-ui/core';

import { FormInputText, } from 'Components/Form';


const SpaceForm = (): JSX.Element => {
  const { t, } = useTranslation();

  return (
    <>

      <Typography variant="h6">
        {t('space:form.subTitleSpace')}
      </Typography>

      <FormInputText
        name="name"
        label={t('space:label.name')}
        margin="normal"
      />

    </>
  );
};

export default SpaceForm;
