import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import { useGetBusinessOwnerBillingInfoQuery } from "Apollo/graphql";
import ErrorAlert from "Components/ErrorAlert";
import ErrorMessage from "Components/ErrorMessage";
import LoadingBoxWithMessage from "Components/LoadingBoxWithMessage";
import BillingInfo from "Modules/Order/components/BillingInfo";
import { useTranslation } from "react-i18next";

const OwnerBillingInfo = () => {
  const { t } = useTranslation("billing");
  const { data, error, loading } = useGetBusinessOwnerBillingInfoQuery();
  if (loading)
    return <LoadingBoxWithMessage label={t("common:loading.loadingBilling")} />;
  if (error || !data?.getBusinessOwnerBillingInfo)
    return <ErrorAlert error={error} />;
  return (
    <Box padding={3}>
      <Box marginBottom={2}>
        <Typography variant="h4">{t("ownerInfo")}</Typography>
      </Box>

      <BillingInfo data={data.getBusinessOwnerBillingInfo} />
    </Box>
  );
};

export default OwnerBillingInfo;
