import { useGetBillingInfoQuery } from "Apollo/graphql";
import ErrorMessage from "Components/ErrorMessage";
import LoadingBoxWithMessage from "Components/LoadingBoxWithMessage";
import { useOrderStore } from "Modules/Order/store";
import { OrderStore } from "Modules/Order/store/types";
import { useUser } from "Tools/auth";
import Logic from "./Logic";
import { useTranslation } from "react-i18next";
const selectStoreData = (s: OrderStore) => ({
  orderId: s.orderId,
  setBillingInfo: s.setBillingInfo,
});
const BillingForm = (): JSX.Element => {
  const {t,} = useTranslation('common')
  const { orderId, setBillingInfo } = useOrderStore(selectStoreData);
  const user = useUser();
  const { loading, error, data } = useGetBillingInfoQuery({
    fetchPolicy: "network-only",
    variables: { orderId, spaceId: user?.space.id },
    onCompleted: (data) => {
      if (data.getBillingInfo) {
        setBillingInfo(data.getBillingInfo);
      }
    },
  });
  if (loading) return <LoadingBoxWithMessage label={t('loading.billingLoading')}/>;
  if (error || !data) return <ErrorMessage apolloError={error} />;
  return <Logic />;
};
export default BillingForm;
