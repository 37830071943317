import { PartFragment } from "Apollo/graphql";
import { Parts, StoreVariables } from "./types";

export const parsePartsToStore = (parts: PartFragment[]): Parts => {
  const partsStore: Parts = {};
  parts.forEach((part) => {
    partsStore[part.id] = part;
  });
  return partsStore;
};

export const parseStorePartToPart = (
  storeParts: Parts | null
): PartFragment[] => {
  if (!storeParts) {
    return [];
  }
  const parts: PartFragment[] = [];
  Object.keys(storeParts).forEach((key) => {
    parts.push(storeParts[key]);
  });
  return parts;
};
export const addPartToStore = (
  storeParts: Parts | null,
  part: PartFragment
): Parts => {
  if (!storeParts) {
    return { [part.id]: part };
  }
  return { ...storeParts, [part.id]: part };
};
export const removePartsFromStore = (
  storeParts: Parts | null,
  ids: string[]
): Parts | null => {
  if (!storeParts) {
    return null;
  }
  const parts: Parts = { ...storeParts };
  ids.forEach((id) => delete parts[id]);
  return parts;
};

export const EMPTY_STORE_STATE: StoreVariables = {
  phase: "build",
  parts: {},
  orderId: "",
  billingInfo: null,
  shippingPrice: null,
  paymentLink: "",
};
