import { useGetCurrentOrderQuery } from "Apollo/graphql";
import LoadingBoxWithMessage from "Components/LoadingBoxWithMessage";
import { useUser } from "Tools/auth";
import { useTranslation } from "react-i18next";
import PartsDataTable from ".";

const PartsTable = (): JSX.Element => {
  const { t,} = useTranslation('common')
  const user = useUser();
  const { data, error, loading } = useGetCurrentOrderQuery({
    fetchPolicy: "cache-and-network",
    variables: { userId: user?.id || "" },
  });
  if (loading) return <LoadingBoxWithMessage label={t('loading.loadingOrder')}/>;

  if (error || !data?.getCurrentOrder) return <>{`error:${error}`}</>;
  return (
    <PartsDataTable parts={data.getCurrentOrder.parts} shippingPrice={data.getCurrentOrder.shippingPrice}/>
  );
};

export default PartsTable;
