import create from "zustand";
import { OrderStore } from "./types";
import {
  EMPTY_STORE_STATE,
  addPartToStore,
  parsePartsToStore,
  removePartsFromStore,
} from "./utils";
import { PartFragment } from "Apollo/graphql";

export const useOrderStore = create<OrderStore>((set) => ({
  ...EMPTY_STORE_STATE, // actions
  setPaymentLink: (paymentLink) => set({ paymentLink }),
  setShippingPrice: (shippingPrice) => set({ shippingPrice }),
  setBillingInfo: (billingInfo) => set({ billingInfo }),
  changePhase: (phase) => set({ phase }),
  updatePart: (part) =>
    set((state) => {
      return { parts: addPartToStore(state.parts, part) };
    }),
  removePart: (id) =>
    set((state) => {
      const parts = { ...state.parts };
      delete parts[id];
      return { ...state, parts };
    }),
  removePartsFromStore: (ids) =>
    set((state) => ({
      ...state,
      parts: removePartsFromStore(state.parts, ids),
    })),
  updateOrder: (order) =>
    set({ parts: parsePartsToStore(order.parts), orderId: order.id }),
  changeAmount: ({ amount, id }) =>
    set((state) => ({
      ...state,
      parts: {
        ...state.parts,
        [id]: {
          ...(state.parts?.[id] as PartFragment),
          amount,
        },
      },
    })),
  resetStore: () => set(() => ({ ...EMPTY_STORE_STATE })),
}));
