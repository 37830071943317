import { useMediaQuery } from "@material-ui/core";
import PartTable from "./table";
import { useOrderStore } from "../store";
import { OrderStore } from "../store/types";
import PartMobile from "./PartMobile";

const selectStoreData = (s: OrderStore) => ({
  changeAmount: s.changeAmount,
  removePartFromStore: s.removePart,
  updatePart: s.updatePart,
  parts: s.parts,
  orderId: s.orderId,
});

const PartList = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const { changeAmount, removePartFromStore, updatePart, parts } =
    useOrderStore(selectStoreData);

  return isMobile ? (
    <PartMobile
      parts={parts}
      changeAmount={changeAmount}
      removePartFromStore={removePartFromStore}
      updatePart={updatePart}
    />
  ) : (
    <PartTable
      parts={parts}
      changeAmount={changeAmount}
      removePartFromStore={removePartFromStore}
      updatePart={updatePart}
    />
  );
};

export default PartList;
