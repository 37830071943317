import React from 'react';
import { useTranslation, } from 'react-i18next';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import Autocomplete, { AutocompleteProps, } from '@material-ui/lab/Autocomplete';
import { Value, } from '@material-ui/lab/useAutocomplete';
import TextField from '@material-ui/core/TextField';

import { ChangeFilter, } from '../types';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '& .MuiFilledInput-root': {
      background: theme.palette.common.white,
    },
  },
  inputRoot: {
    '&&[class*="MuiFilledInput-root"]': {
      padding: theme.spacing(1),
    },
    '&[class*="MuiFilledInput-root"][class*="MuiFilledInput-marginDense"] .MuiAutocomplete-input': {
      padding: 0,
    },
  },
}));


interface Props<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>
  extends Pick<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'placeholder' | 'value' | 'multiple' | 'options' | 'getOptionLabel' | 'getOptionSelected' | 'loadingText' | 'noOptionsText'
  > {
  name: string,
  onChange: ChangeFilter<Value<T, Multiple, DisableClearable, FreeSolo>>,
}


const FilterSelect = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
    value,
    name,
    placeholder,
    loadingText,
    noOptionsText,
    onChange,
    ...rest
  }: Props<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  const classes = useStyles();
  const { t, } = useTranslation();

  const currentPlaceholder = placeholder || t('common:table.inputs.placeholder');


  const handleChange = (
    e: React.ChangeEvent<unknown>,
    newValue: Value<T, Multiple, DisableClearable, FreeSolo>,
  ) => {
    if (onChange) {
      onChange({
        value: newValue,
        name,
      });
    }
  };


  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
      {...rest}
      classes={{
        root: classes.root,
        inputRoot: classes.inputRoot,
      }}
      value={value}
      onChange={handleChange}
      noOptionsText={noOptionsText || t('common:form.noOptions')}
      loadingText={loadingText || t('common:form.loading')}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          placeholder={currentPlaceholder}
        />
      )}
    />
  );
};


export default FilterSelect;
