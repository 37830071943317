import { useFormikContext, } from 'formik';
import Button, {
  ButtonProps,
} from '@material-ui/core/Button';

interface Props extends Omit<ButtonProps, 'type'> {
  children: React.ReactNode,
}


const FormSubmit = ({
  disabled,
  children,
  ...rest
}: Props): JSX.Element => {
  const { isSubmitting, } = useFormikContext();


  return (
    <Button
      type="submit"
      {...rest}
      disabled={disabled || isSubmitting}
    >
      {children}
    </Button>
  );
};

export default FormSubmit;
