import { useTranslation, } from 'react-i18next';
import { Redirect, } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { ROUTES, } from 'Utils/globals';
import { useUser, } from 'Tools/auth';
import { Breadcrumb, BreadcrumbLinkType, } from 'Components/Breadcrumb';
import ChangePassword from './ChangePassword';
import ChangeEmail from './ChangeEmail';


const WebinarTermDetailPage = (): JSX.Element => {
  const { t, } = useTranslation();

  const user = useUser();

  const linkList: BreadcrumbLinkType[] = [
    {
      id: '1',
      name: t('currentUser:breadcrumb.settings'),
      link: null,
    },
  ];


  if (!user) return <Redirect to={`/${ROUTES.LOGIN}`} />;

  return (
    <Container className="container-page">

      <Breadcrumb list={linkList} />

      <Typography variant="h5" gutterBottom>
        {t('currentUser:settings.title')}
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ChangePassword />
        </Grid>
        <Grid item xs={12}>
          <ChangeEmail />
        </Grid>
      </Grid>

    </Container>
  );
};


export default WebinarTermDetailPage;
