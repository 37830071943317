import { ReactNode } from 'react';

// TODO placeholderNode
interface Props {
  value?: number | null;
  placeholder?: string | null;
  prefix?: ReactNode;
  postfix?: ReactNode;
  locale?: string;
}

const LocalizedNumber = ({ locale = 'cs-CZ', placeholder, postfix, prefix, value }: Props): JSX.Element | null => {
  if (value === null || value === undefined || Number.isNaN(value)) return placeholder ? <>{placeholder}</> : null;

  const intl = Intl.NumberFormat(locale);
  return (
    <>
      {prefix}
      {intl.format(value)}
      {postfix}
    </>
  );
};

export default LocalizedNumber;
