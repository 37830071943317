import create from "zustand";
import { ManageOrdersStore } from "./types";
import { EMPTY_STORE_STATE } from "./utils";

export const useManageOrdersStore = create<ManageOrdersStore>((set) => ({
  ...EMPTY_STORE_STATE, // actions
  setDateInterval({ from, to }) {
    set({ dateInterval: { from, to } });
  },
  setOrderList(orderList) {
    set({ orderList });
  },
  setPhase(phase) {
    set({ phase });
  },
  setActiveOrderId(id) {
    set({ activeOrderId: id });
  },
  setActiveOrder(order) {
    set({ activeOrder: order });
  },
  resetStore: () => set(() => ({ ...EMPTY_STORE_STATE })),
}));
