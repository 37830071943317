import OrderProcessBox from "Components/OrderProcessBox";
import Logic from "./Logic";

const PaymentPhase = (): JSX.Element => {
  return (
    <OrderProcessBox>
      <Logic />
    </OrderProcessBox>
  );
};
export default PaymentPhase;
