import { ChangeEvent, } from 'react';
import Autocomplete, { AutocompleteProps, } from '@material-ui/lab/Autocomplete';
import { TextField, } from '@material-ui/core';
import { useField, useFormikContext, } from 'formik';
import { Value, } from '@material-ui/lab/useAutocomplete';

import { useInputMessage, } from '../utils/useInputMessage';

interface Props<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>
  extends Pick<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'placeholder' | 'multiple' | 'options' | 'getOptionLabel' | 'getOptionSelected' | 'noOptionsText' | 'loadingText' | 'loading' | 'onBlur'
  > {
  name: string,
  error?: boolean,
  margin?: 'normal' | 'dense' | 'none'
}
const FormInputSelect = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
    name,
    noOptionsText,
    loadingText,
    placeholder,
    onBlur,
    error,
    margin,
    ...rest
  }: Props<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  // formik
  const { setFieldValue, setFieldTouched, } = useFormikContext();
  const [ field, meta, ] = useField({
    name,
    onBlur,
    ...rest,
  });

  const { error: inputError, helperText, } = useInputMessage(meta);

  const handleChange = (
    e: ChangeEvent<unknown>,
    newValue: Value<T, Multiple, DisableClearable, FreeSolo>
  ) => {
    setFieldValue(name, newValue);
  };

  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
      {...rest}
      value={field.value}
      onChange={handleChange}
      noOptionsText={noOptionsText || 'Žádné možnosti'}
      loadingText={loadingText || 'Načítám'}
      onBlur={() => setFieldTouched(name, true)}
      renderInput={(params) => (
        <TextField
          {...params}
          error={inputError}
          helperText={helperText}
          margin={margin}
          placeholder={placeholder}
        />
      )}
    />
  );
};
export default FormInputSelect;
