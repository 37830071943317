import { useEffect, useState } from "react";
import SubmitDialogue from "./SubmitDialogue";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { ManageOrdersStore } from "Modules/ManageIncomingOrders/store/types";

interface Props {
  setPhase: ManageOrdersStore["setPhase"];
  reservoirList: string[];
  note: string;
  orderId: string;
}

const SubmitForm = ({ note, reservoirList,orderId,setPhase }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [noteValue, setNoteValue] = useState<string>("");
  useEffect(() => {
    setNoteValue(note);
  }, [note]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box padding={2} display="flex" flexDirection="column">
      <TextField
        id="standard-multiline-flexible"
        label={t("order:admin.note")}
        multiline
        fullWidth
        maxRows={6}
        minRows={6}
        variant="outlined"
        defaultValue={note}
        onChange={(e) => setNoteValue(e.target.value)}
      />
      <Button
        color="success"
        variant="contained"
        onClick={handleClickOpen}
        sx={{ marginTop: 2 }}
      >
        {t("common:action:approve")}
      </Button>
      <Button
        variant="outlined"
        onClick={()=>setPhase("overview")}
        sx={{ marginTop: 2 }}
      >
        {t("common:action:back")}
      </Button>

      <SubmitDialogue
        open={open}
        onClose={handleClose}
        missingPartsList={reservoirList}
        note={noteValue}
        orderId={orderId}
        setPhase={setPhase}
      />
    </Box>
  );
};

export default SubmitForm;
