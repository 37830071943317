import { TableCell } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import { AdminTableOrderFragment } from "Apollo/graphql";
import OrderStatusChip from "Components/OrderStatusChip";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PartOrderList from "./PartOrderList";
import Collapse from "@mui/material/Collapse";

interface Props {
  order: AdminTableOrderFragment;
  editOrder?: (id: string) => void;
  selectRow?: (id: string) => void;
  rowIsSelected?: (id: string) => boolean;
  showPartDropDown?: boolean;
}

const OrderRow = ({
  order,
  editOrder,
  selectRow,
  rowIsSelected,
  showPartDropDown,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const partAmount = order.parts.reduce((acc, part) => acc + part.amount, 0);
  const isSelected = rowIsSelected ? rowIsSelected(order.id) : undefined;
  return (
    <>
      <TableRow
        key={order.id}
        selected={isSelected}
        aria-checked={isSelected}
        onClick={selectRow ? () => selectRow(order.id) : undefined}
        role="checkbox"
      >
        {!!rowIsSelected && !!selectRow && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isSelected}
              inputProps={{
                "aria-labelledby": order.id,
              }}
            />
          </TableCell>
        )}
        {showPartDropDown && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell>{order.orderNumber}</TableCell>
        <TableCell>{order.topTransId || "-"}</TableCell>
        <TableCell>{order.billingInfo?.name}</TableCell>
        <TableCell>{partAmount}</TableCell>
        <TableCell>
          <OrderStatusChip status={order.status} />
        </TableCell>
        {!!editOrder && (
          <TableCell>
            <Box display={"flex"} flexDirection={"row"}>
              <IconButton
                aria-label="edit"
                size="large"
                onClick={() => editOrder(order.id)}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <PartOrderList partList={order.parts} />{" "}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default OrderRow;
