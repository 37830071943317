import { QuaggaJSConfigObject } from "@ericblade/quagga2";

export const CONFIG: QuaggaJSConfigObject = {
    "inputStream": {
      "type": "LiveStream",
      "constraints": {
        "width": { "min": 450 },
        "height": { "min": 300 },
        "facingMode": "environment",
        "aspectRatio": { "min": 1, "max": 2 }
      }
    },
    "locator": {
      "patchSize": "medium",
      "halfSample": true
    },
    "numOfWorkers": 2,
    "frequency": 1,
    "decoder": {
      "readers": ["code_128_reader"],
      "debug": {
        drawBoundingBox: false,
        showFrequency: false,
        drawScanline: false,
        showPattern: false,
      },
    },
    "locate": true,

  }
  