import { useCheckOrderAvailabilityMutation } from "Apollo/graphql";
import LoadingBoxWithMessage from "Components/LoadingBoxWithMessage";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOrderStore } from "../store";
import { OrderStore } from "../store/types";
import { PartsMissingView } from "./PartsMissingView";
import ErrorAlert from "Components/ErrorAlert";
import { useSnackbar } from "notistack";

const selectStoreData = (s: OrderStore) => ({
  orderId: s.orderId,
  changePhase: s.changePhase,
  removePartsFromStore: s.removePartsFromStore,
});

const FetchData = (): JSX.Element => {
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const { changePhase, orderId, removePartsFromStore } =
    useOrderStore(selectStoreData);
  const [mutation, result] = useCheckOrderAvailabilityMutation({
    variables: { orderId },
    onCompleted: (data) => {
      if (
        data.checkOrderAvailability.missingParts.length === 0 &&
        data.checkOrderAvailability.success
      ) {
        changePhase("payment");
      }
    },
    onError: () => {
      enqueueSnackbar(t("errors:somethingHappened"), { variant: "error" });
    },
  });
  useEffect(() => {
    mutation();
  }, [mutation]);

  if (result.loading) {
    return <LoadingBoxWithMessage label={t("loading.loadingCheckOrder")} />;
  }
  if (result.error || !result.data?.checkOrderAvailability.order) {
    return <ErrorAlert error={result.error} />;
  }
  return (
    <PartsMissingView
      changePhase={changePhase}
      missingParts={result.data?.checkOrderAvailability.missingParts || []}
      order={result.data?.checkOrderAvailability.order}
      removePartsFromStore={removePartsFromStore}
    />
  );
};
export default FetchData;
