import { Form, Formik } from "formik";
import {
  validationPaymentConfirmation,
  PaymentConfirmationForm,
} from "./utils";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormError, FormInputCheckbox, FormSubmit } from "Components/Form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
interface Props {
  onSubmit: (values: PaymentConfirmationForm) => void;
}
const PaymentConditions = ({ onSubmit }: Props): JSX.Element => {
  const { t } = useTranslation("billing");
  return (
    <Formik<PaymentConfirmationForm>
      initialValues={{ acceptedConditions: false }}
      validationSchema={validationPaymentConfirmation}
      onSubmit={onSubmit}
    >
      <Form>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={1}
            justifyContent="flex-end"
            marginTop={2}
          >
            <Typography>{t("conditions")} <Link target="_blank" to="/vop.pdf">podmínkami</Link></Typography>
            <FormInputCheckbox name="acceptedConditions" />
          </Box>
          <FormError />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            marginTop={2}
            marginBottom={2}
          >
            <FormSubmit color="primary">{t("common:action.ask")}</FormSubmit>
          </Box>
        </Box>
      </Form>
    </Formik>
  );
};

export default PaymentConditions;
