import { ReactNode, } from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { OrderEnum, } from 'Apollo/graphql';
import { ChangeFilter, Sort, } from './types';
import { getSortLabelDirection, } from './utils';


interface Props {
  name: string,
  sort: Sort,
  children: ReactNode,
  onChange: ChangeFilter<Sort>
}

const SortLabel = ({
  onChange,
  name,
  sort,
  children,
}: Props): JSX.Element => {
  const isActive = sort.by === name;
  const direction = getSortLabelDirection(isActive, sort.order);

  const handleChange = () => {
    if (onChange) {
      let newOrder = OrderEnum.Asc;
      if (isActive && sort.order === OrderEnum.Asc) newOrder = OrderEnum.Desc;

      onChange({
        name: 'sort',
        value: {
          by: name,
          order: newOrder,
        },
      });
    }
  };

  return (
    <TableSortLabel
      active={isActive}
      direction={direction}
      onClick={handleChange}
    >
      {children}
    </TableSortLabel>
  );
};

export default SortLabel;
