import { useMemo, } from 'react';
import { Redirect, Route, RouteProps, } from 'react-router-dom';

import { PermissionEnum, } from 'Apollo/graphql';
import { ROUTES, } from 'Utils/globals';
import { useAuthStore, AuthStore, } from 'Tools/auth';


const selectUser = (state: AuthStore) => state.user;


interface Props extends RouteProps {
  permission?: PermissionEnum | null,
}


const AuthRoute = ({
  permission = null,
  ...routeProps
}: Props): JSX.Element => {
  const user = useAuthStore(selectUser);


  const isAuthorized = useMemo<boolean>(
    () => {
      if (!user) return false;
      if (!permission) return true;
      if (Object.prototype.hasOwnProperty.call(user.permissions, permission)) return true;
      return false;
    },
    [ permission, user, ],
  );


  if (!user) return <Redirect to={`/${ROUTES.LOGIN}`} />;
  if (!isAuthorized) return <Redirect to="/" />;

  return (
    <Route {...routeProps} />
  );
};


export default AuthRoute;
