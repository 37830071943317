import { useSpaceQuery, } from 'Apollo/graphql';
import { ModalLoading, ModalError, } from 'Components/Modal';
import Logic from './Logic';


interface Props {
  spaceId: string;
  onClose: () => void,
}


const FetchData = ({
  spaceId,
  onClose,
}: Props): JSX.Element => {
  const { loading, error, data, } = useSpaceQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: spaceId,
    },
  });


  if (loading) {
    return (
      <ModalLoading
        minHeight="6rem"
        onClose={onClose}
      />
    );
  }

  if (error || !data?.space) {
    return (
      <ModalError
        minHeight="6rem"
        apolloError={error}
        onClose={onClose}
      />
    );
  }

  return (
    <Logic
      space={data.space}
      onClose={onClose}
    />
  );
};


export default FetchData;
