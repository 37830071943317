import { createContext, } from 'react';


type ContextProps = {
  subscribe: (eventName: string, cb: () => void) => void,
  unsubscribe: (eventName: string) => void,
  emit: (eventName: string) => void,
};


const AppEventContext = createContext<Partial<ContextProps>>({});


export default AppEventContext;
