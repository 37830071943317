import ClearIcon from "@mui/icons-material/Clear";
import { Box, IconButton } from "@mui/material";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

interface Props {
  partNumber: string;
  partAmount: number;
  handleDelete: () => void;
  handleUpdate: (newPartNumber: string, newPartAmount: number) => void; // Updated to handle both updates
}

const PartChip = ({
  partNumber,
  partAmount,
  handleDelete,
  handleUpdate,
}: Props): JSX.Element => {
  const [isEditingNumber, setIsEditingNumber] = useState(false);
  const [isEditingAmount, setIsEditingAmount] = useState(false);
  const [editedPartNumber, setEditedPartNumber] = useState(partNumber);
  const [editedPartAmount, setEditedPartAmount] = useState(partAmount);

  const handleNumberDoubleClick = () => {
    setIsEditingNumber(true);
  };

  const handleAmountDoubleClick = () => {
    setIsEditingAmount(true);
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedPartNumber(event.target.value);
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedPartAmount(parseInt(event.target.value, 10) || 0);
  };

  const handleKeyPress = (
    event: React.KeyboardEvent,
    type: "number" | "amount"
  ) => {
    if (event.key === "Enter") {
      handleUpdate(editedPartNumber, editedPartAmount);
      if (type === "number") setIsEditingNumber(false);
      if (type === "amount") setIsEditingAmount(false);
    }
  };

  const handleBlur = (type: "number" | "amount") => {
    handleUpdate(editedPartNumber, editedPartAmount);
    if (type === "number") setIsEditingNumber(false);
    if (type === "amount") setIsEditingAmount(false);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={1}
      sx={{ borderRadius: 5, border: 1, width: "min" }}
    >
      {isEditingNumber ? (
        <TextField
          size="small"
          value={editedPartNumber}
          onChange={handleNumberChange}
          onKeyPress={(e) => handleKeyPress(e, "number")}
          onBlur={() => handleBlur("number")}
          autoFocus
        />
      ) : (
        <Chip
          sx={{ marginLeft: 1 }}
          label={`${partNumber}`}
          variant="outlined"
          onDoubleClick={handleNumberDoubleClick}
          size="small"
        />
      )}
      {isEditingAmount ? (
        <TextField
          size="small"
          type="number"
          value={editedPartAmount}
          onChange={handleAmountChange}
          onKeyPress={(e) => handleKeyPress(e, "amount")}
          onBlur={() => handleBlur("amount")}
          inputProps={{ min: 1 }}
          sx={{ maxWidth: 80 }}
          autoFocus
        />
      ) : (
        <Chip
          label={`${partAmount}x`}
          variant="outlined"
          onDoubleClick={handleAmountDoubleClick}
          size="small"
        />
      )}
      <IconButton onClick={handleDelete}>
        <ClearIcon />
      </IconButton>
    </Box>
  );
};

export default PartChip;
