import { makeStyles, Theme, createStyles, } from '@material-ui/core/styles';


export const useGlobalStyles = makeStyles((theme: Theme) => createStyles({
  '@global': {
    '.container-page': {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    '.fontWeight-500': {
      fontWeight: 500,
    },
    '.fontWeight-600': {
      fontWeight: 600,
    },
    '.fontWeight-bold': {
      fontWeight: 'bold',
    },
    '.mb-0-5': {
      marginBottom: theme.spacing(1),
    },
    '.mb-1': {
      marginBottom: theme.spacing(1),
    },
    '.mb-2': {
      marginBottom: theme.spacing(2),
    },
    '.mb-3': {
      marginBottom: theme.spacing(3),
    },
    '.mb-4': {
      marginBottom: theme.spacing(4),
    },
    '.mb-5': {
      marginBottom: theme.spacing(5),
    },
    '.mb-6': {
      marginBottom: theme.spacing(6),
    },
    '.MuiTableCell-root.tableCell-action': {
      padding: theme.spacing(0.25, 2),
    },
    'cursor-pointer': {
      cursor: 'pointer',
    },
    '.button-error': {
      background: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        background: theme.palette.error.dark,
      },
    },
    '.button-success': {
      background: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      '&:hover': {
        background: theme.palette.success.dark,
      },
    },
    '.button-primary': {
      background: theme.palette.primary.main,
      color: theme.palette.success.contrastText,
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
    '.iconButton-error': {
      color: theme.palette.error.dark,
    },
    '.iconButton-success': {
      color: theme.palette.success.dark,
    },
  },
}), { index: 999, });


const GlobalStyles = (): null => {
  useGlobalStyles();

  return null;
};


export default GlobalStyles;
