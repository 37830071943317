import { useTranslation, } from 'react-i18next';
import { Link as RouterLink, } from 'react-router-dom';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import BuildIcon from '@mui/icons-material/Build';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VerifiedIcon from '@mui/icons-material/Verified';
import PhoneIcon from '@mui/icons-material/Phone';

import { User, } from 'Tools/auth';
import { MENU_BUTTONS, } from '../constants';
import Logo from '../Logo';
import UserMenu from './UserMenu';
import { Phone } from '@material-ui/icons';


const useStyles = makeStyles((theme: Theme) => createStyles({
  toolbar: {
    padding: 0,
    display: 'flex',
    background: theme.palette.primary.main,
    justifyContent: 'space-between',
  },
  header: {
    color: '#fff',
    fontWeight: 600,
    height: '30px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#4B4B4B',
  },
}));


interface Props {
  user: User | null,
}


const Desktop = ({
  user,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t, } = useTranslation();

  return (
    <>
      <Toolbar variant="dense" className={classes.header} >
        <p><a style={{ color: '#fff'}} href="tel:+420731888887"><PhoneIcon style={{ padding: 0, margin: 0 }} fontSize="inherit" /> +420 731 888 887</a> </p> | <p> <BuildIcon style={{ padding: 0, margin: 0 }} fontSize="inherit" /> Prodej a distribuce originálních náhradních dílů </p>|<p> <InventoryIcon style={{ padding: 0, margin: 0 }} fontSize="inherit" /> Všechny díly skladem</p>|<p> <AccessTimeIcon style={{ padding: 0, margin: 0 }} fontSize="inherit" /> Dodání do 48 hodin </p>|<p> <VerifiedIcon style={{ padding: 0, margin: 0 }} fontSize="inherit" /> Pouze certifikované náhradní díly </p>
      </Toolbar>
      <Toolbar className={classes.toolbar}>

        <Logo />

        <nav>

          {MENU_BUTTONS.map((navLink) => ((!navLink.permission || user?.permissions[navLink.permission])
            ? (
              <Button
                key={navLink.id}
                variant="text"
                component={RouterLink}
                onClick={navLink.onClick}
                to={navLink.to}
              >
                {t(navLink.textKey)}
              </Button>
            ) : (
              null
            )
          ))}

          <UserMenu
            user={user}
          />

        </nav>

      </Toolbar>
    </>
  );
};


export default Desktop;
