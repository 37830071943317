import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

import AsyncImage from 'Components/AsyncImage';


const useStyles = makeStyles((theme: Theme) => createStyles({
  filePreview: {
    width: '100%',
    paddingTop: '67.5%',
    position: 'relative',
  },
  removeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.spacing(1),
    background: 'rgba(0, 0, 0, 0.24)',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.32)',
    },
  },
  fileWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
}));


interface Props {
  className?: string,
  file?: File | null,
  fileUrl?: string | null,
  loading?: boolean,
  onRemove?: () => void,
}


const ImagePreview = ({
  className,
  file,
  fileUrl,
  loading,
  onRemove,
}: Props): JSX.Element | null => {
  const classes = useStyles();
  const { t, } = useTranslation();

  const src = useMemo(
    () => {
      if (file) return URL.createObjectURL(file);
      if (fileUrl) return fileUrl;
      return null;
    },
    [ file, fileUrl, ],
  );


  if (!src) return null;

  return (
    <div className={clsx(classes.filePreview, className)}>
      <div className={classes.fileWrapper}>
        <AsyncImage
          className={classes.img}
          src={src}
        />
      </div>
      {onRemove && (
        <Tooltip title={<span>{t('common:action.delete')}</span>}>
          <IconButton
            className={clsx(classes.removeButton, 'iconButton-error')}
            disabled={loading}
            onClick={onRemove}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};


export default ImagePreview;
