import { useTranslation, } from 'react-i18next';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import MuiTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { FilterText, FilterSelect, SortLabel, } from 'Components/Table';
import { useOptionsYesNo, } from 'Utils/options/useOptionsYesNo';
import { OptionBoolean, } from 'Utils/types';
import { useOptionsActive, } from 'Utils/options/useOptionsActive';
import { ChangeSpacesFilter, SpacesFilter, } from '../../../store/useSpacesStore';
import { COLS, } from './constants';


const useStyles = makeStyles((theme: Theme) => createStyles({
  tableHead: {
    background: theme.palette.action.hover,
  },
}));

interface Props {
  filter: SpacesFilter,
  onChangeFilter: ChangeSpacesFilter,
}


const TableHead = ({
  filter,
  onChangeFilter,
}: Props): JSX.Element => {
  // utils
  const classes = useStyles();
  const { t, } = useTranslation();

  const optionYeasNo = useOptionsYesNo();
  const optionsActive = useOptionsActive();

  return (
    <MuiTableHead className={classes.tableHead}>
      <TableRow>
        <TableCell style={COLS.name}>
          <SortLabel
            onChange={onChangeFilter}
            name="name"
            sort={filter.sort}
          >
            {t('space:label.name')}
          </SortLabel>
        </TableCell>
        <TableCell style={COLS.status}>
          {t('space:label.labelActive')}
        </TableCell>
        <TableCell style={COLS.confirmed}>
          {t('common:table.approved')}
        </TableCell>
        <TableCell style={COLS.actions} />
      </TableRow>
      <TableRow>
        <TableCell>
          <FilterText
            name="name"
            value={filter.name}
            onChange={onChangeFilter}
          />
        </TableCell>
        <TableCell>
          <FilterSelect<OptionBoolean>
            name="active"
            onChange={onChangeFilter}
            options={optionsActive.options}
            getOptionLabel={optionsActive.getOptionLabel}
            getOptionSelected={optionsActive.getOptionSelected}
          />
        </TableCell>
        <TableCell>
          <FilterSelect<OptionBoolean>
            name="approved"
            onChange={onChangeFilter}
            options={optionYeasNo.options}
            getOptionLabel={optionYeasNo.getOptionLabel}
            getOptionSelected={optionYeasNo.getOptionSelected}
          />
        </TableCell>
        <TableCell />
      </TableRow>
    </MuiTableHead>
  );
};


export default TableHead;
