import { LocaleEnum, CurrencyEnum, Locales, Locale, Currencies, } from './types';


const CurrencySymbols = {
  [CurrencyEnum.CZK]: 'Kč',
};


const formatCurrencyCZK = (value: string): string => `${value} ${CurrencySymbols[CurrencyEnum.CZK]}`;


export const CURRENCIES: Currencies = {
  [CurrencyEnum.CZK]: {
    code: CurrencyEnum.CZK,
    symbol: CurrencySymbols[CurrencyEnum.CZK],
    format: formatCurrencyCZK,
  },
};


export const LOCALES: Locales = {
  [LocaleEnum.cs]: {
    key: LocaleEnum.cs,
    code: LocaleEnum.cs,
    label: 'Čeština',
    currency: CURRENCIES.CZK,
  },
};


export const LOCALE_LIST: Locale[] = Object.values(LOCALES);
