import { Form } from "formik";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import UserIcon from "@material-ui/icons/PersonOutlineOutlined";
import Paper from "@material-ui/core/Paper";

import { GqlErrorCodeEnum } from "Apollo/graphql";
import Link from "Components/Link";
import { eventPreventDefault } from "Utils/events";
import { ROUTES } from "Utils/globals";
import { FormInputText, FormSubmit, FormError } from "Components/Form";
import InitLayout from "../../components/InitLayout";

const customErrorMessage = {
  [GqlErrorCodeEnum.BadLoginInput]: {
    key: "auth:register.badCredentials",
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(6, 4),
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      marginBottom: theme.spacing(1),
    },
    inputIcon: {
      pointerEvents: "none",
    },
    submit: {
      marginTop: theme.spacing(1),
    },
  })
);

const View = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <InitLayout>
      <Paper className={classes.paper} elevation={6}>
        <Typography
          className={classes.title}
          variant="h3"
          color="primary"
          align="center"
        >
          {t("auth:register.title")}
        </Typography>

        <Form className={classes.form}>

          <FormInputText
            variant="outlined"
            name="company"
            autoComplete="company"
            margin="normal"
            autoFocus
            label={t("auth:register.company")}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.inputIcon}>
                  <UserIcon />
                </InputAdornment>
              ),
            }}
          />

          <FormInputText
            variant="outlined"
            name="email"
            autoComplete="username"
            margin="normal"
            autoFocus
            label={t("auth:register.username")}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.inputIcon}>
                  <UserIcon />
                </InputAdornment>
              ),
            }}
          />

          <FormSubmit
            fullWidth
            color="primary"
            size="large"
            className={classes.submit}
          >
            {t("auth:register.submit")}
          </FormSubmit>

          <FormError
            alert
            boxProps={{
              mt: 1,
            }}
            apolloErrorMessages={customErrorMessage}
          />
        </Form>

        <Link
          onMouseDown={eventPreventDefault}
          color="textSecondary"
          variant="body1"
          to={`/${ROUTES.LOGIN}`}
        >
          {t("auth:register.linkLogin")}
        </Link>
        <br/>
        <Link
          onMouseDown={eventPreventDefault}
          color="textSecondary"
          variant="body1"
          to={`/${ROUTES.FORGOTTEN_PASSWORD}`}
        >
          {t("auth:register.linkForgottenPassword")}
        </Link>
      </Paper>
    </InitLayout>
  );
};

export default View;
