import { Collapse, TableCell } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Part } from "Apollo/graphql";

interface Props {
  isOpen: boolean;
  part: Part;
}

const CollapsibleDetail = ({ isOpen, part }: Props) => {
  const { t } = useTranslation("part");

  const { height, length, width, weight, name } = part;

  return (
    <TableRow>
      <TableCell colSpan={5} style={{ padding: 0 }}>
        <Collapse in={isOpen} unmountOnExit>
          <Table sx={{ width: "100%", tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "150px", fontWeight: 600 }}>
                  {t("partDetail.name")}
                </TableCell>
                <TableCell style={{ width: "150px", fontWeight: 600 }}>
                  {t("partDetail.height")}
                </TableCell>
                <TableCell style={{ width: "150px", fontWeight: 600 }}>
                  {t("partDetail.length")}
                </TableCell>
                <TableCell style={{ width: "150px", fontWeight: 600 }}>
                  {t("partDetail.width")}
                </TableCell>
                <TableCell style={{ width: "150px", fontWeight: 600 }}>
                  {t("partDetail.weight")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{name ?? "-"}</TableCell>
                <TableCell>{height ?? "-"}</TableCell>
                <TableCell>{length ?? "-"}</TableCell>
                <TableCell>{width ?? "-"}</TableCell>
                <TableCell>{weight ?? "-"}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default CollapsibleDetail;