import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { OrderFragment, PartFragment } from "Apollo/graphql";
import { useTranslation } from "react-i18next";
import PartsDataTable from "../components/PartsDataTable";
import { OrderStore } from "../store/types";

interface Props {
  changePhase: OrderStore["changePhase"];
  missingParts: string[];
  order: OrderFragment;
  removePartsFromStore: OrderStore["removePartsFromStore"];
}

export const PartsMissingView = ({
  changePhase,
  missingParts,
  order,
  removePartsFromStore,
}: Props): JSX.Element => {
  const { t } = useTranslation("order");
  const missingPartsList = Array.from(new Set(missingParts))
    .map((part) => order.parts.find((p) => p.number === part))
    .filter(Boolean) as PartFragment[];

  const missingPartsIds = missingPartsList.map((part) => part.id);

  const handleContinue = (ids: string[]) => {
    removePartsFromStore(ids);
    changePhase("payment");
  };
  if (missingPartsList.length === 0) {
    return (
      <Box textAlign="center">
        <Typography
          variant="h3"
          sx={(theme) => ({
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down("sm")]: {
              fontSize: theme.spacing(4),
            },
          })}
        >
          {t("invalidOrderTitle")}
        </Typography>
        <Typography variant="body1" marginBottom={2}>
          {t("invalidOrderInfo")}
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            color="inherit"
            variant="contained"
            onClick={() => changePhase("build")}
          >
            {t("common:action.back")}
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box textAlign="center">
      <Typography
        variant="h3"
        sx={(theme) => ({
          marginBottom: theme.spacing(2),
          [theme.breakpoints.down("sm")]: {
            fontSize: theme.spacing(4),
          },
        })}
      >
        {t("missingPartsTitle")}
      </Typography>
      <Typography variant="body1" marginBottom={2}>
        {t("missingPartsInfo")}
      </Typography>
      <PartsDataTable parts={missingPartsList} shippingPrice={order.shippingPrice} />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        gap={2}
        marginTop={2}
      >
        <Button
          color="inherit"
          variant="contained"
          onClick={() => changePhase("build")}
        >
          {t("common:action.back")}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => handleContinue(missingPartsIds)}
        >
          {t("common:action.ok")}
        </Button>
      </Box>
    </Box>
  );
};
