import { ReactNode, } from 'react';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { ReactComponent as Logo, } from 'Utils/icons/logo.svg';


const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  head: {
    position: 'relative',
    padding: theme.spacing(2, 0),
  },
  logo: {
    height: 50,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(5, 1, 1, 1),
    display: 'flex',
    width: '100%',
    alignItems: 'stretch',
  },
  fox: {
    width: '100%',
  },
  paper: {
    width: 450,
    minWidth: 450,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: '100%',
    },
  },
  side: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
}));


type Props = {
  children: ReactNode,
};


const InitLayout = ({ children, }: Props): JSX.Element => {
  const classes = useStyles();


  return (
    <main>
      <Container className={classes.container}>
        <div className={classes.head}>
          <Logo className={classes.logo} />
        </div>
        <div className={classes.content}>
          <div className={classes.side} />
          <div className={classes.paper}>
            {children}
          </div>
          <div className={classes.side}>
          </div>
        </div>
      </Container>
    </main>
  );
};


export default InitLayout;
