import { useState, useEffect, } from 'react';
import { useApolloClient, } from '@apollo/client';

import {
  CurrentUserDocument,
  CurrentUserQuery,
  CurrentUserQueryVariables,
  RefreshDocument,
  RefreshMutation,
} from 'Apollo/graphql';
import {
  removeAccessToken,
  setAccessToken,
  AuthStore,
  useAuthStore,
} from '../store/authStore';


// selectors
const selectorSetUser = (state: AuthStore) => state.setUser;


export const useInitUser = (): boolean => {
  const [ loading, setLoading, ] = useState(true);

  const setUser = useAuthStore(selectorSetUser);
  const apolloClient = useApolloClient();


  useEffect(() => {
    const initUser = async () => {
      try {
        // refresh
        const responseRefresh = await apolloClient.mutate<RefreshMutation, undefined>({
          mutation: RefreshDocument,
          fetchPolicy: 'no-cache',
        });

        const accessToken = responseRefresh.data?.refresh.accessToken;

        if (!accessToken) throw new Error('No accessToken');
        setAccessToken(accessToken);


        // user
        const responseUser = await apolloClient.query<CurrentUserQuery, CurrentUserQueryVariables>({
          query: CurrentUserDocument,
          fetchPolicy: 'no-cache',
        });

        const user = responseUser.data.currentUser;
        if (!user) throw new Error('No user');
        setUser(user);

        setLoading(false);
      } catch {
        removeAccessToken();
        setUser(null);
        setLoading(false);
      }
    };

    initUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return loading;
};
