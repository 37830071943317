import Alert from '@material-ui/lab/Alert';
import Box, { BoxProps, } from '@material-ui/core/Box';

import { useErrorMessage, UseErrorMessageProps, } from 'Tools/useErrorMessage';


interface Props extends UseErrorMessageProps {
  boxProps?: BoxProps,
}


export const ErrorMessageAlert = ({
  boxProps,
  ...rest
}: Props): JSX.Element | null => {
  const errorNode = useErrorMessage(rest);


  if (!errorNode) return null;

  return (
    <Box {...boxProps}>
      <Alert
        severity="error"
      >
        {errorNode}
      </Alert>
    </Box>
  );
};


export default ErrorMessageAlert;
