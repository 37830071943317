import React, { Component, } from 'react';


import AppEventContext from './AppEventContext';


type Store = {
  [key: string]: () => void,
};


type Props = {
  children: React.ReactNode,
};


class AppEventProvider extends Component<Props> {
  store: Store = {};


  subscribe = (eventName: string, cb: () => void): void => {
    this.store[eventName] = cb;
  };


  unsubscribe = (eventName: string): void => {
    if (Object.prototype.hasOwnProperty.call(this.store, eventName)) {
      delete this.store[eventName];
    }
  };


  emit = (eventName: string): void => {
    if (Object.prototype.hasOwnProperty.call(this.store, eventName)) {
      this.store[eventName]();
    }
  };


  render(): JSX.Element {
    const { children, } = this.props;

    return (
      <AppEventContext.Provider
        value={{
          subscribe: this.subscribe,
          unsubscribe: this.unsubscribe,
          emit: this.emit,
        }}
      >
        {children}
      </AppEventContext.Provider>
    );
  }
}


export default AppEventProvider;
