import FormHelperText from '@material-ui/core/FormHelperText';

import { Box, } from '@material-ui/core';
import { useErrorMessage, UseErrorMessageProps, } from 'Tools/useErrorMessage';


export interface ErrorMessageProps extends UseErrorMessageProps {
  textAlign?: string,
}

export const ErrorMessage = ({
  textAlign = 'right',
  ...rest
}: ErrorMessageProps): JSX.Element | null => {
  const errorNode = useErrorMessage(rest);


  if (!errorNode) return null;

  return (
    <Box textAlign={textAlign}>
      <FormHelperText error component="span">
        {errorNode}
      </FormHelperText>
    </Box>
  );
};


export default ErrorMessage;
