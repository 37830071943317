/* eslint-disable react/destructuring-assignment */
import { useFormikContext, } from 'formik';
import { ApolloError, } from '@apollo/client';
import { BoxProps, } from '@material-ui/core';

import ErrorMessage, { ErrorMessageProps, } from 'Components/ErrorMessage';
import ErrorMessageAlert from 'Components/ErrorMessageAlert';


interface Props extends ErrorMessageProps {
  alert?: boolean
  boxProps?: BoxProps
}


export const FormErrorMessage = ({
  alert,
  boxProps,
  ...rest
}: Props): JSX.Element | null => {
  const { status, isValid, submitCount } = useFormikContext();
  const customError = !isValid && submitCount > 0
    ? {
      key: 'errors:invalidForm',
    } : null;


  const showApolloError = !!status && status?.error instanceof ApolloError;
  const error = (status && !showApolloError) || rest.error;

  if (alert) {
    return (
      <ErrorMessageAlert
        {...rest}
        boxProps={boxProps}
        error={error}
        apolloError={showApolloError ? status?.error : rest?.apolloError}
        errorMessage={customError || rest.errorMessage}
      />
    );
  }

  return (
    <ErrorMessage
      {...rest}
      error={error}
      textAlign="right"
      apolloError={showApolloError ? status?.error : rest?.apolloError}
      errorMessage={customError || rest.errorMessage}
    />
  );
};


export default FormErrorMessage;
