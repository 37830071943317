import { ReactNode, } from 'react';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { PartInfo, } from 'Components/Parts';


interface Props {
  colSpan: number,
  minHeight?: string,
  children?: ReactNode,
}


const TableEmpty = ({
  colSpan,
  minHeight = '10rem',
  children,
}: Props): JSX.Element => (
  <MuiTableBody>
    <MuiTableRow>
      <TableCell colSpan={colSpan}>
        <PartInfo minHeight={minHeight}>
          {children}
        </PartInfo>
      </TableCell>
    </MuiTableRow>
  </MuiTableBody>
);


export default TableEmpty;
