import { Modal, } from 'Components/Modal';

import { CurrentUserStore, useCurrentUserStore, } from '../../store/useCurrentUserStore';
import Logic from './Logic';


const selectorModalData = (store: CurrentUserStore) => (store.modalChangePassword);
const selectorClose = (store: CurrentUserStore) => (store.closeModalChangePassword);


const ModalChangePassword = (): JSX.Element | null => {
  const modalData = useCurrentUserStore(selectorModalData);
  const close = useCurrentUserStore(selectorClose);


  if (!modalData.isOpen) return null;

  return (
    <Modal
      open
      maxWidth="xs"
    >
      <Logic
        onClose={close}
      />
    </Modal>
  );
};


export default ModalChangePassword;
