import { useUsersQuery, } from 'Apollo/graphql';
import { APP_EVENTS, useListenAppEvent, } from 'Tools/appEvent';
import ModalUserExport from 'Modules/Users/modals/ModalUserExport';
import { User, } from 'Tools/auth';
import { UserStore, useUserStore, } from '../../../store/useUserStore';
import TableLayout from './TableLayout';


interface Props {
  user: User | null,
}


const selectorFilter = (store: UserStore) => (store.filter);
const selectorChangeFilter = (store: UserStore) => (store.changeFilter);


const OrganizationsTable = ({
  user,
}: Props): JSX.Element => {
  const filter = useUserStore(selectorFilter);
  const changeFilter = useUserStore(selectorChangeFilter);

  const usersQuery = useUsersQuery({
    fetchPolicy: 'no-cache',
    variables: {
      usersInput: {
        filter: {
          email: filter.email ? filter.email : undefined,
          active: filter.active?.value,
          role: filter.role?.id,
          spaceId: filter.space?.id,
        },
        sort: {
          by: filter.sort.by,
          order: filter.sort.order,
        },
      },
      paginationInput: {
        limit: filter.limit,
        offset: filter.offset,
      },
    },
  });


  useListenAppEvent(
    APP_EVENTS.USER_LIST,
    usersQuery.refetch
  );

  return (
    <>
      <ModalUserExport
        usersQuery={usersQuery}
      />
      <TableLayout
        user={user}
        filter={filter}
        usersQuery={usersQuery}
        onChangeFilter={changeFilter}
      />
    </>
  );
};


export default OrganizationsTable;
