import { FORM_VALIDATORS, } from 'Components/Form';
import * as Yup from 'yup';


export const initForgotPasswordFormValues = {
  email: '',
};


export const validationSchemeForgotPasswordForm = Yup.object().shape({
  email: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .email(FORM_VALIDATORS.EMAIL),
});
