import { useState, } from 'react';
import { useExportUsersMutation, } from 'Apollo/graphql';
import { EXPORT_ITEMS, } from 'Utils/globals';
import { exportVariables, } from './exportVariables';
import View from './View';
import LinkView from './LinkView';
import { UserFilter, } from '../../store/useUserStore';

interface Props {
  onClose: () => void,
  filter: UserFilter,
  totalCount?: number
  actualPageCount?: number
}

const Logic = ({
  onClose,
  filter,
  totalCount,
  actualPageCount,
}: Props): JSX.Element => {
  const [ pageToExport, setPageToExport, ] = useState<string>(EXPORT_ITEMS.ALL_PAGES);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageToExport((event.target as HTMLInputElement).value);
  };

  const [ exportMutation, { loading, error, data, }, ] = useExportUsersMutation();

  const handleSubmit = () => {
    exportMutation({
      variables: exportVariables(pageToExport, filter),
    });
  };

  if (data?.exportUsers) {
    return (
      <LinkView
        link={data.exportUsers}
        onClose={onClose}
      />
    );
  }

  return (
    <View
      loading={loading}
      error={error}
      pageToExport={pageToExport}
      totalCount={totalCount}
      actualPageCount={actualPageCount}
      onClose={onClose}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};

export default Logic;

