import { LazyQueryResult } from "@apollo/client/react";
import {
  AdminTableOrderFragment,
  Exact,
  GetOrdersForIntervalQuery,
} from "Apollo/graphql";
import ErrorMessage from "Components/ErrorMessage";
import LoadingBoxWithMessage from "Components/LoadingBoxWithMessage";
import { DateInterval } from "Modules/ManageIncomingOrders/store/types";
import { useTranslation } from "react-i18next";
import OrderTable from "Components/AdminOrderTable/OrderTable";


interface Props {
  editOrder: (id: string) => void;
  orders: AdminTableOrderFragment[];
  dateInterval: DateInterval | null,
  result: LazyQueryResult<
    GetOrdersForIntervalQuery,
    Exact<{
      from: any;
      to: any;
    }>
  >;
}

const AdminOrderTable = ({ orders, result,dateInterval,editOrder }: Props): JSX.Element => {
  const { t, } = useTranslation("common")
  if (result.loading) return <LoadingBoxWithMessage label={t('common:loading.loadingOrderTable')}/>;
  if (result.error || !orders)
    return <ErrorMessage apolloError={result.error} />
  return <OrderTable orders={orders} editOrder={editOrder}/>;
};

export default AdminOrderTable;
