import React from 'react';
import { useField, useFormikContext, } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps, } from '@material-ui/core/Checkbox';


interface Props extends Omit<CheckboxProps, 'onChange'> {
  label?: React.ReactNode,
  name: string
}


const FormInputCheckbox: React.FC<Props> = ({
  label,
  name,
  color = 'primary',
  ...rest
}: Props) => {
  const { setFieldValue, } = useFormikContext();

  const [ field, ] = useField({
    name,
  });
  // change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, e.target.checked);
  };


  return (
    <FormControlLabel
      control={(
        <Checkbox
          checked={field.value}
          onChange={handleChange}
          color={color}
          {...rest}
        />
      )}
      label={label}
    />
  );
};


export default FormInputCheckbox;
