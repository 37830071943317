import { useCallback, } from 'react';
import { useSnackbar, } from 'notistack';
import { useTranslation, } from 'react-i18next';
import { useApolloClient, } from '@apollo/client';
import { Formik, FormikHelpers, } from 'formik';

import {
  EditUserDocument,
  EditUserMutation,
  UserFragment,
} from 'Apollo/graphql';
import { APP_EVENTS, useEmitAppEvent, } from 'Tools/appEvent';
import { roleOptions, } from 'Utils/options/useRoleOptions';
import { useUser, } from 'Tools/auth';
import {
  initUserForm,
  validationSchemeUserForm,
  validationSchemeUserFormAdmin,
} from '../../utils/constants';
import { userInputVariables, } from '../../utils/variables';
import { UserForm, } from '../../types';
import View from './View';

interface Props {
  onClose: () => void,
  user: UserFragment,
}


const Logic = ({
  user,
  onClose,
}: Props): JSX.Element => {
  const apolloClient = useApolloClient();
  const emitAppEvent = useEmitAppEvent();
  const { t, } = useTranslation();
  const { enqueueSnackbar, } = useSnackbar();
  const currentUser = useUser();

  const handleSubmit = useCallback(
    async (
      values: UserForm,
      { setStatus, }: FormikHelpers<UserForm>,
    ) => {
      try {
        setStatus(undefined);
        await apolloClient.mutate<EditUserMutation>({
          mutation: EditUserDocument,
          variables: {
            id: user.id,
            userInput: userInputVariables(values, currentUser),
          },
        });
        emitAppEvent?.(APP_EVENTS.USER_LIST);
        enqueueSnackbar(t('user:usersTable.notificationEdited'), { variant: 'success', });

        onClose();
      } catch (error) {
        setStatus({ error, });
      }
    },
    [ apolloClient, onClose, user, emitAppEvent, enqueueSnackbar, t, currentUser, ]
  );

  const role = roleOptions.find((o) => user.role === o.id);

  return (
    <Formik<UserForm>
      initialValues={{
        ...initUserForm,
        email: user.email,
        role: role || null,
        space: user.space || null,
      }}
      onSubmit={handleSubmit}
      validationSchema={currentUser?.permissions.USERS_MANAGE
        ? validationSchemeUserFormAdmin
        : validationSchemeUserForm
      }
    >
      <View
        currentUser={currentUser}
        onClose={onClose}
      />
    </Formik>
  );
};


export default Logic;
