import { useTranslation, } from 'react-i18next';
import Button from '@material-ui/core/Button';

import { ModalHeader, ModalBody, ModalFooter, } from 'Components/Modal';
import ImportZone from './ImportZone';


interface Props {
  onClose: () => void,
}


const View = ({
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation();


  return (
    <>

      <ModalHeader onClose={onClose}>
        {t('user:importModal.title')}
      </ModalHeader>

      <ModalBody disablePaddingBottom>
        <ImportZone />
      </ModalBody>

      <ModalFooter>
        <Button
          variant="outlined"
          color="default"
          onClick={onClose}
        >
          {t('common:action.back')}
        </Button>
      </ModalFooter>

    </>
  );
};


export default View;
