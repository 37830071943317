import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useAcceptBillingInfoAndCalculateShippingPriceMutation } from "Apollo/graphql";
import ErrorMessage from "Components/ErrorMessage";
import { FormError, FormSubmit } from "Components/Form";
import LoadingBoxWithMessage from "Components/LoadingBoxWithMessage";
import PartsTable from "Modules/Order/components/PartsDataTable/PartsTable";
import { useOrderStore } from "Modules/Order/store";
import { OrderStore } from "Modules/Order/store/types";
import { useUser } from "Tools/auth/hooks/useUser";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OwnerBillingInfo from "../../components/OwnerBillingInfo";
import BillingDataUserForm from "./BillingDataUserForm";
import {
  BillingDataForm,
  INITIAL_BILLING_DATA_FORM,
  billingInfoFragmentToBillingDataForm,
  validationSchemaBillingData,
} from "./utils";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import ErrorAlert from "Components/ErrorAlert";

const selectStoreData = (s: OrderStore) => ({
  changePhase: s.changePhase,
  orderId: s.orderId,
  setBillingInfo: s.setBillingInfo,
  setShippingPrice: s.setShippingPrice,
  billingInfo: s.billingInfo,
});

const Logic = (): JSX.Element => {
  const user = useUser();
  const { t } = useTranslation();
  const {
    changePhase,
    orderId,
    setBillingInfo,
    setShippingPrice,
    billingInfo,
  } = useOrderStore(selectStoreData);
  const [initialValues, setInitialValues] = useState(
    INITIAL_BILLING_DATA_FORM ||
      billingInfoFragmentToBillingDataForm(billingInfo)
  );
  useEffect(() => {
    setInitialValues(billingInfoFragmentToBillingDataForm(billingInfo));
  }, [billingInfo]);

  const [mutation, result] =
    useAcceptBillingInfoAndCalculateShippingPriceMutation({
      onCompleted(data) {
        if (
          data.acceptBillingInfoAndCalculateShippingPrice.order?.billingInfo
        ) {
          setBillingInfo(
            data.acceptBillingInfoAndCalculateShippingPrice.order.billingInfo
          );
        }

        setShippingPrice(
          data.acceptBillingInfoAndCalculateShippingPrice.order?.shippingPrice ?? 0
        );

        if (data.acceptBillingInfoAndCalculateShippingPrice.success)
          changePhase("checkOrder");
      },
    });

  const onSubmit = async (
    values: BillingDataForm,
    { setStatus }: FormikHelpers<BillingDataForm>
  ) => {
    await mutation({
      variables: {
        billingInfoInput: { ...values, orderId, spaceId: user?.space.id || "" },
      },
    }).catch((err) => {
      console.log(err);
      setStatus({ error: err });
    });
  };
  if (result.loading)
    return (
      <LoadingBoxWithMessage label={t("common:loading.loadingTransportCost")} />
    );
  if (result.error) return <ErrorAlert error={result.error} />;
  return (
    <Box>
      <Formik<BillingDataForm>
        initialValues={initialValues}
        validationSchema={validationSchemaBillingData}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Form>
          <Box
            sx={(theme) => ({
              marginBottom: theme.spacing(12),
              [theme.breakpoints.down("sm")]: {
                marginBottom: theme.spacing(9),
              },
            })}
          >
            <Box
              marginBottom={2}
              marginTop={10}
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="1rem"
              marginLeft={2}
            >
              <Typography variant="h3">{t("billing:title")}</Typography>
              <Typography variant="body1">{t("billing:info")}</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-evenly"
            >
              <OwnerBillingInfo />
              <Divider
                orientation="vertical"
                flexItem
                sx={{ margin: "0 2rem 0 2rem" }}
              />
              <BillingDataUserForm />
              <FormError />
            </Box>
            <PartsTable />
            {result.data?.acceptBillingInfoAndCalculateShippingPrice.errors && (
              <Box>
                {result.data?.acceptBillingInfoAndCalculateShippingPrice.errors.map(
                  (error) => (
                    <Alert severity="error">{error}</Alert>
                  )
                )}
              </Box>
            )}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              marginTop={5}
            >
              <FormSubmit color="primary">
                {t("common:action.create")}
              </FormSubmit>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              marginTop={2}
              marginBottom={2}
            >
              <Button
                color="inherit"
                variant="contained"
                onClick={() => changePhase("build")}
              >
                {t("common:action.back")}
              </Button>
            </Box>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};
export default Logic;
