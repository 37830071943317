import { useCallback, } from 'react';
import { useApolloClient, MutationOptions, } from '@apollo/client';

import {
  LoginDocument,
  LoginMutation,
  MutationLoginArgs,
  CurrentUserDocument,
  CurrentUserQueryVariables,
  CurrentUserQuery,
} from 'Apollo/graphql';
import {
  removeAccessToken,
  setAccessToken,
  AuthStore,
  useAuthStore,
} from '../store/authStore';


type Login = (options: Omit<MutationOptions<LoginMutation, MutationLoginArgs>, 'mutation' | 'fetchPolicy'>) => Promise<void>;


const selectorSetUser = (state: AuthStore) => state.setUser;


export const useLogin = (): Login => {
  const setUser = useAuthStore(selectorSetUser);
  const apolloClient = useApolloClient();


  const login = useCallback(
    async (options = {}) => {
      try {
        // authenticate
        const responseLogin = await apolloClient.mutate<LoginMutation, MutationLoginArgs>({
          ...options,
          mutation: LoginDocument,
          fetchPolicy: 'no-cache',
        });

        const accessToken = responseLogin.data?.login.token.accessToken;

        if (!accessToken) throw new Error('No accessToken');
        setAccessToken(accessToken);


        // user
        const responseUser = await apolloClient.query<CurrentUserQuery, CurrentUserQueryVariables>({
          query: CurrentUserDocument,
          fetchPolicy: 'no-cache',
        });

        const user = responseUser.data.currentUser;

        if (!user) throw new Error('No user');
        setUser(user);

      } catch (error) {
        removeAccessToken();
        setUser(null);
        throw error;
      }
    },
    [ apolloClient, setUser, ],
  );


  return login;
};
