/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Form, } from 'formik';
import { makeStyles, createStyles, } from '@material-ui/core/styles';
import { useTranslation, Trans, } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useUser, } from 'Tools/auth';
import { ModalHeader, ModalBody, ModalFooter, } from 'Components/Modal';
import { FormSubmit, FormError, FormInputText, } from 'Components/Form';


const useStyles = makeStyles(() => createStyles({
  inputIcon: {
    pointerEvents: 'none',
  },
}));


const View = (): JSX.Element => {
  const { t, } = useTranslation();
  const classes = useStyles();
  const user = useUser();

  const gdprConfirmed = user?.gdprConfirmed || false;


  return (
    <>

      <ModalHeader>
        {t('currentUser:modalChangePassword.title')}
      </ModalHeader>

      <Form>

        <ModalBody>

          <Typography
            gutterBottom
            color="textSecondary"
          >
            {t('currentUser:modalChangePassword.info')}
          </Typography>

          <FormInputText
            name="oldPassword"
            type="password"
            margin="normal"
            label={t('currentUser:modalChangePassword.labelOldPassword')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.inputIcon}>
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />

          <FormInputText
            name="newPassword"
            type="password"
            margin="normal"
            label={t('currentUser:modalChangePassword.labelNewPassword')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.inputIcon}>
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />

          <FormInputText
            name="newPasswordCheck"
            type="password"
            margin="normal"
            label={t('currentUser:modalChangePassword.labelNewPasswordCheck')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.inputIcon}>
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />

          <Grid container justify="center" spacing={1}>
            <Typography
              gutterBottom
              color="textSecondary"
            >
              {!gdprConfirmed && (
                <Trans
                  i18nKey="currentUser:modalChangePassword.gdpr"
                  components={{
                    a: (
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://seznam.cz"
                      />
                    ),
                  }}
                />
              )}
            </Typography>
          </Grid>

        </ModalBody>

        <ModalFooter>
          <Grid container justify="flex-end" spacing={2}>
            <Grid item>
              <FormSubmit
                color="primary"
              >
                {t('currentUser:modalChangePassword.continue')}
              </FormSubmit>
            </Grid>
          </Grid>
          <FormError />
        </ModalFooter>

      </Form>

    </>
  );
};
export default View;
