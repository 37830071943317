import Grid from "@material-ui/core/Grid";
import { BillingInfoFragment } from "Apollo/graphql";
import UneditableText from "Components/Form/inputs/UneditableText";
import { useTranslation } from "react-i18next";

interface Props {
  data: BillingInfoFragment
}

const BillingInfo = (data:Props) => {
  const { name, city, dic, email, ico, psc, street, telephone } = data.data
  const { t, } = useTranslation("billing")
  return (
      <Grid container spacing={2} direction="column">
        <Grid item>
          <UneditableText label={t("taxInfo.name")} value={name || ""} />
        </Grid>
        <Grid item>
          <UneditableText label={t("taxInfo.email")} value={email || ""} />
        </Grid>
        <Grid item>
          <UneditableText label={t("taxInfo.telephone")} value={telephone || ""} />
        </Grid>
        <Grid item>
          <UneditableText label={t("taxInfo.city")} value={city || ""} />
        </Grid>
        <Grid item>
          <UneditableText label={t("taxInfo.psc")} value={psc || ""} />
        </Grid>
        <Grid item>
          <UneditableText label={t("taxInfo.street")} value={street || ""} />
        </Grid>
        <Grid item>
          <UneditableText label={t("taxInfo.dic")} value={dic || ""} />
        </Grid>
        <Grid item>
          <UneditableText label={t("taxInfo.ico")} value={ico || ""} />
        </Grid>
      </Grid>
  );
};

export default BillingInfo;
