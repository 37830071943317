import { useTranslation, } from 'react-i18next';
import { Form, } from 'formik';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Typography, } from '@material-ui/core';

import { FormInputText, FormSubmit, FormError, } from 'Components/Form';


const useStyles = makeStyles((theme: Theme) => createStyles({
  alert: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  inputIcon: {
    pointerEvents: 'none',
  },
  submit: {
    marginTop: theme.spacing(1),
  },
}));


interface Props {
  isSent: boolean,
}


const ViewForm = ({
  isSent,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t, } = useTranslation();


  if (isSent) {
    return (
      <Alert
        className={classes.alert}
        variant="outlined"
        severity="success"
      >
        <AlertTitle>
          {t('auth:resetPassword.successTitle')}
        </AlertTitle>
        {t('auth:resetPassword.successText')}
      </Alert>
    );
  }

  return (
    <Form>

      <Typography
        color="textSecondary"
      >
        {t('auth:resetPassword.infoText')}
      </Typography>

      <FormInputText
        name="password"
        type="password"
        size="medium"
        margin="normal"
        autoFocus
        label={t('auth:label.password')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputIcon}>
              <LockOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />

      <FormInputText
        name="passwordCheck"
        type="password"
        size="medium"
        margin="normal"
        label={t('auth:label.passwordCheck')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputIcon}>
              <LockOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />

      <FormSubmit
        fullWidth
        color="primary"
        className={classes.submit}
        size="large"
      >
        {t('auth:resetPassword.submit')}
      </FormSubmit>

      <FormError
        alert
        boxProps={{
          mt: 1,
        }}
      />

    </Form>
  );
};


export default ViewForm;
