import { ReactNode, } from 'react';
import Button from '@material-ui/core/Button';
import { useTranslation, } from 'react-i18next';

import { PartLoading, PartLoadingProps, } from 'Components/Parts';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';


interface Props extends PartLoadingProps {
  label?: ReactNode
  onClose: () => void,
}

const ModalLoading = ({
  onClose,
  label,
  ...rest
}: Props): JSX.Element => {
  const { t, } = useTranslation();
  return (
    <>
      <ModalBody>
        <PartLoading {...rest} />
      </ModalBody>
      <ModalFooter>
        <Button
          variant="outlined"
          color="default"
          onMouseDown={onClose}
          size="medium"
        >
          {label || t('common.action.close')}
        </Button>
      </ModalFooter>
    </>
  );
};


export default ModalLoading;
