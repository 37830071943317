import create from 'zustand';
import jwtDecode from 'jwt-decode';

import { AuthUserFragment, } from 'Apollo/graphql';
import { User, DecodedToken, } from '../types';


/**
 * Utils
 */
const parseUser = (user: AuthUserFragment | null): User | null => {
  if (!user) return null;

  const permissions = user.permissions.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: true,
    }),
    {},
  );

  return {
    ...user,
    permissions,
  };
};

/**
 * Types
 */
export type AuthStore = {
  accessToken: string | null,
  user: User | null,
  setAccessToken: (accessToken: string | null) => void
  setUser: (user: AuthUserFragment | null) => void
};


/**
 * Store
 */
export const useAuthStore = create<AuthStore>((set) => ({
  // state
  accessToken: null,
  user: null,


  // methods
  setAccessToken: (accessToken) => set({
    accessToken,
  }),

  setUser: (newUser = null) => set({
    user: parseUser(newUser),
  }),
}));


/**
 * Modifiers, Getters
 */
export const getAccessToken = (): string | null => useAuthStore.getState().accessToken;

export const setAccessToken = (accessToken: string): void => useAuthStore.getState().setAccessToken(accessToken);

export const removeAccessToken = (): void => useAuthStore.getState().setAccessToken(null);

export const getSpaceId = (): string | null => useAuthStore.getState().user?.space.id || null;


export const isAccessTokenValidOrUndefined = (): boolean => {
  const token = getAccessToken();
  if (!token) {
    return true;
  }

  try {
    const { exp, } = jwtDecode(token) as DecodedToken;
    const expiredDate = (exp * 1000) - 2000;
    if (Date.now() >= expiredDate) {
      return false;
    }
    return true;
  } catch {
    return false;
  }
};
