import { useGetCurrentOrderQuery } from "Apollo/graphql";
import ErrorMessage from "Components/ErrorMessage";
import LoadingBoxWithMessage from "Components/LoadingBoxWithMessage";
import { useUser } from "Tools/auth";
import { useTranslation } from "react-i18next";
import { useOrderStore } from "../store";
import { OrderStore } from "../store/types";
import View from "./View";

const selectStoreData = (s: OrderStore) => ({
  updateOrder: s.updateOrder,
  changePhase: s.changePhase,
  id: s.orderId,
  parts: s.parts
});

const Logic = (): JSX.Element => {
  const { t,} = useTranslation("common")
  const { updateOrder, changePhase, id, parts } = useOrderStore(selectStoreData);

  const user = useUser();
  const { data, error, loading } = useGetCurrentOrderQuery({
    variables: { userId: user?.id || "" },
    onCompleted(data) {
      updateOrder(data.getCurrentOrder);
    },
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <LoadingBoxWithMessage label={t('loading.loadingOrder')}/>;
  if (error || !data) return <ErrorMessage apolloError={error} />;

  return <View changePhase={changePhase} id={id} parts={parts}/>;
};

export default Logic;
