import { useTranslation, } from 'react-i18next';
import { makeStyles, createStyles, Theme, } from '@material-ui/core/styles';
import MuiTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FilterText, FilterSelect, SortLabel, } from 'Components/Table';
import { OptionBoolean, } from 'Utils/types';
import { User, } from 'Tools/auth';
import { SpaceOptionFragment, } from 'Apollo/graphql';
import { useRoleOptions, } from 'Utils/options/useRoleOptions';
import { useOptionsActive, } from 'Utils/options/useOptionsActive';
import { useSpaceOptions, } from 'Utils/options/useSpaceOptions';
import { UserRoleType, } from '../../../types';
import { ChangeUserFilter, UserFilter, } from '../../../store/useUserStore';
import { COLS, } from './constants';


const useStyles = makeStyles((theme: Theme) => createStyles({
  tableHead: {
    background: theme.palette.action.hover,
  },
}));

interface Props {
  user: User | null,
  filter: UserFilter,
  onChangeFilter: ChangeUserFilter,
}


const TableHead = ({
  user,
  filter,
  onChangeFilter,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t, } = useTranslation();

  const roleOptions = useRoleOptions();
  const spaceOptions = useSpaceOptions();

  const spaceList = spaceOptions.data?.spaces.list || [];


  const activeOptions = useOptionsActive();

  return (
    <MuiTableHead className={classes.tableHead}>
      <TableRow>
        <TableCell style={COLS.email}>
          <SortLabel
            onChange={onChangeFilter}
            name="email"
            sort={filter.sort}
          >
            {t('user:label.email')}
          </SortLabel>
        </TableCell>
        <TableCell style={COLS.space}>
          {t('user:label.space')}
        </TableCell>
        <TableCell style={COLS.role}>
          <SortLabel
            onChange={onChangeFilter}
            name="role"
            sort={filter.sort}
          >
            {t('user:label.role')}
          </SortLabel>
        </TableCell>

        <TableCell style={COLS.active}>
            {t('user:label.labelActive')}
        </TableCell>
        <TableCell style={COLS.firstLogin}>
          {t('user:label.firstLogin')}
        </TableCell>

        <TableCell style={COLS.actions} />
      </TableRow>

      <TableRow>
        <TableCell>
          <FilterText
            name="email"
            value={filter.email}
            onChange={onChangeFilter}
          />
        </TableCell>
        <TableCell>
          {user?.permissions.USERS_MANAGE && (
            <FilterSelect<SpaceOptionFragment>
              name="organization"
              onChange={onChangeFilter}
              options={spaceList}
              getOptionLabel={spaceOptions.getOptionLabel}
              getOptionSelected={spaceOptions.getOptionSelected}
            />
          )}
        </TableCell>
        <TableCell>
          <FilterSelect<UserRoleType>
            name="role"
            onChange={onChangeFilter}
            options={roleOptions.options}
            getOptionLabel={roleOptions.getOptionLabel}
            getOptionSelected={roleOptions.getOptionSelected}
          />
        </TableCell>
        <TableCell>
          <FilterSelect<OptionBoolean>
            name="active"
            onChange={onChangeFilter}
            options={activeOptions.options}
            getOptionLabel={activeOptions.getOptionLabel}
            getOptionSelected={activeOptions.getOptionSelected}
          />
        </TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    </MuiTableHead>
  );
};


export default TableHead;
