import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { useReviewOrderMutation } from "Apollo/graphql";
import { ManageOrdersStore } from "Modules/ManageIncomingOrders/store/types";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
  setPhase: ManageOrdersStore["setPhase"];
  open: boolean;
  missingPartsList: string[];
  note: string;
  orderId: string;
}
const SubmitDialogue = ({
  missingPartsList,
  note,
  onClose,
  open,
  orderId,
  setPhase,
}: Props): JSX.Element => {
  const { t } = useTranslation("order");
  const { enqueueSnackbar } = useSnackbar();
  const [mutation, result] = useReviewOrderMutation({
    variables: {
      input: {
        missingPartNumbers: missingPartsList,
        note: note,
        id: orderId,
      },
    },
    onCompleted(data) {
      if (data.reviewOrder) {
        setPhase("overview");
        onClose();
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });
  const handleClose = () => {
    onClose();
  };
  const partsAreMissing = missingPartsList.length > 0;
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t("admin.processOrder")}</DialogTitle>
      <Box padding={2} display="flex" flexDirection="column" gap={1}>
        <Typography variant="body2">
          {partsAreMissing
            ? t("admin.incompleteNotice")
            : t("admin.completeNotice")}
        </Typography>
        {partsAreMissing && (
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="body2" fontWeight="bold">
              {t("missingPartsTitle")}:
            </Typography>
            {missingPartsList.map((item) => (
              <Typography variant="body2">{item}</Typography>
            ))}
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
        >
          <Button onClick={handleClose}>{t("common:action.reject")}</Button>
          <Button disabled={result.loading} onClick={() => mutation()}>
            {t("common:action.ok")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SubmitDialogue;
