import { Modal, } from 'Components/Modal';

import { SpacesStore, useSpacesStore, } from '../../store/useSpacesStore';
import Logic from './Logic';


const selectorModalData = (store: SpacesStore) => (store.modalCreate);
const selectorClose = (store: SpacesStore) => (store.closeModalCreate);


const ModalSpaceCreate = (): JSX.Element | null => {
  const modalData = useSpacesStore(selectorModalData);
  const close = useSpacesStore(selectorClose);


  if (!modalData.isOpen) return null;

  return (
    <Modal
      open
      onClose={close}
      maxWidth="sm"
    >
      <Logic
        onClose={close}
      />
    </Modal>
  );
};


export default ModalSpaceCreate;
