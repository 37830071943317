import { useTranslation, } from 'react-i18next';
import { useState, } from 'react';
import { Link as RouterLink, } from 'react-router-dom';
import { makeStyles, createStyles, fade, Theme, } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { ROUTES, } from 'Utils/globals';
import { User, } from 'Tools/auth';
import { useLogout, } from 'Tools/auth/hooks/useLogout';
import { MENU_BUTTONS, } from './constants';
import Logo from './Logo';


const useStyles = makeStyles((theme: Theme) => createStyles({
  toolbar: {
    padding: 0,
    background: theme.palette.primary.main,
  },
  drawerPaper: {
    width: 230,
    minWidth: 230,
    maxWidth: 230,
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  buttonCloseMenu: {
    marginLeft: 'auto',
  },
  navList: {
    padding: 0,
  },
  mainListItem: {
    padding: theme.spacing(3),
    '&.MuiListItem-divider': {
      borderColor: fade(theme.palette.grey[400],0.9),
    },
    '&$selected': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));


interface Props {
  user: User | null,
}


const Mobile = ({
  user,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t, } = useTranslation();
  const [ logout, logoutData, ] = useLogout();

  const [ isOpenMenu, changeOpenMenu, ] = useState<boolean>(false);

  const handleToggleMenu = () => changeOpenMenu(!isOpenMenu);
  const handleCloseMenu = () => changeOpenMenu(false);
  const handleClick = () => handleCloseMenu();
  const handleLogout = () => {
    logout();
    handleClick();
  };


  return (
    <Toolbar className={classes.toolbar}>
      <Logo />

      <IconButton
        className={classes.buttonCloseMenu}
        color="inherit"
        edge="start"
        onClick={handleToggleMenu}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        variant="temporary"
        anchor="right"
        open={isOpenMenu}
        onClose={handleCloseMenu}
        ModalProps={{
          keepMounted: true,
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List className={classes.navList}>
          {MENU_BUTTONS.map((navLink) =>
            !navLink.permission || user?.permissions[navLink.permission] ? (
              <ListItem
                key={navLink.id}
                component={RouterLink}
                to={navLink.to}
                className={classes.mainListItem}
                button
                onClick={() => {
                  handleClick();
                  if (navLink.onClick) {
                    navLink.onClick();
                  }
                }
              }
              >
                {t(navLink.textKey)}
              </ListItem>
            ) : null
          )}
        </List>

        {user && (
          <>
            <List className={classes.navList}>
              <ListItem
                className={classes.mainListItem}
                component={RouterLink}
                to={`/${ROUTES.CURRENT_USER}`}
                button
                onClick={handleClick}
              >
                {t("common:header.userMenu.profile")}
              </ListItem>
              <ListItem
                className={classes.mainListItem}
                button
                disabled={logoutData.loading}
                onClick={handleLogout}
              >
                {t("common:header.userMenu.logout")}
              </ListItem>
              <ListItem className={classes.mainListItem}>
                {user.email}
              </ListItem>
            </List>
          </>
        )}
      </Drawer>
    </Toolbar>
  );
};


export default Mobile;
