import {
  removeAccessToken,
  setAccessToken,
} from 'Tools/auth';


export type RefreshLinkResult = {
  accessToken?: string,
};


export const fetchRefresh = (): Promise<Response> => fetch(process.env.REACT_APP_HTTP_LINK as string, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json', },
  credentials: 'include',
  body: JSON.stringify({
    query: `mutation refreshToken {
      refreshToken {
        accessToken
      }
    }`,
  }),
});


export const handleRefreshCompleted = ({ accessToken, }: RefreshLinkResult): void => {
  if (accessToken) setAccessToken(accessToken);
  else removeAccessToken();
};


export const handleRefreshError = (): void => {
  // TODO - remove access and refresh token if unauthorized
};
