import { TableCell } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import * as lodash from "lodash";
import { useMemo, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import LoadingResponse from "./LoadingResponse";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import CollapsibleDetail from "./CollapsibleDetail";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocalizedNumber from "Tools/localization/LocalizedNumber";
import {
  Exact,
  PartFragment,
  UpdatePartAmountInput,
  UpdatePartAmountMutation,
} from "Apollo/graphql";
import { MutationFunctionOptions } from "@apollo/client";

interface Props {
  isExecuting: boolean;
  part: PartFragment;
  removePart: () => void;
  refreshPart: () => void;
  updatePart: (
    options?: MutationFunctionOptions<
      UpdatePartAmountMutation,
      Exact<{
        updatePartAmountInput: UpdatePartAmountInput;
      }>
    >
  ) => Promise<any>;
}

const PartRow = ({
  isExecuting,
  part,
  updatePart,
  removePart,
  refreshPart,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(part.amount);
  const [isOpen, setIsOpen] = useState(false);

  // Debounce the mutation function
  const debouncedMutation = useMemo(() => {
    return lodash.debounce(({ id, amount }: { id: string; amount: number }) => {
      updatePart({
        variables: {
          updatePartAmountInput: { id, amount },
        },
      });
    }, 500);
  }, [updatePart]);

  const handleAmountChange = (event: { target: { value: any } }) => {
    const newAmount = Number(event.target.value);
    setAmount(newAmount);
    debouncedMutation({ id: part.id, amount: newAmount });
  };

  const totalPrice = (part.price || 0) * amount;

  return (
    <>
      <TableRow
        key={part.id}
        sx={{
          position: "relative",
          backgroundColor: part.price ? "#ffff" : "#ff9d9d",
        }}
      >
        {isExecuting && <LoadingResponse />}
        <TableCell>{part.oemNumber || part.number}</TableCell>
        <TableCell>{part.scrapedBrand}</TableCell>
        <Tooltip
          title={
            totalPrice
              ? t("order:table.priceInfo")
              : t("order:table.willBeDeleted")
          }
        >
          {
            <TableCell>
              {totalPrice ? (
                <LocalizedNumber value={totalPrice} postfix={" Kč"} />
              ) : (
                t("order:table.unavailable")
              )}
            </TableCell>
          }
        </Tooltip>
        <TableCell style={{ width: "50px" }}>
          <input
            type="number"
            value={amount}
            onChange={handleAmountChange}
            min={1}
            style={{ width: "100%" }}
          />
        </TableCell>
        <TableCell>
          {part.deliveryDate
            ? new Date(part.deliveryDate).toLocaleDateString("cs-CZ")
            : null}
        </TableCell>
        <TableCell>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>
        </TableCell>
        <TableCell>
          <Box display="flex" flexDirection="row">
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={() => refreshPart()}
            >
              <RefreshIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={() => removePart()}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <CollapsibleDetail isOpen={isOpen} part={part} />
    </>
  );
};

export default PartRow;
