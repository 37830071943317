import { BillingInfoFragment } from "Apollo/graphql";
import { FORM_VALIDATORS } from "Components/Form";
import * as Yup from "yup";

export type BillingDataForm = {
  name: string;
  telephone: string;
  email: string;
  ico: string;
  dic: string;
  psc: string;
  city: string;
  street: string;
};

export const INITIAL_BILLING_DATA_FORM: BillingDataForm = {
  name: "",
  telephone: "",
  email: "",
  ico: "",
  dic: "CZ",
  psc: "",
  city: "",
  street: "",
};
const PHONE_REGEX =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i;
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const PSC_REGEX = /^[0-9]{5}$/i;

export const validationSchemaBillingData = Yup.object().shape({
  name: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .max(256, FORM_VALIDATORS.STRING_MAX),
  telephone: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .matches(PHONE_REGEX, FORM_VALIDATORS.WRONG_FORMAT)
    .max(256, FORM_VALIDATORS.STRING_MAX),
  email: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .matches(EMAIL_REGEX, FORM_VALIDATORS.WRONG_FORMAT)
    .max(256, FORM_VALIDATORS.STRING_MAX),
  ico: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .max(256, FORM_VALIDATORS.STRING_MAX),
  dic: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .max(256, FORM_VALIDATORS.STRING_MAX),
  psc: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .matches(PSC_REGEX, FORM_VALIDATORS.WRONG_FORMAT)
    .max(256, FORM_VALIDATORS.STRING_MAX),
  city: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .max(256, FORM_VALIDATORS.STRING_MAX),
  street: Yup.string()
    .required(FORM_VALIDATORS.REQUIRED)
    .max(256, FORM_VALIDATORS.STRING_MAX),
});

export const billingInfoFragmentToBillingDataForm = (
  billingInfo: BillingInfoFragment | null
) => {
  if (!billingInfo) return INITIAL_BILLING_DATA_FORM;
  return {
    name: billingInfo.name,
    telephone: billingInfo.telephone,
    email: billingInfo.email,
    ico: billingInfo.ico,
    dic: billingInfo.dic,
    psc: billingInfo.psc,
    city: billingInfo.city,
    street: billingInfo.street,
  };
};
