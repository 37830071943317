import { useTranslation, } from 'react-i18next';
import {
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';


interface Props {
  active?: boolean,
  disabled?: boolean,
  activeLabel?: React.ReactNode,
  inActiveLabel?: React.ReactNode,
  activeIcon?: React.ReactElement,
  inActiveIcon?: React.ReactElement,
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: Theme) => createStyles<any, { active: boolean, }>({
  chip: {
    maxWidth: '100%',
    color: (props) => (props.active ? theme.palette.success.dark : theme.palette.error.dark),
    borderColor: (props) => (props.active ? theme.palette.success.dark : theme.palette.error.dark),
  },
}));


const ChipActive: React.FC<Props> = ({
  active = false,
  disabled,
  activeLabel,
  inActiveLabel,
  activeIcon = <CheckIcon />,
  inActiveIcon = <CloseIcon />,
}: Props) => {
  const classes = useStyles({ active, });
  const { t, } = useTranslation();

  const activeLabelDef = activeLabel || t('common:options.yes');
  const inActiveLabelDef = inActiveLabel || t('common:options.no');

  return (
    <Chip
      size="small"
      className={classes.chip}
      variant="outlined"
      disabled={disabled}
      icon={active ? activeIcon : inActiveIcon}
      label={active ? activeLabelDef : inActiveLabelDef}
      color="primary"
    />
  );
};


export default ChipActive;
