import { CSSProperties, } from '@material-ui/core/styles/withStyles';


export const COLS = {
  email: {
    minWidth: 200,
    maxWidth: 200,
  } as CSSProperties,
  space: {
    minWidth: 140,
    maxWidth: 140,
  } as CSSProperties,
  role: {
    minWidth: 170,
    maxWidth: 170,
  } as CSSProperties,
  firstLogin: {
    minWidth: 100,
    maxWidth: 100,
  } as CSSProperties,
  active: {
    minWidth: 100,
    maxWidth: 100,
  } as CSSProperties,
  actions: {
    minWidth: 144,
    maxWidth: 144,
    'boxSizing': 'content-box',
  } as CSSProperties,
};
