import { useUser, } from 'Tools/auth';

const DemandDetailRouterPage = (): JSX.Element => {
  const user = useUser();

  return (
    <>

      {user?.permissions.PARTS_MANAGE && (
        <>
        </>
      )}

    </>
  );
};

export default DemandDetailRouterPage;
