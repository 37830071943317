import { useUser } from "Tools/auth";
import Dispatch from "./Phases/Dispatch";
import OverView from "./Phases/OverView";
import { useDispatchOrdersStore } from "./store";
import { DispatchOrderStore, Phases } from "./store/types";
import Box from "@mui/material/Box";

const selectStoreData = (s: DispatchOrderStore) => ({
  phase: s.phase,
});

const ManageDispatchOrders = (): JSX.Element => {
  const phaseToComponents: { [key in Phases]: JSX.Element } = {
    overview: <OverView />,
    dispatch: <Dispatch />,
  };
  const { phase } = useDispatchOrdersStore(selectStoreData);
  const user = useUser();

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(10)
      })}
    >
      <Box width="80%">
        {user?.permissions.MANAGE_ORDERS && phaseToComponents[phase]}
      </Box>
    </Box>
  );
};

export default ManageDispatchOrders;
