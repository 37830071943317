import { DispatchOrderStore } from "Modules/ManageDispatchOrders/store/types"

export const flattenOrderSelection = (orderSelection: DispatchOrderStore["orderSelection"]): boolean[] => {
    if (orderSelection === null) return [];
    const entries = Object.entries(orderSelection)
    return entries.map(([, value]) => value)
}

export const sumOrderSelection = (orderSelection: DispatchOrderStore["orderSelection"]): number => {
    const flatOrderSelection = flattenOrderSelection(orderSelection)
    return flatOrderSelection.reduce((acc, value) => acc + (value ? 1 : 0), 0)
}
export const onlySelectedInOrderSelection = (orderSelection: Record<string, boolean>): string[] => {
    const keys =  orderSelection === null ? [] : Object.keys(orderSelection)
    const onlyTrue=  keys.reduce((acc, key) => orderSelection[key] ? [...acc,key] : acc, [] as string[])
    return onlyTrue
}
