import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { AdminTableOrderFragment } from "Apollo/graphql";
import OrderTable from "Components/AdminOrderTable/OrderTable";
import { useDispatchOrdersStore } from "Modules/ManageDispatchOrders/store";
import { DispatchOrderStore } from "Modules/ManageDispatchOrders/store/types";
import { useTranslation } from "react-i18next";
import DispatchSelected from "./DispatchSelected";

const selectStoreData = (s: DispatchOrderStore) => ({
  toggleOrderSelection: s.toggleOrderSelection,
  orderSelection: s.orderSelection,
  setPhase: s.setPhase,
  resetOrderSelection: s.resetOrderSelection,
  removeOrders: s.removeOrders,
});

interface Props {
  orders: AdminTableOrderFragment[];
}

const Table = ({ orders }: Props): JSX.Element => {
  const { orderSelection, toggleOrderSelection,setPhase,resetOrderSelection,removeOrders } =
    useDispatchOrdersStore(selectStoreData);
  const { t } = useTranslation("order");
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={2}
      marginTop={2}
    >
      <Typography
        variant="h3"
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(4)
          }
        })}
      >
        {t("dispatch.titleOverview")}
      </Typography>
      <OrderTable
        orders={orders}
        selectRow={toggleOrderSelection}
        rowIsSelected={(orderId) => (orderSelection || {})[orderId]}
        showPartDropDown={true}
      />
      <DispatchSelected
        orderSelection={orderSelection}
        setPhase={setPhase}
        resetOrderSelection={resetOrderSelection}
        removeOrders={removeOrders}
      />
    </Box>
  );
};
export default Table;
