import { useTranslation, } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { ModalBody, ModalFooter, } from 'Components/Modal';

interface Props {
  link: string,
  onClose: () => void,
}

const LinkView = ({
  link,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation();
  return (
    <>
      <ModalBody>
        <Typography>
          {t('user:exportModal.exportSuccessMessage')}&nbsp;
          <MuiLink
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('user:exportModal.here')}
          </MuiLink>
        </Typography>

      </ModalBody>
      <ModalFooter>
        <Grid container justify="space-between" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="default"
              onMouseDown={onClose}
            >
              {t('common:action.back')}
            </Button>
          </Grid>
        </Grid>
      </ModalFooter>
    </>
  );
};

export default LinkView;
