import React, { ReactNode, } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
  },
}));


interface Props {
  children: ReactNode,
}


const ModalFooter: React.FC<Props> = ({
  children,
}: Props) => {
  // classes
  const classes = useStyles();


  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};


export default ModalFooter;
