import create from 'zustand';

import { SpaceTableFragment, OrderEnum, } from 'Apollo/graphql';
import { OptionBoolean, } from 'Utils/types';
import { Sort, } from 'Components/Table';

export type SpacesFilter = {
  name: string,
  active: OptionBoolean | null,
  approved: OptionBoolean | null,
  offset: number,
  limit: number,
  sort: Sort,
};

export type ChangeSpacesFilter = (payload: { name: string, value: any, }) => void;

export type SpacesStore = {
  filter: SpacesFilter,
  modalCreate: {
    isOpen: boolean,
  },
  modalEdit: {
    isOpen: boolean,
    space: SpaceTableFragment | null,
  },
  modalExport: {
    isOpen: boolean,
  },
  changeFilter: ChangeSpacesFilter,
  openModalCreate: () => void,
  closeModalCreate: () => void,
  openModalEdit: (space: SpaceTableFragment) => void,
  closeModalEdit: () => void,
  openModalExport: () => void,
  closeModalExport: () => void,
};

/**
 * Store / Hook
 */
export const useSpacesStore = create<SpacesStore>((set) => ({
  // state
  filter: {
    name: '',
    active: null,
    approved: null,
    offset: 0,
    limit: 20,
    sort: {
      by: '',
      order: OrderEnum.Asc,
    },
  },
  modalCreate: {
    isOpen: false,
  },
  modalEdit: {
    isOpen: false,
    space: null,
  },
  modalExport: {
    isOpen: false,
  },
  // actions
  changeFilter: ({ name, value, }) => set((state) => ({
    filter: {
      ...state.filter,
      offset: 0,
      [name]: value,
    },
  })),
  openModalCreate: () => set({
    modalCreate: {
      isOpen: true,
    },
  }),
  closeModalCreate: () => set({
    modalCreate: {
      isOpen: false,
    },
  }),
  openModalEdit: (space) => set({
    modalEdit: {
      isOpen: true,
      space,
    },
  }),
  closeModalEdit: () => set({
    modalEdit: {
      isOpen: false,
      space: null,
    },
  }),
  openModalExport: () => set({
    modalExport: {
      isOpen: true,
    },
  }),
  closeModalExport: () => set({
    modalExport: {
      isOpen: false,
    },
  }),
}));
