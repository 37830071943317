import { useUserQuery, } from 'Apollo/graphql';
import { ModalLoading, ModalError, } from 'Components/Modal';
import Logic from './Logic';


interface Props {
  userId: string;
  onClose: () => void,
}

const FetchData = ({
  userId,
  onClose,
}: Props): JSX.Element => {
  const { loading, error, data, } = useUserQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: userId,
    },
  });


  if (loading) {
    return (
      <ModalLoading
        minHeight="6rem"
        onClose={onClose}
      />
    );
  }

  if (error || !data?.user) {
    return (
      <ModalError
        minHeight="6rem"
        apolloError={error}
        onClose={onClose}
      />
    );
  }

  return (
    <Logic
      user={data.user}
      onClose={onClose}
    />
  );
};


export default FetchData;
