import React, { useCallback, } from 'react';
import PaginationMui from '@material-ui/lab/Pagination';


interface Props {
  className?: string,
  count?: number,
  limit?: number,
  offset?: number,
  inputName?: string,
  onChange: (payload: { value: number, name: string, }) => void,
}


const Pagination: React.FC<Props> = ({
  className,
  count = 1,
  limit = 1,
  offset = 0,
  inputName = 'offset',
  onChange,
}: Props) => {
  const pageCount = Math.ceil(count / limit);
  const currentPage = offset === 0 ? 1 : Math.trunc(offset / limit) + 1;

  const handleChange = useCallback(
    (e: React.ChangeEvent<unknown>, newPage: number) => {
      onChange({
        name: inputName,
        value: (newPage - 1) * limit,
      });
    },
    [ limit, inputName, onChange, ],
  );


  return (
    <PaginationMui
      className={className}
      color="primary"
      count={pageCount || 1}
      page={currentPage}
      onChange={handleChange}
    />
  );
};


export default Pagination;
