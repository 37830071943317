import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ItemButton from "./ItemButton";

interface Props {
  reservoirList: string[];
  scannedList: string[];
  scanNumber: (number: string) => void;
  resetNumber: (number: string) => void;
}

const TransferList = ({
  reservoirList,
  scannedList,
  resetNumber,
  scanNumber,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const filteredList = reservoirList.filter((item) => item.includes(search));

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <TextField
        label={t("order:admin.filter")}
        variant="outlined"
        onChange={(event) => setSearch(event.target.value)}
      />
      <Box display="flex" flexDirection="row" minWidth={250} paddingTop={2} gap={2}>
        <Box display="flex" flexDirection="column" minWidth={120}>
          <Typography>{t('order:admin.reservoir')}</Typography>
          {filteredList.map((item) => (
            <ItemButton id={item} callback={scanNumber} />
          ))}
        </Box>
        <Box display="flex" flexDirection="column" minWidth={120}>
          <Typography>{t('order:admin.scanned')}</Typography>
          {scannedList.map((item) => (
            <ItemButton id={item} callback={resetNumber} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TransferList;
