import { LocaleEnum, } from 'Tools/localization/types';


export const ROUTES = {
  LOGIN: 'login',
  REGISTER: 'register',
  FORGOTTEN_PASSWORD: 'forgotten-password',
  RESET_PASSWORD: 'reset-password',
  CURRENT_USER: 'current-user',
  USERS: 'users',
  CUSTOMERS: 'customers',
  DETAIL: 'detail',
  ORDER: 'order',
  ORDERS: 'orders',
  ABOUT_US: 'about-us',
  COURSE_ORGANIZATION_LIST: 'organizations',
  COURSE_USERS: 'users',
  DISPATCH: 'dispatch',
  MANAGE_INCOMING_ORDERS: 'manage-incoming-orders',
  MANAGE_DISPATCH_ORDERS: 'manage-dispatch-orders',
};

export const EXPORT_ITEMS = {
  ALL_PAGES: 'all',
  CURRENT_PAGE: 'current',
};


export const DEFAULT_LOCALE = LocaleEnum.cs;
