import { FormSubmit } from "Components/Form";
import { Form } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingResponse from "./LoadingResponse";
import CheckPartForm from "./components/CheckPartForm";
import { Parts } from "Modules/Order/store/types";
import { Box } from "@mui/material";
interface Props {
  loading: boolean;
  parts: Parts | null;
  partSelection: any[]
}
const View = ({ loading, parts,partSelection }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Form>
      <Box margin={1}>
        <CheckPartForm parts={parts} />
        {loading ? (
          <LoadingResponse />
        ) : (
          <FormSubmit
            fullWidth
            color="primary"
            onSubmit={(e) => setIsLoading(true)}
            disabled={partSelection.length === 0}
          >
            {isLoading ? t("common:action.checking") : t("common:action.check")}
          </FormSubmit>
        )}
      </Box>
    </Form>
  );
};
export default View;
