import { useMemo, useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSnackbar, } from 'notistack';
import MuiTableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import { User, } from 'Tools/auth';
import { APP_EVENTS, useEmitAppEvent, } from 'Tools/appEvent';
import { UserFragment, useRemoveUserMutation, useSetUserActiveMutation, } from 'Apollo/graphql';
import { useConfirmModal, } from 'Tools/confirmModal';
import ChipActive from 'Components/ChipActive';
import { roleOptions, } from 'Utils/options/useRoleOptions';
import { COLS, } from './constants';

interface Props {
  user: User | null,
  row: UserFragment,
  onDetail: (user: UserFragment) => void,
}

const TableRow = ({
  user,
  row,
  onDetail,
}: Props): JSX.Element => {

  const { t, } = useTranslation();
  const confirm = useConfirmModal();
  const emitEvent = useEmitAppEvent();
  const { enqueueSnackbar, } = useSnackbar();

  const role = useMemo(
    () => roleOptions.find((o) => o.id === row.role),
    [row]
  );

  const [removeMutation, removeMutationData] = useRemoveUserMutation({
    onCompleted: () => {
      emitEvent?.(APP_EVENTS.USER_LIST);
      enqueueSnackbar(t('user:usersTable.notificationRemoved'), { variant: 'success', });
    },
    onError: () => {
      enqueueSnackbar(t('errors:somethingHappened'), { variant: 'error', });
    },
  });

  const [setActiveMutation, setActiveMutationData] = useSetUserActiveMutation({
    onCompleted: () => {
      emitEvent?.(APP_EVENTS.USER_LIST);
      enqueueSnackbar(
        row.active
          ? t('user:usersTable.notificationBlocked')
          : t('user:usersTable.notificationActivated'),
        { variant: 'success' }
      );
    },
    onError: () => {
      enqueueSnackbar(t('errors:somethingHappened'), { variant: 'error', });
    },
  });

  const handleDetail = () => onDetail(row);

  const handleSetActive = async () => {
    const ret = await confirm?.({
      description: row.active
        ? t('user:usersTable.confirmBlock', { user: row.email })
        : t('user:usersTable.confirmUnblock', { user: row.email }),
    });

    if (ret) {
      setActiveMutation({
        variables: {
          id: row.id,
          active: !row.active,
        },
      });
    }
  };

  const [force, setForce] = useState(true);

  const content = (<>Potvrzením navždy smažete uživatele {row.email}. <br /> Zaškrtnutím checkboxu jej odstraníte z databáze navždy <input type="checkbox" onChange={e => setForce(!!e.target.checked)} /></>)


  const handleRemove = async () => {
    const ret = await confirm?.({
      content,
      // description: t('user:usersTable.confirmRemove', { user: row.name, }),
    });

    if (ret) {
      removeMutation({
        variables: { id: row.id, force },
      });
    }
  };

  handleRemove.bind(this);


  return (
    <MuiTableRow hover>

      <TableCell style={COLS.email}>
        {row.email}
      </TableCell>

      <TableCell style={COLS.space}>
        {row.space?.name}
      </TableCell>

      <TableCell style={COLS.role}>
        {role ? t(role.value) : ''}
      </TableCell>

      <TableCell className="tableCell-action" style={COLS.active}>
        <ChipActive
          active={row.active}
          activeLabel={t('common:activeOptions.active')}
          activeIcon={<LockOpenIcon />}
          inActiveLabel={t('common:activeOptions.blocked')}
          inActiveIcon={<LockIcon />}
        />
      </TableCell>

      <TableCell style={COLS.firstLogin} className="tableCell-action">
        <ChipActive
          active={!row.isFirstLogin}
        />
      </TableCell>

      <TableCell style={COLS.actions} align="right" className="tableCell-action">
        <Grid container>
          {user?.permissions.USERS_SPACE_MANAGE && (
            <Grid item>
              <Tooltip title={<span>{t('common:action.detail')}</span>}>
                <span>
                  <IconButton
                    onClick={handleDetail}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          )}
          {user?.permissions.USERS_MANAGE && (
            <Grid item>
              <Tooltip title={<span>{row.active ? t('common:action.block') : t('common:action.unblock')}</span>}>
                <span>
                  <IconButton
                    onClick={handleSetActive}
                    color="primary"
                    disabled={setActiveMutationData.loading}
                  >
                    {row.active
                      ? (<LockIcon />)
                      : (<LockOpenIcon />)
                    }
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          )}
          {user?.permissions.USERS_SPACE_MANAGE && (
            <Grid item>
              <Tooltip title={<span>{t('common:action.delete')}</span>}>
                <span>
                  <IconButton
                    onClick={handleRemove}
                    color="primary"
                    disabled={removeMutationData.loading}
                    className="iconButton-error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </TableCell>

    </MuiTableRow>
  );
};


export default TableRow;
